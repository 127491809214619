import React, {Component} from 'react'
import cn from 'classnames'

class Toggle extends Component {
    render(){
        let cx = cn('toggle', this.props.className)
        return (
            <div className={cx}>
              <div className={this.props.horizontalToggle ? 'toggle-label-horizontal' :'toggle-label'} />
              <div className={this.props.on ? (this.props.horizontalToggle ? 'toggle-left' :'toggle-on') : (this.props.horizontalToggle ? 'toggle-right':'toggle-off')} 
                   onClick={()=>this.props.toggle(this.props.type)} />
            </div>
        )
    }
}

export default class Toggles extends Component {
    render(){
        let { additional, original, bootLeg , completion, toggleUpdate} = this.props
        return (
            <div className="timeline-toggles">
              <div className="original-release"  onClick={()=>toggleUpdate('original')} />
              <Toggle toggle={toggleUpdate}
                      type='original'
                      on={original}   className="original"    />
              <Toggle toggle={toggleUpdate}
                      type='additional'
                      on={additional} className="additional"  />
            <div className="additional-release" onClick={()=>toggleUpdate('additional')} />
              <Toggle toggle={toggleUpdate}
                      type ='bootleg'
                      on={bootLeg} className="bootleg"  />
            <div className="bootleg-label" onClick={()=>toggleUpdate('bootleg')} />
            <div className={`completion-label ${completion ? "enable":"disable"}`} onClick={()=>toggleUpdate('completion')} />
            <Toggle toggle={toggleUpdate}
                      type ='completion'
                      on={completion} className="completion"
                      horizontalToggle={true}  />
            <div className={`release-label ${completion ? "disable":"enable"}`} onClick={()=>toggleUpdate('completion')} />
            </div>
        )
    }
}
