import React, { Component } from "react";

class SubCategoryResults extends Component {
    render() {
      const { subCategoryData, onClick, queryCategory, desc , loadingPagination} = this.props;
      const subCategoryKeys = subCategoryData && Object.keys(subCategoryData);
      const subCategoryItems = [];
      const style = (queryCategory === 'newsArticle') ? "result-items articles" : "result-items";
  
      if (subCategoryKeys && subCategoryKeys.length > 0) {
        subCategoryKeys.forEach((_subCategory, index) => {
          const data = subCategoryData[_subCategory];
          subCategoryItems.push(
            <div className="search-results" key={index}>
              <div className="inner-wrapper">
                {data.length ? (
                  <div className="header">
                    <div className="desc">{_subCategory}</div>
                    <div className="count">
                      {data.length} Result
                      {data.length > 1 ? "s" : ""}
                    </div>
                    <br style={{ clear: "both" }} />
                  </div>
                ) : null}
                <div className="filler" />
                <div className={style}>
                  {data.map((item, idx) => desc(item, idx, onClick))}
                </div>
              </div>
            </div>
          );
        });
      }
      return <div className="subCategory-wrapper">{subCategoryItems}</div>;
    }
  }

  export default  SubCategoryResults;