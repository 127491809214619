import React, {Component} from 'react'
import TimelineEvent from "./timeline-event";
import {sortedItems,getEventsByYear} from "../drawer-items";

export default class TimelineEvents extends Component {
    shouldComponentUpdate(newprops) {
      return (
        newprops.year !== this.props.year ||
        newprops.selectedEventId !== this.props.selectedEventId
      );
    }
    render() {
      let id = this.props.selectedEventId;
      let events = getEventsByYear(this.props.year);
  
      if (!events || events.length === 0) return null;
  
      events = sortedItems(events);
  
      return (
        <div className="timeline-events-wrapper">
          <div className="timeline-events">
            {events.map((e, i) => (
              <TimelineEvent
                key={i}
                selected={e.id === id}
                onClick={this.props.onClick}
                onClose={this.props.onClose}
                showMoreInfo={this.props.showMoreInfo}
                showVideo={this.props.showVideo}
                showPhotos={this.props.showPhotos}
                event={e}
              />
            ))}
          </div>
        </div>
      );
    }
  }