import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Separator from './separator';
import Performance from './performance';
import TimelineEventInfo from '../timeline-event-info'
import {
    calcPositionInMonth,
    compareDates,
    mergeTourPerformances,
    calcCenterInYears
} from './utils';


export const TourMonth = props => {
    let {year, month, onClick, selectedEventId,updateMonthNum, monthSelectedNum} = props;
    const [selectedTour, setSelectedTour] = useState(null);
    const [monthLogVisible, setMonthLogVisible] = useState(false);
    const months = useSelector(state => {
        const monthObj = state.tour.byDate[year];
        if(monthObj && monthObj[month]) {
            return monthObj[month];
        }
        return null;
    });

    const calcPosition = calcPositionInMonth;

    if (!months) return null;
    
    const allTours = Array.from(months);

    const onTourElementClick = (event, tour) => {
        if(tour && tour._id){
            setSelectedTour(tour);
            setMonthLogVisible(true)
            updateMonthNum(month)
            onClick(tour._id);
        }
    }

    const toursJSX = allTours.reduce((performanceAcc, tour) => {

        const mergedPerformances = mergeTourPerformances(tour);
        if (mergedPerformances.length === 0) {return acc;}

        let startedPlacingPerformances = false;
        let finishedPlacingPerformances = false;

        const performancesJSX = mergedPerformances.reduce((acc, mergedPerformance, index, allPerformances) => {
            if (finishedPlacingPerformances) {return acc;}

            let performanceStartDate = mergedPerformance.startDate;
            let performanceEndDate = mergedPerformance.endDate;

            const startsThisMonth = performanceStartDate.year === year && performanceStartDate.month === month;
            const endsThisMonth = performanceEndDate.year === year && performanceEndDate.month === month;
            const isPartOfThisPage = startsThisMonth || endsThisMonth;

             // some other page will render this performance
            if (!isPartOfThisPage) {return acc;}

            const isNotLast = index < allPerformances.length - 1

            // if we are the first performance of this month but not of the tour we will
            // add an initial separator to connect to the previous month.
            let initialSeparator = null;
            if (index > 0 && !startedPlacingPerformances) {
                const prevPerfEndDate = allPerformances[index - 1].endDate;
                if (prevPerfEndDate.month < month) {
                    initialSeparator = <Separator
                        onClick={onTourElementClick}
                        offset={'0px'}
                        tour={tour}
                        startDate={{year, month, day: 0}}
                        endDate={performanceStartDate}
                        calcPosition={calcPosition}/>
                }
            }
            let finalSeparator = null;

            if (isNotLast) {
                // if we are the last performance of this month but not of the tour we will
                // change the separator end date to connect to the next month.
                const nextPerfStartDate = allPerformances[index + 1].startDate;
                const nextPerfEndDate = allPerformances[index + 1].startDate;
                let separatorEndDate = nextPerfStartDate;
                if (nextPerfStartDate.month > month) {
                    finishedPlacingPerformances = true;
                    separatorEndDate = {year, month, day: 31};
                } else if (nextPerfEndDate.month > month) {
                    finishedPlacingPerformances = true;
                }

                // if the last performance of this month bleeds into the next month 
                // we will skip the final separator
                finalSeparator = (<Separator
                    onClick={onTourElementClick}
                    offset={'0px'}
                    tour={tour}
                    startDate={performanceEndDate}
                    endDate={separatorEndDate}
                    calcPosition={calcPosition}/>);
                if (performanceStartDate.month < performanceEndDate.month && performanceStartDate.month === month || index === allPerformances.length - 1) {
                    finalSeparator = null;
                }

            }
            // if the current performance started didnt start this month
            // but bled into this one we skip the initial separator
            if (!startsThisMonth && endsThisMonth) {
                initialSeparator = null;
            }

            startedPlacingPerformances = true;
            const stringDate = `${mergedPerformance.startDate.month}.${mergedPerformance.startDate.day}.${mergedPerformance.startDate.year}`;
            
            acc.push(
            <div key={`tour-${stringDate}-${index}`}>
                {initialSeparator}
                <Performance
                    onClick={onTourElementClick}
                    performances={mergedPerformance}
                    year={year}
                    month={month}
                    dateElement="month"
                    calcPosition={calcPosition}
                    tour={tour}
                />
                {finalSeparator}
            </div>
            );
            return acc;
        },[])
        if (performancesJSX.length === 0) {
            return <Separator
                offset={'0px'}
                startDate={{year, month, day: 0}}
                endDate={{year, month, day: 31}}
                calcPosition={calcPosition}/>
        }
        performanceAcc = performanceAcc.concat(performancesJSX)
        return performanceAcc;
    }, [])

    let bubbleOffset = 0;
    if (selectedTour && selectedTour.performances.length > 0) {
        const startDate = selectedTour.performances[0].asset.startDate;
        const endDate = selectedTour.performances[selectedTour.performances.length - 1].asset.endDate;
        let selectedTourCenter = calcCenterInYears(startDate, endDate);
        const centerPos = calcPosition(selectedTourCenter);

        
        if (selectedTourCenter.year < year) {
            const yearDiff = year - selectedTourCenter.year;
            bubbleOffset = centerPos - (100 * yearDiff);
        } else if (selectedTourCenter.year > year) {
            const yearDiff = selectedTourCenter.year - year;
            bubbleOffset = centerPos + (100 * yearDiff);
        } else {
            bubbleOffset = centerPos;
        }
    }

    return (
        <div className="tour-wrapper monthly">
            {toursJSX}

            {selectedTour && selectedTour._id === selectedEventId && monthSelectedNum == month &&<div className="tour-info-wrapper" style={{left:`calc(${bubbleOffset}%)`}}>
                <TimelineEventInfo event={selectedTour}
                                showMoreInfo={props.showMoreInfo}
                                showVideo={props.showVideo}
                                showPhotos={props.showPhotos}
                                onClose={props.onClose} />
            </div>}
        </div>
    );
}


export default TourMonth;