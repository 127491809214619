import lorem from 'lorem-ipsum';
import {stream_from, pick, random_int} from '../util/random'

export const photos = {
    all: [],
    index: {}
}

let _id = 0

/* image thumbs will by sized to fit within 400x400 */
let imagesizes = [[400,200], [200, 400], [400,400], [300, 400], [400, 300]]
let next_thumb = stream_from(imagesizes)
/* full size images (for photos) will sized to fit within 880x880 */
let thumb_to_full_ratio = 880/400
/* full size images (for documents) will be sized to fit within 1600x1600 */
let thumb_to_full_ratio_docs = 1600/400

export function genPhotos(number, isDocuments=false){
    let result = []
    let count  = number
    while(count--){
        let id          = `${_id++}`
        let description = lorem({count: 1, units:'paragraphs'})

        let [w, h] = next_thumb()

        let r = isDocuments ? thumb_to_full_ratio_docs : thumb_to_full_ratio
        let W = (w*r)|0
        let H = (h*r)|0

        let thumb = `http://lorempixel.com/${w}/${h}`
        let full  = `http://lorempixel.com/${W}/${H}`

        let thumbWidth  = w
        let thumbHeight = h
        let fullWidth   = W
        let fullHeight  = H

        let photographer = lorem({count: 2, units:'words'})
        let owner        = lorem({count: 2, units:'words'})

        let credits = `photo by ${photographer}\ncourtesy of ${owner}`

        let date = {month: pick([1,2,3,4,5,6,7,8,9,10,11,12]),
                    year: random_int(1965, 2017)}

        let photo = {id, description, thumb, full, date, credits,
                     fullHeight, fullWidth,
                     thumbWidth, thumbHeight}

        photos.all.push(photo)
        photos.index[id] = photo

        result.push(photo)
    }
    return result
}
