import _ from 'lodash'
import {parseDate, formatParsedDate} from './date-utils'

function tryGetImageUrl(key, data, result) {
    let image = data[key]
    if (!image) return 
    if (!_.has(image, 'fields.file.url')) return 
    result.push(image.fields.file.url)
}
export function parseArticle(data){
    const images = []

    tryGetImageUrl('image1', data, images)
    tryGetImageUrl('image2', data, images)

    let type
    if (images.length === 2) {
        type = 'two-image'
    } else if (images.length === 1) {
        type = 'one-image'
    } else {
        type = 'no-image'
    }

    if (data.date) {
        data.releaseDate = parseDate(data.date)
        data.dateText = formatParsedDate(data.releaseDate, true)
    } else {
        console.error("missing date for article!", data.id)
        return null
    }

    data.type   = type
    data.images = images

    data.credits = data.photoCaption
    data.author  = data.byline

    return data
}
