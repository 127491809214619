import React, {Component} from 'react';

class Video extends Component {
    render(){
        let {title, description, thumbnail} = this.props.video

        let style = thumbnail ? {backgroundImage:`url('${thumbnail}')`} : {}

        return (
            <div className="video">
              <div className="thumbnail" style={style}><div className="play"/></div>
              <div className="info">
                <div className="title">{title}</div>
                <div className="description">{description}</div>
              </div>
            </div>
        )
    }
}

export default class VideoListing extends Component {
    render(){
        let {videos} = this.props
        return (
            <div className="video-listing">
              {videos && videos.map((video, idx)=><Video video={video} key={idx} />)}
            </div>
        )
    }
}
