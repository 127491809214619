import React, {Component} from 'react'
import VerticalCenter     from './vertical-center'

export class LeftButton extends Component {
    render(){
        let left     = (<div className="left-arrow" onClick={this.props.onClick}></div>)
        let moreLeft = this.props.show

        return (
              <VerticalCenter className="left-arrow-wrapper">
                { moreLeft ? left : null}
              </VerticalCenter>
        )
    }
}

export class RightButton extends Component {
    render(){
        let right     = (<div className="right-arrow" onClick={this.props.onClick}></div>)
        let moreRight = this.props.show

        return (
              <VerticalCenter className="right-arrow-wrapper">
                { moreRight ? right : null}
              </VerticalCenter>
        )
    }
}
