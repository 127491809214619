import { ENTRIES_LOADED, SET_SELECTED_FEATURED } from '../constants';

export default function update(state ={ppvMovies:[], selectedFeaturedIndex:null}, action) {
  const {type, entries, data} = action
  switch (type) {
    case ENTRIES_LOADED:
      if (entries.ppvMovie) {
        let result = entries.ppvMovie;
        Object.keys(entries.ppvMovie).forEach(k => {
            let ppvMovies = entries.ppvMovie[k]

            if (ppvMovies && ppvMovies.id) {
                result.byId[ppvMovies.id] = ppvMovies
                result.all.push(ppvMovies)
            }
        })
        return result;
      }
      if (entries.video) {
        let result = {'video':entries.video}
        return result
      }
      break;
  }
  return state;
}