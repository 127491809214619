import React, {Component} from 'react'
import _ from 'lodash'
import cn from 'classnames'

const isSafari = navigator.userAgent.search(/Safari/) > -1

let height = 47 /* must match css */

function offset(n){
    return -(n * height) + 'px';
}

class NumberDisplay extends Component {
    constructor(props){
        super(props)

        this.click = this.click.bind(this)
    }
    shouldComponentUpdate(newprops){
        return newprops.number !== this.props.number || newprops.edit !== this.props.edit
    }
    click(){
        this.props.onClick(this.props.index)
    }
    render(){
        let cx = cn('numbers', {blink: this.props.index === this.props.edit})
        return (
            <div className="numbers-display" onClick={this.click}>
              <div className={cx} style={{top:offset(this.props.number)}}>
                <div className="number">0</div>
                <div className="number">1</div>
                <div className="number">2</div>
                <div className="number">3</div>
                <div className="number">4</div>
                <div className="number">5</div>
                <div className="number">6</div>
                <div className="number">7</div>
                <div className="number">8</div>
                <div className="number">9</div>
              </div>
            </div>
        )
    }
}

function numberDigits(num, digitCount){
    let result = [], n = num, places = Math.pow(10, digitCount - 1)
    while (places){
        result.push(Math.floor(n / places))
        n = n % places
        places = Math.floor(places / 10)
    }
    return result
}

function toNumber(digits){
    return _.reduce(digits, (acc, d)=>d+(acc*10), 0)
}

function assembleDate(digits){
    let month = toNumber(digits.slice(0, 2))
    let day   = toNumber(digits.slice(2, 4))
    let year  = toNumber(digits.slice(4))

    return {month, day, year}
}


function dateDigits({month, day, year}) {
    return numberDigits(month, 2)
        .concat(numberDigits(day, 2))
        .concat(numberDigits(year, 4))
}

export default class DateDisplay extends Component {
    constructor(props){
        super(props)

        this.state = {value: '', editing: false}

        this.inputChange   = this.inputChange.bind(this)
        this.inputBlur     = this.inputBlur.bind(this)
        this.inputKeyPress = this.inputKeyPress.bind(this)
        this.click         = this.click.bind(this)

    }
    shouldComponentUpdate(newprops, newstate){
        return !_.isEqual(newprops.date, this.props.date) || 
               !_.isEqual(newstate, this.state)
    }
    inputKeyPress(e){
        if (e.charCode === 13) {
            this.refs.input.blur();
        } else if (isSafari) {
            //Safari doesn't fire the onChange consistently for us,
            //but /does/ fire keypress events. *shrug*

            let ch = String.fromCharCode(e.charCode)
            let value = this.refs.input.value + ch
            console.log(e, e.charCode, value)
            this._updateWithValue(value)
        }
    }
    inputChange(e){
        //Safari doesn't fire the onChange event for inputs > 1,
        //so we ignore and use keypress events exclusively
        if (isSafari) return

        this._updateWithValue(e.target.value)
    }
    _updateWithValue(val){
        let value = val.replace(/[^0-9]/g, '').split('').pop()
        if (!value) return

        let {numbers, index} = this.state

        numbers = numbers.slice()
        numbers[index] = parseInt(value, 10)
        index++;

        this.setState({value, numbers, index}, ()=>{
            if (index > 7) {
                //done
                this.refs.input.blur()
            }
        })
    }
    inputBlur(){
        let {numbers} = this.state
        let date      =  assembleDate(numbers)

        this.props.onSearch(date)

        this.setState({editing: false, index: -1})
    }
    click(idx){
        console.log('focusing from index', idx)
        this.refs.input.focus()
        this.setState({editing: true, value: '', index:idx,
                       numbers:dateDigits(this.props.date)})
    }
    render(){

        let {value, numbers, editing, index} = this.state
        let digits = editing? numbers : dateDigits(this.props.date)

        return (
            <div className="date-display">
              <div className="background" />
              <NumberDisplay index={0} onClick={this.click} edit={index} number={digits[0]} />
              <NumberDisplay index={1} onClick={this.click} edit={index} number={digits[1]} />
              <div className="spacer" />
              <NumberDisplay index={2} onClick={this.click} edit={index} number={digits[2]} />
              <NumberDisplay index={3} onClick={this.click} edit={index} number={digits[3]} />
              <div className="spacer" />
              <NumberDisplay index={4} onClick={this.click} edit={index} number={digits[4]} />
              <NumberDisplay index={5} onClick={this.click} edit={index} number={digits[5]} />
              <NumberDisplay index={6} onClick={this.click} edit={index} number={digits[6]} />
              <NumberDisplay index={7} onClick={this.click} edit={index} number={digits[7]} />
              <div className="shader" />
              <input type="text" ref="input"
                     value={value}
                     className="hidden-input"
                     onKeyPress={this.inputKeyPress}
                     onBlur={this.inputBlur}
                     onChange={this.inputChange} />
            </div>
        )
    }
}
