//REDUX
export const ENTRIES_LOADED = 'ENTRIES_LOADED';
export const DRAWER_LOADED = 'DRAWER_LOADED';
export const LOAD_ENTRIES = 'LOAD_ENTRIES';
export const LOAD_SUBSCRIBER_ENTRIES = 'LOAD_SUBSCRIBER_ENTRIES';
export const FAILED_TO_LOAD_ENTRIES = 'FAILED_TO_LOAD_ENTRIES';
export const LOAD_TRACKS = 'LOAD_TRACKS';
export const TRACKS_LOADED = 'TRACKS_LOADED';
export const FAILED_TO_LOAD_TRACKS = 'FAILED_TO_LOAD_TRACKS';
export const LOAD_ALBUMS = 'LOAD_ALBUMS';
export const ALBUMS_LOADED = 'ALBUMS_LOADED';
export const FAILED_TO_LOAD_ALBUMS = 'FAILED_TO_LOAD_ALBUMS';
export const LOAD_ARTISTS = 'LOAD_ARTISTS';
export const ARTISTS_LOADED = 'ARTISTS_LOADED';
export const FAILED_TO_LOAD_ARTISTS = 'FAILED_TO_LOAD_ARTISTS';
export const LOAD_RELATED = 'LOAD_RELATED';
export const RELATED_LOADED = 'RELATED_LOADED';
export const FAILED_TO_LOAD_RELATED = 'FAILED_TO_LOAD_RELATED';
export const ADD_PLAYLIST = 'ADD_PLAYLIST';
export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST';
export const DELETE_PLAYLIST = 'DELETE_PLAYLIST';
export const REMOVE_TRACK = 'REMOVE_TRACK';
export const CHANGE_PLAYLIST_ITEMS_ORDER = 'CHANGE_PLAYLIST_ITEMS_ORDER';
export const SET_METADATA = 'SET_METADATA';
export const UPDATE_METADATA = 'UPDATE_METADATA';
export const RESET_METADATA = 'RESET_METADATA';
export const UPDATE_MOVIE = 'UPDATE_MOVIE';
export const UPDATE_FILMS= 'UPDATE_FILMS';
export const CLEAR_INTERVAL= 'CLEAR_INTERVAL';
export const ACTIVE_CONTROLLER = 'ACTIVE_CONTROLLER';
export const UPDATE_VIDEO_STATUS = 'UPDATE_VIDEO_STATUS';
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES'

export const VIDEO_PLAYING = "PLAYING";
export const VIDEO_PAUSED = "PAUSED";

export const LOAD_FREE_TRACKS = 'LOAD_FREE_TRACKS';
export const PLAYING_TRACK = 'PLAYING_TRACK';
export const PLAYING_STATE = 'PLAYING_STATE';
export const LOAD_LEFT_SIDE_ICONS = "LOAD_LEFT_SIDE_ICONS";
export const LOAD_RIGHT_SIDE_ICONS = "LOAD_RIGHT_SIDE_ICONS";
export const LOAD_POST_IT = "LOAD_POST_IT";

//PPV
export const SET_PPV_DATA = "SET_PPV_DATA";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_FEATURED = "SET_SELECTED_FEATURED";
export const SET_PPV_MOVIE = "SET_PPV_MOVIE";
export const SET_PURCHASE_STATE = "SET_PURCHASE_STATE";

export const SET_USERDATA = 'SET_USERDATA';

//TOGGLES : completion, original , bootleg, release.
export const UPDATE_TOGGLE = 'UPDATE_TOGGLE';

//article 
export const ARTICLE_VIEW ='ARTICLE_VIEW';
export const SET_ARTICLE =' SET_ARTICLE'

//search
export const SET_SEARCH = "SET_SEARCH";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const RESET_SEARCH = "RESET_SEARCH";
export const CHANGE_SEARCH_CATEGORY = "CHANGE_SEARCH_CATEGORY";
