import _ from 'lodash'
import moment from 'moment'
import store from '../store';
import {getThumbnail}  from '../services/vimeo'
import {getBrioVideoInformation} from '../services/brio'
import {parseOnlyLetters} from '../util/strings'

const parseImage = (film,contentType)=> {
    return _.get(film, `${[contentType]}.fields.file.url`, '')
}

const isLiveStreaming = film => !!(film.liveStreamingUrlPrimary || film.liveStreamingUrlSecondary || film.liveStreamingUrlTertiary)

export const parseMovie = async film => {
    const now = moment()
    let endText;
    // (VOD and live) Asset is availbe on Hearse Theater
    const availableAt = new Date(film.liveDate)
    // (only live) Live streaming will ends
    const end = new Date(film.liveStreamingExpireDate)
    film.isLiveStreaming = isLiveStreaming(film)
    film.availableAt = +availableAt
    film.startDate = moment(availableAt)
    film.videoID = parseInt(film.videoID, 10)
    film.orastreamVideoId = film.orastreamVideoId
    film.imageURL = parseImage(film,'thumbnail')
    film.liveStreamingPrePoster =parseImage(film,'liveStreamingPrePoster')
    film.liveStreamingPostPoster =parseImage(film,'liveStreamingPostPoster')
    film.isAfterStartDate = now.isAfter(film.startDate)
    film.dateAvailability = film.startDate.format("MMMM D, YYYY h[:]mma")
    const startText = film.startDate.format("h[:]mma, MMMM Do, YYYY")

    if(film.isLiveStreaming){
        film.endDate = moment(end)
        film.isBetweenStartDateAndEndDate = now.isBetween(film.startDate, film.endDate)
        endText = film.endDate.format("h[:]mma, MMMM Do, YYYY")
    }

    if(film.videoID && !film.orastreamVideoId) {
        const _thumbnail = await getThumbnail(film.videoID);
        film.thumbnail = _thumbnail;
    }
    if(film.orastreamVideoId){
        const brioVideo = await getBrioVideoInformation(film.orastreamVideoId);
        const {html, cover} = brioVideo;
        film.thumbnail = cover;
        film.iframe = html;
    }
    

    film.dateText = film.isLiveStreaming && film.isAfterStartDate 
                    ? `Available until: ${endText}` 
                    : availableAt > new Date() ?  
                      `Available starting: ${startText}`
                    : ``

    film.nowShowing = film.isLiveStreaming && film.isBetweenStartDateAndEndDate|| availableAt < new Date()
                    ? true 
                    : false
          
    return film
}
export const getMovieByQuery = (videoQuery)=>{
    const movieNightFilm = store.getState().movieNightFilm.all || [];
    let movieQuery = movieNightFilm.filter(movie=>{        
        return     videoQuery == parseOnlyLetters(movie.title) ||
                   videoQuery == movie._id.toLowerCase()       ||
                   videoQuery == movie.videoID 
    })
    return movieQuery 
}

export const NO_SUBCATEGORY = "None"; // Dummy for films without subcategory

export const getMoviesByScreenCategory = (category)=> {
    const movieFilms     = { nowShowing: [], scheduled: [] }
    const movieNightFilm = store.getState().movieNightFilm[category] || [];
    
    const movieFilmsBySubcategory = { nowShowing: [], scheduled: [] };
    const moviesBySubcategory = store.getState().movieNightFilm.hearseTheater[category] || [];
    
    for(let i = 0; i <= movieNightFilm.length; i++){
        let film= movieNightFilm[i];
            if(film && !(film.isLiveStreaming &&  film.endDate < new Date())){
                movieFilms[film.nowShowing ? "nowShowing" : "scheduled"].push(film)
            } 
    }
    
    // parsing films with categories and subcategories
    const films = moviesBySubcategory.films;
    const subcategories = moviesBySubcategory.subcategories;
    // Adding dummy category to order films position
    if (!subcategories.some( a => a.title === NO_SUBCATEGORY))
        subcategories.push({title: NO_SUBCATEGORY, position: 9999}); 

    for (let i = 0; i < subcategories.length; i++) {
        const subcategory = subcategories[i];
        let nowShowing = {title: subcategory.title, films: []};
        let scheduled = {title: subcategory.title, films: []};
        for (let film of films[subcategory.title]) {
            if(film && !(film.isLiveStreaming &&  film.endDate < new Date())){ 
                film.nowShowing ? nowShowing.films.push(film) : scheduled.films.push(film);
            }
        }
        nowShowing.films.sort( (a,b) => {
            return new Date(b.liveDate) - new Date(a.liveDate)
        })
        scheduled.films.sort( (a,b) => {
            return new Date(a.liveDate) - new Date(b.liveDate)
        })

        movieFilmsBySubcategory.nowShowing.push(nowShowing);
        movieFilmsBySubcategory.scheduled.push(scheduled);
    }
    // console.log("HERE",movieFilmsBySubcategory);
    // end parsing films

    movieFilms.nowShowing.sort((a,b)=>{
        return new Date(b.liveDate) - new Date(a.liveDate)
    })
    movieFilms.scheduled.sort((a,b)=>{
        return new Date(a.liveDate) - new Date(b.liveDate)
    })

    return {movies: movieFilms, parsedFilms: movieFilmsBySubcategory}
}

