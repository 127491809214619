import React, {Component} from 'react'
import cn from 'classnames';

class MenuPopup extends Component {
    constructor(props, ctx){
        super(props, ctx)

        this.state = {
            showEmbedModal: false,
        }

        this.hidePopup = this.hidePopup.bind(this)
    }
    componentWillMount(){
        document.addEventListener('mousedown', this.hidePopup)
    }
    hidePopup(e){

        if (
          e.target.dataset.isplaylistitem ||
          e.target.className.includes("copy") ||
          e.target.className == 'close-btn'
        ) {
          return;
        }

        e.preventDefault()
        e.stopPropagation()

        this.unbind()
        this.props.hidePopup(false, false)
    }
    componentWillUnmount(){
        this.unbind()
    }
    unbind(){
        document.removeEventListener('mousedown', this.hidePopup)
    }
    render(){
        const { showPlaylistPopup,toggleEmbedModal, type, mainMenu = true, playlistPopup = false} = this.props;

        return (
            <div className={cn("playlist-popup menu")} style={this.props.style}>
              <div className="contentWrapper">
                  <div className="new" data-isplaylistitem="1" onClick={() => {

                      showPlaylistPopup(playlistPopup, true, true);  
                  }}>
                    <div className="text" data-isplaylistitem="1">Add to playlist</div>
                  </div>
                  <div className="new" data-isplaylistitem="1" onClick={(e) => {
                      toggleEmbedModal({
                        clientX: e.clientX,
                        clientY: e.clientY,
                        data: e.target.getBoundingClientRect(),
                      }, mainMenu)
                  }}>
                    <div className="text" data-isplaylistitem="1">Embed {type}</div>
                  </div>
              </div>
            </div>
        )
    }
}

export default MenuPopup;
