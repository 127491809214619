let current = null
let stack   = []

const History = {
    init(){
    },
    updatePathname(path){
        if (current === path) return 

        if (this.lastPath() === path) {
            stack.shift()
            current = path
        } else {
            if (current) stack.unshift(current);
            current = path 
        }
    },
    lastPath(){
        return stack[0]
    }
}

export default History
