import React, {Component} from 'react';
import Easing               from "../util/easing";
import { connect }        from 'react-redux';
import {setTimelineControllers} from "../actions/timeline"


export const clearTimelineControls =(pauseActive=' ')=>{
    clearInterval(window.interval)
    setTimelineControllers({nextActive:' non-active', prevActive:' non-active',pauseActive,  clickCounter: 0})
}
class TimelineController extends Component {
    constructor(props,b){
        super(props,b);
        this.sliderButtonController = this.sliderButtonController.bind(this)
        this.scrollAnimation     = this.scrollAnimation.bind(this)
        this.pauseButton         = this.pauseButton.bind(this)
        

        this.interval            = null;
        this.count               = 0;
        this.userAgent = navigator.userAgent.toLowerCase().indexOf('chrome') > -1 
                         ?'chrome': navigator.userAgent.toLowerCase().indexOf('firefox') > -1 
                         ?'firefox':'safari'
         
        this.controllerSpeedOne  =   this.userAgent =='safari'? 0.01 : this.userAgent  =='chrome'? 0.002 : 0.0089
        this.controllerSpeedTwo  =   this.userAgent =='safari'? 0.04 :  0.2
        this.controllerSpeedThree =  this.userAgent =='safari'? 0.09 :  0.9
        this.intervalSpeed;


        let min = _.has(props, 'min') ? props.min : 0
        let max = _.has(props, 'max') ? props.max : 100

        let sliderBtnType =' stop'
      
        this.state = {min, max,sliderBtnType}

    }
    componentWillUnmount(){
        clearTimelineControls()
    }
    scrollAnimation(newPosition,fpsNumber){ 
        let frameSpeed = this.count == this.controllerSpeedOne && this.userAgent  =='chrome' || 
                         this.count == this.controllerSpeedOne && this.userAgent  =='firefox'? 100 : 1000

        let {pct,pagePercentage} = this.props
        let delta      = newPosition - pct
        let duration   = Math.abs(delta) < pagePercentage ? 500 : 1000
        let fps        = fpsNumber
        let frame      = frameSpeed/fps
        let frameCount = duration / frame
        let step       = 1.0/ frameCount
      
        let current      = 0
        let currentFrame = 0

            currentFrame++
            current += step
            let pos = (Easing.easeInOutQuad(current) * delta) + pct
            this.props.onScroll(pos)
            if (currentFrame >= frameCount)  {
                this.props.onScroll(newPosition)
            }
      }

    clickCount (times){
        let count  = (
            times === 1 ? this.controllerSpeedOne: 
            times === 2 ? this.controllerSpeedTwo: 
            times >=  3 ? this.controllerSpeedThree: 
            0
          );
        this.intervalSpeed = this.userAgent =='safari'
                             ? 10  : this.userAgent  =='chrome'&& count == this.controllerSpeedOne 
                             ? 10: this.userAgent  =='chrome'
                             ? 60: 40
        
        return count; 
    } 

    clickClass (times){
        let className  = (
            times === this.controllerSpeedOne ? ' one' : 
            times === this.controllerSpeedTwo? ' two': 
            times >= this.controllerSpeedThree? ' three': 
            ' three' 
          );
        return className; 
    }
    incrementalInterval(pct, count, max){      
        window.interval = setInterval(()=>{
            pct += count
            if (pct < max)  {
                this.scrollAnimation(pct,1) 
            }else{
                this.pauseButton(' non-active', ' non-active', ' active')
            }
        }, this.intervalSpeed  ) 
    }

    decrementalInterval(pct, count, min){

        window.interval= setInterval(()=>{

                pct -= count
                if (pct > min)  {
                    this.scrollAnimation(pct,1)
                }else{
                    this.pauseButton(' non-active',' non-active', ' active')
                }
        }, this.intervalSpeed  ) 
    }

    sliderButtonController(pct, type){

        clearInterval(window.interval)
        this.setState({sliderBtnType : type})  

        this.props.pixelScrollEnded()

        let clickChange, counterState;
        let {min , max, clickCounter, } = this.props

        let clickCount= clickCounter > 1 ? clickCounter >= 3 ? 3 : 2 :0

                if(type !== this.state.sliderBtnType){

                    counterState =clickCount == 0 ? 1 : clickCount 

                    this.count  = this.clickCount(counterState)

                    clickChange = this.count !==this.controllerSpeedOne 
                                ? this.count == this.controllerSpeedThree
                                ? -0.05:-this.controllerSpeedTwo: this.state.sliderBtnType !=='stop'
                                ? -this.controllerSpeedOne:0;
                }else{
                    counterState = clickCounter + 1;

                    this.count  = this.clickCount(counterState)

                    clickChange = this.count ==this.controllerSpeedTwo
                                ? -this.controllerSpeedTwo:this.count == this.controllerSpeedThree && clickCounter>2
                                ? 0 :this.count == this.controllerSpeedThree && clickCounter==2
                                ? -this.controllerSpeedThree : -this.controllerSpeedOne;              
                }
        let classCtx =  this.clickClass(this.count)

            if(type =='prevButton'){

                if(((pct-this.count) - clickChange)+this.count <= min)return

                this.decrementalInterval((pct-this.count) - clickChange, this.count, min-1)  

                setTimelineControllers({
                    nextActive: ' non-active', 
                    prevActive: classCtx, 
                    pauseActive: ' ',
                    clickCounter:counterState})

            }else if(type=='nextButton'){

                if(((pct-this.count) - clickChange)+this.count >= max)return

                this.incrementalInterval((pct+this.count) + clickChange, this.count, max+1) 

                setTimelineControllers({
                    nextActive: classCtx, 
                    prevActive: ' non-active', 
                    pauseActive: ' ',
                    clickCounter:counterState})

            }else if(type=='stop'){
                return;
            }else{
                this.pauseButton(' non-active', ' non-active', ' active')
            }
            
    }
    pauseButton(){
        clearTimelineControls(' active')

        this.setState({sliderBtnType:'stop'},
        (()=>{this.sliderButtonController(this.props.pct, 'stop')}))  

        setTimeout(()=>{
            clearTimelineControls()
        },100)
         
    }


    render(){
        let {pct,nextActive, prevActive,pauseActive, mouseDown, touchStart, getThumbStyle} = this.props
        let {sliderButtonController, pauseButton} = this

        return (
                  <div ref="thumb" style={getThumbStyle()}
                    className="horizontal-slider-thumb scroll" > 
                    
                   <div className="horizontal-slider-thumb-hit-area"
                       onMouseDown={mouseDown} onTouchStart={touchStart} >
                    </div>

                   {/* ######## temporarilly disable next , prev , paused functionality ######### */}
                  {/* <div ref="thumb" style={{left : `45%`}}className="horizontal-slider-thumb  controllers" >   */}
                        
                      
                       {/* <span id="back"className={'back' + prevActive}
                       onClick={((e)=>sliderButtonController(pct,'prevButton'))} 
                       onTouchStart={((e)=>sliderButtonController(pct,'prevButton'))} 
                       /> */}
                       {/* <span className={'paused' + pauseActive } 
                             onClick={(()=>pauseButton)} 
                             onMouseDown={mouseDown}
                             onTouchStart={touchStart}
                       /> */}
                       {/* <span id="next"className={'next' + nextActive}
                       onClick={((e)=>sliderButtonController(pct,'nextButton'))}
                       onTouchStart = {((e)=>sliderButtonController(pct,'nextButton'))}
                       /> */}
                  </div>); 
    }
}
const mapStateToProps = function (state) {
    return {    nextActive: state.timelineControllers.nextActive, 
                prevActive:state.timelineControllers.prevActive , 
                pauseActive:state.timelineControllers.pauseActive,
                clickCounter: state.timelineControllers.clickCounter };
};
export default connect(mapStateToProps)(TimelineController);