import React, { useEffect, useRef, useState } from 'react';
import '../../scss/nyatc/reset.scss';
import '../../scss/nyatc/nyatc.newspaper.scss';
import NewspaperPageContent from './content';

import { NewspaperFrame } from './frame';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventBusPublisher from './event-bus-publisher';
import NewsArticle from './nyatc_article';
import Top40Tracks from './top-40-tracks';

export function NewspaperPage(props) {
 const router = props.router;
 const params = router.params;
 const query = router?.location?.query || {};
 let page = parseInt(params ? params.page : 1) || 1;
 const isArticleView = router?.location?.pathname?.includes('article') && 'id' in query;
 const isTop40Tracks = router?.location?.pathname?.includes('top-40-tracks');

 useEffect(() => {
  if (isArticleView) {
   EventBusPublisher.changePage(page);
   return;
  }
  if (isTop40Tracks) {
   EventBusPublisher.changePage('top-40-tracks');
   return;
  }
 }, [page]);

 if (isTop40Tracks) {
  return (
   <NewspaperFrame router={router} page={page}>
    <Top40Tracks router={router} />
   </NewspaperFrame>
  );
 }

 if (isArticleView) {
  return (
   <NewspaperFrame router={router} page={page} isArticleView={isArticleView} >
    <NewsArticle router={router} page={page} />
   </NewspaperFrame>
  );
 }

 return (
  <NewspaperFrame router={router} page={page}>
   <NewspaperPageContent page={page} router={router} isArticleView={isArticleView} />
  </NewspaperFrame>
 );
}

NewspaperPage.contextTypes = {
 router: PropTypes.object.isRequired,
 page: PropTypes.number,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(NewspaperPage);
