import _ from 'lodash'
import { ENTRIES_LOADED } from '../constants';

const initialState = {all:[], byId:{}, byContentfulId:{}}

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.more) {
                let byId = {}, byContentfulId = {}
                let all = _.map(action.entries.more, more=>{
                    byId[more.id] = more
                    byContentfulId[more._id] = more
                    return more
                })
                return {all, byId, byContentfulId}
            }
            break;
    }
    return state;
}
