import React from "react";
const Mp3PlayerControls = (props)=>{

      const {mp3Playing} = props
      let btnClass = mp3Playing? ' playing' : ' paused'

    return (
      <div  id="mp3-play" className={`btn ${btnClass}`} >
      </div>
    )
}

export default Mp3PlayerControls;