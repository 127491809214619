
import React   from 'react'
 const SearchMenu = (props) => {
    const {categories,chooseCategory ,queryCategory,categoryType} = props
    const categoryItem = [];
    if(categories == null) return null;
    
    categories && categories.forEach(_category=>{
        const style = queryCategory == _category ? "menu-item active" : "menu-item";
        categoryItem.push(<div  className ={style} key={_category} onClick={()=>chooseCategory(_category)}>{categoryType(_category)}</div>)
    })

        return <div className="search-menu">{categoryItem}</div>
}
export default SearchMenu;