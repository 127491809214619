import React, {Component}      from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import _                       from 'lodash'

export default class RouteTransitioner extends Component {
    constructor(props, b){
        super(props, b)
        this.state = {oldpath:null}
    }
    componentWillReceiveProps(newprops){
        let oldpath = this.props.location
        let newpath = newprops.location
        if (oldpath === newpath) return
        this.setState({oldpath})
    }
    componentWillMount(){
        let oldpath = this.props.location
        this.setState({oldpath})
    }
    getTransition(newpath){
        let {oldpath} = this.state

        if (!oldpath || oldpath === newpath) return ["none"];

        function match(test, path){
            if (test === path) return true
            if (test && test.test) return test.test(path)
            return false
        }

        let tests = this.props.paths
        for (let i = 0; i < tests.length; i++){
            let test = tests[i]
            if(match(test[0], oldpath) && match(test[1], newpath)) {
                return [test[2], test[3]]
            }
        }
        return ["none"]
    }
    render(){
        let pathname           = this.props.location
        let [transition, hook] = this.getTransition(pathname)

        if (_.isFunction(hook)) {
            hook();
            _.defer(()=>this.setState({oldpath:pathname}))
        }

        let durations  = this.props.durations || {}

        let duration = durations[transition] || 1000

        if (transition === 'none' || !duration || !_.isFinite(duration)) {
            duration = 1 //it is important that this be nonzero
        }

        let enter=duration, leave=duration

        return (<ReactCSSTransitionGroup component="div" 
                                         transitionName={transition}
                                         transitionEnterTimeout={enter}
                                         transitionLeaveTimeout={leave}>
                {React.cloneElement(this.props.children, {key: pathname})}
                </ReactCSSTransitionGroup>)
    }
}
