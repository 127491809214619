import React, { Component } from 'react';
import cn from 'classnames';
import { getUserSettings, setUserSettings } from '../services/api';


class NotificationsPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subscribed: false,
            current: false,
            status: 'loading',
        };

        this.handleNewsletterChange = this.handleNewsletterChange.bind(this);
        this.handleNewsletterSubmit = this.handleNewsletterSubmit.bind(this);
    }

    handleNewsletterChange(event) {
        this.setState({
            subscribed: event.target.value === "true"
        });
    }

    handleNewsletterSubmit(event) {
        this.setState({
            status: 'loading',
        }, () => {
            setUserSettings({
                type: 'cdc_newsletter',
                values: { subscribed: this.state.subscribed },
            }).then(() => {
                this.setState({
                    status: 'update_ok',
                    current: this.state.subscribed
                })
            }).catch(() => {
                this.setState({
                    status: 'update_error'
                })
            });
        });
    }

    componentDidMount() {
        getUserSettings().then((settings) => {
            if(settings && settings.cdc && typeof settings.cdc.subscribed !== 'undefined') {
                this.setState({
                    status: 'initial',
                    subscribed: settings.cdc.subscribed,
                    current: settings.cdc.subscribed
                });
            } else {
               this.setState({
                    status: 'initial',
                    subscribed: false,
                    current: false,
                });
            }
        }).catch(() => {
            this.setState({
                status: 'update_error'
            })
        });
    }

    _views() {
        switch (this.state.status) {
            case 'initial':
                const nextState = this.state.current === this.state.subscribed
                const cx = cn('button', {'clear disabled': nextState})
                return (
                <div className="notifications-content">
                    <span className="notifications-message">Would you like to receive newsletters from NYA?</span>
                    <form className="form-container">
                        <div className="option">
                            <label>
                                <input type="radio" value={true} checked={this.state.subscribed} onChange={this.handleNewsletterChange}/>
                                <span className="check" />
                                YES
                            </label>
                        </div>
                        <div className="option">
                            <label>
                                <input type="radio" value={false} checked={!this.state.subscribed} onChange={this.handleNewsletterChange} />
                                <span className="check" />
                                NO
                            </label>
                        </div>
                    </form>
                    <button className={cx} onClick={!nextState ? this.handleNewsletterSubmit : undefined}>SUBMIT CHANGE</button>
                </div>
                )
            case 'loading':
                return (
                    <div className="notifications-content">
                        <span className="notifications-message">Updating your notification settings...</span>
                    </div>
                )
            case 'update_error':
                return (
                    <div className="notifications-content">
                        <span className="notifications-message">We're sorry, there was an error updating your notification settings.<br />
                Please try again.</span>
                    </div>
                )
            case 'update_ok':
                return (
                    <div className="notifications-content">
                        <span className="notifications-message">Your notification settings have been updated</span>
                    </div>
                )
            default:
                return (
                    <div className="notifications-content">
                        <span className="notifications-message">Updating your notification settings...</span>
                    </div>
                )
        }
    }

    render() {
        return (
            <div className="notifications-panel panel">
                <div className="title">Notifications settings</div>
                {this._views()}
            </div>
        );
    }
}

export default NotificationsPanel;