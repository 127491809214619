import cn                 from 'classnames'
import React, {Component} from 'react';
import TimelineEventInfo  from './timeline-event-info'
import {getTourZOByYear}  from '../drawer-items'


let calcPosition = ({month, day}) => {
    return (((1/12) * (month-1)) + ((1/31) * day * (1/12))) * 100
}

export default class TourZO extends Component {
    constructor(props){
        super(props)

        this.onClick    = this.onClick.bind(this)
        this.mouseEnter = this.mouseEnter.bind(this)

    }
    shouldComponentUpdate(newprops){
        return newprops.selectedEventId !== this.props.selectedEventId
    }
    onClick(e){
        let id = e.target.getAttribute('data-id')
        if (!id) return
        e.preventDefault()
        e.stopPropagation()
        this.props.onClick(id)
    }
    mouseEnter(e){
        let id = e.target.getAttribute('data-id')
        if (!id) return
        if (id === this.props.selectedEventId) return
        e.preventDefault()
        e.stopPropagation()
        this.props.onClick(id)
    }
    getTileForYear(tour, year, key) {

        let idx   = year - tour.startDate.year
        let image = tour.tiles[idx]

        let style = {backgroundImage:`url(${image.url})`,
                     width:image.width, height: image.height}

        let selected = tour.id === this.props.selectedEventId

        let snapleft = false, snapright = false;
        let containsBubble = idx === tour.centerTileIndex
        let bubbleOffset   = tour.centerTileLeftOffset

        if (tour.tiles.length === 1) {

            style.left = calcPosition(tour.startDate) + '%'

        } else {

            snapleft = idx === tour.tiles.length - 1
            snapright = !snapleft
        }

        let cx = cn('tour', {snapleft, snapright, selected})

        return (
            <div key={key} className={cx} style={style}
                 data-id={tour.id}
                 onClick={this.onClick}>
              { selected && containsBubble &&
                <div className="tour-info-wrapper" style={{left:bubbleOffset+'px'}}>
                 <TimelineEventInfo event={tour}
                                    showMoreInfo={this.props.showMoreInfo}
                                    showVideo={this.props.showVideo}
                                    showPhotos={this.props.showPhotos}
                                    onClose={this.props.onClose} />
                </div>
              }
            </div>
        )

    }
    render(){
        let {year} = this.props
        let tours = getTourZOByYear(year)

        if (!tours) return null
        let tiles = tours.map((tour, idx)=>this.getTileForYear(tour, year, idx))

        return (
            <div className="tour-wrapper">
              {tiles}
            </div>
        )
    }
}
