import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware                      from 'redux-saga'
import reducers                                  from './reducers'
import {initialSaga, exclusiveSaga}              from './services/sagas';
import {usingStubs}                              from './drawer-items'
import logger from 'redux-logger';

const sagaMiddleware = createSagaMiddleware() // get the saga middleware
const devtools = window.devToolsExtension || (() => no => no); // help for redux extension
const middlewares = [sagaMiddleware] // array of middlewares to be applied
if (process.env.NODE_ENV === 'development') middlewares.push(logger);
const enhancers = [applyMiddleware(...middlewares), devtools()]; // bundle up our enhancements

const store = createStore(
  reducers,
  compose(...enhancers)
)

// console.log('Starting state', store.getState());
if (window.devToolsExtension) {
    window.devToolsExtension.updateStore(store);
}

export function fetchInitial() {
  if (!usingStubs()) sagaMiddleware.run(initialSaga);
  console.log("fetchInitial using stubs? " , usingStubs())
}

export function fetchExclusive() {
  if (!usingStubs()) sagaMiddleware.run(exclusiveSaga);
  console.log("fetchExclusive using stubs? " , usingStubs())
}

export default store
