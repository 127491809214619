import soundManager from './vendor/soundmanager2'
import _            from 'lodash'

function logging(str, fn){
    return ()=>{
        console.log(str)
        fn()
    }
}

export const soundReady = () => new Promise((resolve, reject)=>{
    soundManager.setup({
        debugMode:false,
        url: '/swf/',
        html5PollingInterval:20,
        onready: logging('sound manager ready', resolve),
        ontimeout: logging('sound manager timeout', reject)
    })
})

let currentSound = null

export function play(url, onLoaded=()=>{}, onFinish=()=>{}){
    let sound = soundManager.createSound({url})
    if (currentSound) {
        currentSound.stop()
        currentSound.destruct()
    }
    currentSound = sound
    currentSound.load({
        onload:function(){
            if (currentSound !== sound) return

            console.log('got duration: ', this.duration)

            onLoaded(this.duration, this)
            currentSound.play()
        },
        onfinish: onFinish
    })
}

export function pause(){
   currentSound && currentSound.pause()
}

export function resume(){
   currentSound && currentSound.resume()
}

export function clear(){
    if (currentSound) {
        currentSound.stop()
        currentSound.destruct()
    }
    currentSound = null
}

export function preloadSoundSprite(id, url, opts={}){
    return new Promise((resolve, reject)=>{
        let sound = soundManager.createSound(_.extend({id, url}, opts))
        sound.load({onload:resolve})
    })
}

export function playSoundSprite(id, next=()=>{}){
    let playedNext = false
    soundManager.play(id, {onfinish:next})
}

let gaplessPlaying = []
let beforeEnd = 0.75
let cancelThreshold = 0.0
let shouldCancel = false

export function setGaplessTrigger (ending) {
    beforeEnd = ending
}

export function playSoundSpriteGapless(id, next=()=>{}){
    let playedNext = false
    let start
    gaplessPlaying.push(id)
    soundManager.play(id, {
        //from: 500,
        onfinish:function(){
            gaplessPlaying = gaplessPlaying.filter(it=>it!==id)
            // just in case it got missed somehow...
            if (!playedNext) {
                playedNext = true
                if (!shouldCancel) {
                    const len = Date.now() - start
                    const rem = beforeEnd - 1.0
                    const time = Math.max(0, rem * len)
                    setTimeout(next, time)
                }
            }
        },
        whileplaying:function(){
            if (!start) start = Date.now()
            const pct = (this.position / this.duration);
            if (pct < cancelThreshold) {
                playedNext = true
                soundManager.stop(id);
            }
            if (!playedNext && pct > beforeEnd) {
                playedNext = true
                if (!shouldCancel) next()
            } else if (!playedNext && pct > 0.95) {
                /* the onfinish handler sometimes gets called very
                late in safari, so we test for being near to the end
                during playback instead*/
                playedNext = true
                const len = Date.now() - start
                const rem = beforeEnd - 1.0
                const time = Math.max(0, rem * len)
                if (!shouldCancel) setTimeout(next, time)
            }
        }
    })
}

export function willStartSFX() {
    cancelThreshold = 0.0
    shouldCancel = false
}

window.CANCEL_THRESHOLD = 0.3;
export function cancelAllSoundSpritesGapless() {
    beforeEnd = 2.0
    shouldCancel = true
    cancelThreshold = window.CANCEL_THRESHOLD
}

export function stopAllSoundSpritesGapless () {
    gaplessPlaying.forEach(stopSoundSprite)
    gaplessPlaying = []
}

export function stopSoundSprite(id){
    soundManager.stop(id)
}
