import React, {Component} from 'react'
import { connect }         from 'react-redux';
import { addTracksToPlaylist } from '../services/api';
import { userIsNotSubscribed } from '../user'
import { processState }   from '../util/stateProcessing'
import { updatePlaylistAction }    from '../actions/playlists'
import CreatePlaylistModal     from './create-playlist'
import cn from 'classnames';

class PlaylistPopup extends Component {
    constructor(props, ctx){
        super(props, ctx)
        const showAddPlaylistModal = this.props.showAddPlaylistModal || false

        this.state = {
            showAddPlaylistModal
        }

        this.hidePopup = this.hidePopup.bind(this)
    }
    componentDidMount() {
        const { customPlaylists } = this.props
    }
    componentWillMount(){
        document.addEventListener('mousedown', this.hidePopup)
    }
    hidePopup(e){
        if (e.target.dataset.isplaylistitem) {
          return
        }
        // if (e.target.closest('.playlist-popup')) {
        //   return
        // }

        e.preventDefault()
        e.stopPropagation()

        this.unbind()
        this.props.hidePopup()
    }
    componentWillUnmount(){
        this.unbind()
    }
    unbind(){
        document.removeEventListener('mousedown', this.hidePopup)
    }
    render(){
        const { tapeLeft, customPlaylists } = this.props
        const { showAddPlaylistModal } = this.state

        const onCloseModal = () => {
            this.setState({showAddPlaylistModal: false})
            this.props.hidePopup()
        }

        return (
            <div className={cn("playlist-popup", {'playlist-popup--left': tapeLeft})} style={this.props.style}>
              <div className="header">Add to playlist</div>
              <div className="contentWrapper">
                  <div className="new" data-isplaylistitem="1" onClick={() => {
                    if (userIsNotSubscribed()) {
                        window.subs()
                        return
                    }
                    document.removeEventListener('mousedown', this.hidePopup)
                    this.setState({showAddPlaylistModal: true})
                }}>
                    <div className="button" data-isplaylistitem="1" />
                    <div className="text" data-isplaylistitem="1">New playlist</div>
                  </div>
                  <ul className="playlists">
                    { customPlaylists.map(({ title, _id }, idx) => (
                        <li
                          key={`item-${idx}`}
                          data-isplaylistitem="1"
                          onClick={() => {
                            addTracksToPlaylist(_id, this.props.tracks)
                                .then(result => {
                                    this.props.dispatch(updatePlaylistAction(result.body))
                                    this.props.hidePopup()
                                })
                                .catch(err => {
                                    console.error(err)
                                    this.props.hidePopup()
                                })
                          }}
                          className="playlist"
                        >{title}</li>
                    )) }
                  </ul>
              </div>
              <CreatePlaylistModal
              showAddPlaylistModal ={showAddPlaylistModal}
              onCloseModal={onCloseModal}
              dispatch={this.props.dispatch}
              modalerror={this.state.modalerror}
              tracks={this.props.tracks}
              />
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    const processedState = processState(state)
    const { items } = processedState
    return { customPlaylists: items.filter((item) => item.isPlaylist && item.type !== 'playlist') };
};

export default connect(mapStateToProps)(PlaylistPopup);
