import _ from 'lodash'
import { ENTRIES_LOADED } from '../constants';

const initialState = {byYear: {}, byId: {}, all:[]}

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.tourZO) {
                let byYear = _.clone(initialState.byYear)
                let byId = _.clone(initialState.byId)
                let all = initialState.all.slice()
                Object.keys(action.entries.tourZO).forEach(k=>{
                    let item = action.entries.tourZO[k]

                    for (var i = 0; i < item.tiles.length; i++) {
                        let year = item.startDate.year + i
                        byYear[year] = byYear[year] || []
                        byYear[year].push(item)
                        byId[item.id] = item
                        all.push(item)
                    }

                })
                return {byYear, byId, all}
            }
            break;
    }
    return state;
}
