import React, { Component } from "react";
import MusicPlayer from "../components/music-player";
import Mp3PlayerControls from "../components/new-player/mp3-player/mp3-btn";

export default class PlayerBtn extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
    this.renderPlayer = this.renderPlayer.bind(this);
  }
  renderPlayer(track, disabledbyId) {
    const { mp3audio } = track;
    const { currentlyPlaying, isMp3Playing, tour = false} = this.props;
    if (mp3audio) {
      return (
        <Mp3PlayerControls
          trackUrl={mp3audio}
          mp3Playing={isMp3Playing}
          currentlyPlaying={currentlyPlaying}
        />
      );
    } else {
      return (
        <MusicPlayer
          item={track}
          scaled={true}
          disabled={disabledbyId}
          makeStateInSync
          dummy
          tour={tour}
        />
      );
    }
  }
  render() {
    const { track, disabledbyId, style } = this.props;

    return (
      <td
        className="music-wrapper-wrapper music-wrapper-wrapper--dummy"
        valign="center"
        onClick={() => this.props.onTrackElementClick({ track, disabledbyId })}
      >
        <div className="music-wrapper" style={style}>
          {this.renderPlayer(track, disabledbyId)}
        </div>
      </td>
    );
  }
}
