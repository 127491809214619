import { ENTRIES_LOADED, TRACKS_LOADED, LOAD_FREE_TRACKS, PLAYING_TRACK , PLAYING_STATE } from '../constants';
import {makeInitialState, index} from './utils'

const initialState = makeInitialState()

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.tracks){ 
                return Object.assign(state, index(action.entries.tracks, state));}
            break;
        case LOAD_FREE_TRACKS:
            return Object.assign(state, {freeTracks: action.freeTracks});
        case TRACKS_LOADED:
            if(action.tracks) return index(action.tracks, state);
            break;
        case PLAYING_TRACK:
            return {
                ...state,
                playingTrack:action.playingTrack
            }
        case PLAYING_STATE:
            return {
                ...state,
                playing:action.playing,
            }
    }
    return state;
}
