import React, {Component} from 'react'
import PropTypes          from 'prop-types'
import VerticalCenter     from './components/vertical-center'

export default class LiveStreamContainer extends Component {
    constructor(props, ctx){
        super(props, ctx)

        this.onBGClick = this.onBGClick.bind(this)
    }
    componentDidMount(){
        window.pauseAudioIfNeeded && window.pauseAudioIfNeeded()
    }
    componentWillUnmount(){
        window.resumeAudioIfNeeded && window.resumeAudioIfNeeded()
    }
    onBGClick(e){
        if (e.target === this.refs.background) {
            this.context.router.goBack()
        }
    }
    render(){
        let style = {border:'none', overflow:'hidden'}

        const useOriginalEmbedCode = true

        let w = (476 * 2)
        let h = w * 1//(3/4)
        let width = w + ""
        let height = h + ""

        /* don't forget to update the style attribute! */
        /* <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FGreenDay%2Fvideos%2F10154440256324521%2F&show_text=0&width=476" width="476" height="476" style={style} scrolling="no" frameBorder="0" allowTransparency="true" allowFullScreen="true"></iframe> */

        let url = `https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/NeilYoung/videos/10159601154410317/&show_text=0&width=${width}`

        return (
            <div id="video-player" ref="background" onClick={this.onBGClick}>
              <VerticalCenter className="player">
                {
                    useOriginalEmbedCode &&
                    <iframe src="https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/NeilYoung/videos/10159601154410317/&show_text=0&width=476" width="476" height="476" style={style} scrolling="no" frameBorder="0" allowTransparency="true" allowFullScreen="true"></iframe>}
                 {
                     !useOriginalEmbedCode &&
                     <iframe src={url} width={width} height={height} style={style} scrolling="no" frameBorder="0" allowTransparency="true" allowFullScreen="true"></iframe>

                 }
              </VerticalCenter>
            </div>
        )
    }
}

LiveStreamContainer.contextTypes = {
    router: PropTypes.object.isRequired
}
