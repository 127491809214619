import React, {useEffect, useState} from "react";
import NewspaperHeadline from "./header/headline";
import NewspaperMidline from "./header/middleline";
import Tabsline from "./header/tabsline";
import PropTypes from "prop-types";
import {isInIOSApp} from "../platform";
import SplashScreen from "../splash-screen";
import VerticalCenter from "../components/vertical-center";
import frame1 from '../../images/loading-indicator/frame_01.png';

export const debounce = (func, wait) => {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

function Loader() {
  return (
    <VerticalCenter style={{width:'100%', height:'100%'}}>
      <center style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
        <img id="image-loader" src={frame1} alt="loader" style={{
        }}/>
      </center>
    </VerticalCenter>
  )
}

export function NewspaperFrame(props) {
  const {children, router, page= 1, isArticleView} = props;
  const [opacity, setOpacity] = useState(1)
  const [isFixed, setIsFixed] = useState(false)
  const [lastPosition, setLastPosition] = useState(0);
  const [lastPage, setLastPage] = useState(page);
  const [loading, setLoading] = useState(true);


  const _handleScroll = (event) => {
    const target = event.target;
    const scrollTop = target.scrollTop;
    const headlineHeight = document.querySelector(".headline").clientHeight;
    let percentage = scrollTop / headlineHeight;
    percentage = Math.max(0, Math.min(percentage, 1));
    let opacity =  Math.abs(-(1/6) * Math.log(percentage));
    opacity = Math.min(opacity, 1);
    setOpacity(opacity);
    if (percentage >= 0.98) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
    const params = router.params;
    const query = router?.location?.query || {};
    const _page = parseInt(params ? params.page : 1) || 1;
    const _isArticleView = router?.location?.pathname?.includes('article') && 'id' in query;
    if (!_isArticleView) {
      setLastPosition(scrollTop);
      setLastPage(_page);
    }
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  }, [page]);

  useEffect(() => {
    const frame = document.getElementById("frame");
    const handleScroll = debounce(_handleScroll, 2)
    frame.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const frame = document.getElementById("frame");
    if (lastPage !== page) {
      frame.scrollTop = 0;
      setLastPage(page);
      return;
    }
    if(!isArticleView) {
      setTimeout(() => {
        frame.scrollTop = lastPosition
      }, 800)
    } else {
      frame.scrollTop = 0;
    }
  }, [isArticleView, page]);

  return (
   <div className="newspaper frame" id="frame">
    <div className="newspaper header">
     <NewspaperHeadline opacity={opacity} page={page} />
     <NewspaperMidline
      router={router}
      page={page}
      fixed={isFixed}
      isArticleView ={isArticleView}
     />
     <Tabsline
      fixed={isFixed}
      page={page}
      router={router}
     />
    </div>
    <div className={`newspaper page ${isFixed ? 'fixed' : ''} ${isInIOSApp ? 'safari' : ''}`}>
      <div className={`loading-overlay ${isFixed ? 'fixed': ''} ${!loading? 'hidden' : ''}`}>
        <Loader style={{ height: '100vh', width: '100%' }}/>
      </div>
      {children}
    </div>
   </div>
  );
}

NewspaperFrame.contextTypes = {
  router: PropTypes.object,
  page: PropTypes.number,
}
