import { ENTRIES_LOADED } from '../constants';

export default function update(state = {byId:{}, all:[]}, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.newsArticle3) {
                let result = {byId:{}, all:[]}
                Object.keys(action.entries.newsArticle3).forEach(k => {
                    let it = action.entries.newsArticle3[k]
                    if (it && it.id) {
                        result.byId[it.id] = it
                        result.all.push(it)
                    }
                })
                return result
            }
            break;
    }
    return state;
}
