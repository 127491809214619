import React from "react";

import More from "../components/modal-more";

const renderPhotos = (photos, onClick) => {
    let thumbnails = photos.map(p => p.thumb);


    return <More assets={photos}
        onClickItem={onClick}
        thumbnails={thumbnails} />
}

const GalleryTable = (props) => {
    const {gallery, onViewPhoto} = props;
    if (!gallery || gallery.length === 0) {return null;}

    const selectPhoto = (photo) => {
        onViewPhoto(photo, gallery);
    }
    return (
        <div className="track-listing">
            <div className="gallery-wrapper">
                <div className="gallery-images">
                    {renderPhotos(gallery, selectPhoto)}
                </div>
            </div>
        </div>
    );
}

export default GalleryTable;