import { combineReducers }          from 'redux';
import albums                       from './albums';
import tracks                       from './tracks';
import artists                      from './artists';
import related                      from './related';
import videos                       from './videos';
import lyrics                       from './lyrics';
import films                        from './films';
import photos                        from './photos';
import more                         from './more';
import pages                        from './pages';
import albumArt                     from './albumArt';
import boxset                       from './boxset';
import book                         from './book';
import tourZO                       from './tourZO';
import tourZI                       from './tourZI';
import tour                         from './tour';
import requestable                  from './requestable';
import userRequest                  from './userRequest';
import venue                        from './venue';
import performance                  from './performance';
import userRequestParams            from './userRequestParams'
import timelineEvents               from './timelineEvents';
import timelineControllers          from './timelineControllers';
import status                       from './status';
import postIt                       from './postIt';
import newsPage                     from './newsPage';
import newsArticle                  from './newsArticle';
import contrarianPage               from './contrarianPage';
import newsPage2                    from './newsPage2';
import newsPage3                    from './newsPage3';
import newsPage4                    from './newsPage4';
import newsArticle2                 from './newsArticle2';
import newsArticle3                 from './newsArticle3';
import newsArticle4                 from './newsArticle4';
import leftSideIcons                from './leftSideIcons';
import rightSideIcons                from './rightSideIcons';
import movieNightFilm               from './movieNightFilm';
import movieNightCategory           from './MovieNightCategory';
import mp3tracks                    from './mp3Track';
import contrarianAd                 from './contrarianAd';
import subscribersCornerEntry       from './subscribersCornerEntry';
import playlists                    from './playlists';
import plans                        from './plan';
import topCharts                    from './topCharts'
import metas                        from './metas';
import access                       from './Access';
import ppvCategories                from './ppvCategories';
import ppvMovies                    from './ppvMovies';
import commonValues                 from './commonValues'
import userData                     from './user-information'
import toggle                       from './toggles'
import articleView                  from './articleview'
import search                       from './search'

export default combineReducers({
  metas,
  more,
  films,
  lyrics,
  videos,
  albums,
  plans,
  artists,
  tracks,
  playlists,
  related,
  pages,
  albumArt,
  boxset,
  book,
  tourZO,
  tourZI,
  tour,
  requestable,
  userRequest,
  venue,
  performance,
  userRequestParams,
  timelineEvents,
  timelineControllers,
  status,
  postIt,
  topCharts,
  newsPage,
  contrarianPage,
  newsArticle,
  newsPage2,
  newsPage3,
  newsPage4,
  newsArticle2,
  newsArticle3,
  newsArticle4,
  movieNightFilm,
  movieNightCategory,
  mp3tracks,
  leftSideIcons,
  rightSideIcons,
  contrarianAd,
  subscribersCornerEntry,
  commonValues,
  access,
  ppvCategories,
  ppvMovies,
  photos,
  userData,
  toggle,
  articleView,
  search
});
