import { LOAD_TRACKS, LOAD_FREE_TRACKS, PLAYING_TRACK, PLAYING_STATE } from '../constants';
import store from '../store'

export const loadTracks = () => {
    return {
        type: LOAD_TRACKS
    }
}

export const loadFreeTracks = (freeTracks) => {
    return store.dispatch({
        type: LOAD_FREE_TRACKS,
        freeTracks
    });
}


export const loadPlayingTrack = (playingTrack) => {
    return store.dispatch({
        type: PLAYING_TRACK,
        playingTrack
    });
}

export const playerState = (playing = false) => {
    return store.dispatch({
        type: PLAYING_STATE,
        playing
    });
}

