import MobileDetect from 'mobile-detect'

let md = new MobileDetect(navigator.userAgent)

export const isMobile = md.mobile()

export const isIPad = navigator.userAgent.search(/iPad/) > -1
export const isIPhone = navigator.userAgent.search(/iPhone/) > -1
export const isLowPerformance = !navigator.hardwareConcurrency || navigator.hardwareConcurrency <= 4

export const isInMobileApp = window.navigator ?
  window.navigator.userAgent.includes('nya-mobile') : false;

export const isInIOSApp = window.navigator ?
  window.navigator.userAgent.includes('nya-mobile-ios') : false;
