import React, {Component } from 'react'
import {updateMetadata}   from '../../actions/metas'
import {updatePpvData}    from '../../actions/ppv'
import { updateEntries } from "../../actions/entries";
import PPVCategories from './ppv-category'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import {getPpvData, hasAuth, getRentals} from '../../services/api';
import leftArrow from "../../../images/ppv/left-arrow.png"

class PPV_Page extends Component {

  constructor(props, ctx){
    super(props, ctx)

    this.state = {
      featuredVideos: [],
      categoryList: []
    }

    this.showModalForVideo = this.showModalForVideo.bind(this)
    this.updateCategoryList = this.updateCategoryList.bind(this)
  }
  componentDidMount() {
    if(hasAuth()){
      getRentals().then(rentals=>{
        updatePpvData(rentals.data)
      })
    }else{
      getPpvData().then(data => {
          updatePpvData(data)
      });
    } 
  }

  componentWillMount(){
    updateMetadata({ title: 'Movietone' })
  }

  showModalForVideo(video) 
  {
      const { orastreamVideoId, vimeoId} = video;
      let id = vimeoId || orastreamVideoId;
      let categoryTitle;
      if(orastreamVideoId && !vimeoId){
        const categoryId = video.category && video.category._id;

        const categoryData = this.props.categoryList.find(_category =>_category._id === categoryId);
        categoryTitle = categoryData.title.toLowerCase();
        video.category = categoryData;
        updateEntries({video})
      }

      const {router} = this.context

      let modalLink = `/movietone/${id}`
      if(categoryTitle)modalLink += `?category=${categoryTitle}`
      if(id.length > 9)modalLink += `&osVideo=true`
      if(id)router.push(router.createLocation(modalLink))
  }
  updateCategoryList(data){
      this.setState({categoryList:data})
  } 

  go(path) {
    let { router } = this.context;
    router.push(router.createLocation(path));
  }

  render(){
    const { featuredVideos, categoryList,userData } = this.props
    const query = new URLSearchParams(this.props.location.search)
    const categoryId = query.get("category");

    if(featuredVideos){
      return (
        <div className="movietone-library-main-container">        
          <div className="page-wrapper">  
            <div className="movietone-library-content">
              <div className="main-content article">                
                <div className="pay-per-view">    

                  <div 
                    className="return-to-hearse-theater-button"
                    onClick={() => (this.go("/movie-night/home"))}
                  >
                      <img className="return-to-hearse-theater-image" src={leftArrow}/>
                      <span className='return-to-hearse-theater-title'>Hearse Theater</span>
                  </div>

                  <PPVCategories
                    categoryVideosList={categoryList}
                    deeplink={categoryId}
                    userData={userData}
                    modelClickback={this.showModalForVideo}
                    updateCategoryList={this.updateCategoryList}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }else{
      return<div></div>
    } 
  }
}

PPV_Page.contextTypes = {
  router: PropTypes.object.isRequired
}

PPV_Page.propTypes = {
  featuredVideos: PropTypes.array,
  setFeaturedVideos: PropTypes.func,
  categoryList: PropTypes.array,
  setCategoryList: PropTypes.func,
}

const mapStateToProps = function (state){
  return { 
    featuredVideos: state.ppvCategories.featured, 
    categoryList: state.ppvCategories.ppvCategories,
  };
};

export default connect(mapStateToProps)(PPV_Page)