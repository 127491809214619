import _ from 'lodash'
import superagent from 'superagent';
import {getGeolocation} from './geolocation'

const endpoint = 'https://recordstoreday.com/VenueLocationSearch.json'

export const searchByCoordinates = () => {
  return new Promise((resolve, reject) => {
    getGeolocation()
      .then(coords => {
        if (!coords) {
          console.log('Unexpected invalid geolocation result')
          throw new Error('Invalid geolocation result')
        }
        const {latitude, longitude} = coords
        let storesPromise = getStores('coordinates', {latitude, longitude})
        return resolve(storesPromise)
      })
  })
}

export const searchByMyLocation = () => {
    return getStores('myLocation')
}

export const searchByQuery = query => {
    return getStores('query', query)
}

export const searchByZipCode = zip => {
  return getStores('zipcode', zip)
}

export const searchByCityQuery = city => {
  return getStores('city', city)
}

const parseStoresFromResponse = response => {
  // TODO: Confirm required fields
  const {venues = []} = response
  return venues.map(v => {
    // let {id, venue_id, name, zipcode, address, website_address, facebook, country, city} = v
    return _.pick(v, 'id', 'venue_id', 'name', 'zipcode', 'address', 'website_address', 'facebook', 'country', 'city', 'distance_in_miles')
  })
}

export const getStores = (searchType, searchParam) => {
  let query = {}
  if (searchType === 'query' || searchType === 'city' || searchType === 'zipcode') {
    query = {new_search: searchParam}
  } else if (searchType === 'coordinates') {
    const {latitude, longitude} = searchParam
    query = {latitude, longitude}
  }

  return new Promise((resolve, reject) => {
    superagent.get(endpoint)
      .query(query)
      .end((err, response) => {
        if (err) {
          return reject(err)
        }
        let {body} = response
        let stores = parseStoresFromResponse(body)
        return resolve(stores)
      })
  })
}