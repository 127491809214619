import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import Billboard from '../news/billboard';
import Ad from './ad';
import useDeviceType from '../util/useDeviceType';
import { scale } from '../scale';
import '../../scss/nyatc/nyatc_article.scss';

const Top40Tracks = (props) => {
 const { ads } = props;
 const {isMobile, isTablet} = useDeviceType()
  const top40TracksRef = useRef(null);

  const resizeContent = useCallback(() => {
   let element = top40TracksRef.current;
   const s = scale();
   if (element) {
    element.style.zoom = s;
   }
  }, []);

useEffect(() => {
 window.addEventListener('resize', resizeContent);
 resizeContent();

 return () => {
  window.removeEventListener('resize', resizeContent);
 };
}, [resizeContent]);

 return (
  <div ref={top40TracksRef} className="main-content article topchart">
    <div className="column article topchart">
     <Billboard key="top40" which="top40" showNote={true} />
    </div>
    <div className="column ads">
     {ads.map((item, index) => (
      <Ad
       link={item.link}
       image={`${item.image}?fm=jpg&fl=progressive&fit=thumb&w=332`}
       key={index}
      />
     ))}
    </div>
  </div>
 );
};
const mapStateToProps = (state) => {
 const parsedAds = state.contrarianAd.all
  .filter((item) => item.link !== undefined)
  .sort(() => 0.5 - Math.random());
 return {
  ads: parsedAds,
 };
};

export default connect(mapStateToProps, {})(Top40Tracks);
