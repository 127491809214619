import React, {Component, Fragment} from 'react'
import cn                 from 'classnames'
import PropTypes          from 'prop-types'
import VerticalCenter     from './vertical-center'
import {getFreeAlbumID}   from '../drawer-items'
import {isFreeUser,userHasSubscription}       from '../user'
import PlaylistManager    from '../components/new-player/playlist-manager'
import {
  setUserSettings, resetNewsletterPreference, hasAuth,
  getUserInfo, setEmailModal, setShowModal, linkingConfirmationEmail, createAuth0User,
  setFreePassModal, freePassToken, setReferralFreePass, addToTourMailingList
} from '../services/api';
import { Link }           from 'react-router'
import { getFreeSong }    from '../drawer-items'
import Login              from '../services/login';
import PasswordField      from '../util/password'
import Auth from '../services/auth'
import {hideTourMailingList} from "../services/api";

export default class WarningMessage extends Component {
    constructor(props, context){
        super(props, context)

        this.onAudioSetup     = this.onAudioSetup.bind(this)
        this.onSubscribe      = this.onSubscribe.bind(this)
        this.onFreeAlbum      = this.onFreeAlbum.bind(this)
        this.onFreeSong       = this.onFreeSong.bind(this)
        this.onStopPlayback   = this.onStopPlayback.bind(this)
        this.onLogin          = this.onLogin.bind(this)
        this.onResumePlayback = this.onResumePlayback.bind(this)
        this.onTimesContrarian      = this.onTimesContrarian.bind(this)
        this.handleNewsletterChange = this.handleNewsletterChange.bind(this);
        this.handleNewsletterSubmit = this.handleNewsletterSubmit.bind(this);
        this.handleFreePassSubmit   = this.handleFreePassSubmit.bind(this);
        this.handleFreePassRetry    = this.handleFreePassRetry.bind(this)
        this.freePassTimeParser     = this.freePassTimeParser.bind(this)
        this.goToSubscriptionAndScrollToTop = this.goToSubscriptionAndScrollToTop.bind(this);
        this.handleClose = this.handleClose.bind(this)
        this.handleLinking = this.handleLinking.bind(this)
        this.handleOptInMailingList = this.handleOptInMailingList.bind(this)

        this.state = {
            subscribed: false,
            showFreePopup: false,
            auth0User:{
              password:'',
              passwordConfirmation:''
            },
            inputError :'',
            duplicateSubscriptionData :[],
            email: this.props.data.email,
        }
    }

    onAudioSetup(){
        this.go('/audio-setup')
    }
    onSubscribe(){
        this.go('/account?screen=plans')
    }
    onFreeAlbum(){
      this.props.onClose()
      let {router} = this.context
      router.push(router.createLocation(`/album?id=${getFreeAlbumID()}`));
    }
    onFreeSong(){
        this.props.onClose()
        let {router} = this.context
        const freeSongs = getFreeSong()
        router.push(`/info-card?track=${freeSongs.id}`)
    }
    onTimesContrarian(){
        this.go('/news/1')
    }
    go(pathname){
        this.props.onClose()
        let {router} = this.context
        let location = router.createLocation(pathname)
        router.push(location)
    }
    onResumePlayback(){
        PlaylistManager.play()
        this.props.onClose()
    }
    onStopPlayback(){
        PlaylistManager.stopPlaying()
        this.props.onClose()
    }
    onLogin(){
      Login();
      this.props.onClose();
    }
    handleNewsletterChange(event) {
        this.setState({subscribed: event.target.value === "true"});
    }
    handlePasswordChange(event){
      let name = event.target.name;
      let value = event.target.value;

      this.setState(currentState => {
        const auth0User = {...currentState.auth0User, [name] : value};

        return {auth0User,inputError:''};
    });

    }
    handleNewsletterSubmit() {
        this.setState({newsletter: 'loading'})
        setUserSettings({
            type: 'cdc_newsletter',
            values: { subscribed: this.state.subscribed },
        }).then(() => {
            this.props.onClose();
        }).catch(() => {
            resetNewsletterPreference();
            this.props.onClose();
        });
    }

    handleCreateUserSubmit(userData){
      const {password, passwordConfirmation} = this.state.auth0User
      const {email, name,nickname } = userData
      const previousAuth0Id = userData.user_id
      let newUser = {
        email,
        name,
        username: nickname,
        password
      }
      if(password.length>1 &&
       password == passwordConfirmation){
        this.props.onClose();
        createAuth0User(newUser, previousAuth0Id).then((data)=>{
          let {linkModalType} = data.body
          if(linkModalType == 'linking-success'){
            linkModalType = 'account-success'
          }
            setShowModal(linkModalType)
            location.reload();

        }).catch(err=>{
          console.error("error creating new user try again", err)
          setShowModal({modalType:'account-error',showModal:true})
            location.reload();
        })
      }else{
        this.setState({inputError:'password does not match'})
      }
    }
    handleLinking(email,type){
      this.setState({newsletter: 'loading'})
       this.props.onClose();
      setEmailModal({email, type, connection:false, endpoint:`/api/account/link_user`}).then(()=>{
        location.reload();
      }).catch((err) => {
            console.error('there was an error linking the account', err)
            setShowModal({modalType:'linking-error',showModal:true})
        });
    }

    handleEmail(email,name){
      this.props.onClose();
      linkingConfirmationEmail(email, name).then(()=>{
          setShowModal({modalType:'logout-message',showModal:true})
          location.reload();
      }).catch((err)=>{
        console.error('email confirming linking could not be sent to the user')
      })
    }
    handleLogout(){
        setShowModal({modalType:null,showModal:false})
        this.props.onClose();
        Auth.logout('/account?screen=plans&view=select')
    }
    handleRedirect(route){
      let {router} = this.context

      this.props.onClose();
      setShowModal({modalType:null,showModal:false})
      router.push(router.createLocation(`${route}`));
    }
    handleClose(){
      setShowModal({modalType:null,showModal:false})
      this.props.onClose();
    }

    handleFreePassSubmit(modalType){
      setFreePassModal(modalType, false)
      this.props.onClose()
    }
    handleFreePassRetry(){

      setReferralFreePass().then((res)=>{
        if(res){
          setFreePassModal('pass-error', false)
          this.props.onClose()
          location.reload()
        }
      })
    }

    handleOptInMailingList() {
      addToTourMailingList(this.state.email)
        .then(console.log)
        .catch( e => console.log("%c Error addind email to mail list: ", "color:blue;", e))
      hideTourMailingList()
      this.props.onClose()
    }

    goToSubscriptionAndScrollToTop() {
        let {router} = this.context
        router.push('/account?screen=plans')
        document.querySelector('.account-info-page').scrollTop = 0
        this.props.onClose();
    }

    freePassTimeParser(){
      let validPass = freePassToken()
      let tokenExpiration;
      if(validPass !== null && validPass.validity){
        let tokenValidity = validPass.validity
        tokenExpiration =  tokenValidity.includes('h') ? parseInt(tokenValidity) > 1? `${parseInt(tokenValidity)} hrs` : `${parseInt(tokenValidity)} hr` : `${parseInt(tokenValidity)} min`
      }
      return tokenExpiration;
    }
    getContent(){
        let {which, data} = this.props
        let userData = getUserInfo()
        let name,email;

        if(userData.name){
          name = userData.name
          email = userData.email
        }

        if (which === 'audio') {
            return (
                <div className="content">If you experience any playback issues<br />
                  like skipping or excessive buffering,<br/>
                  please switch to <span className="link" onClick={()=>window.setAudioTo320()}>320</span> and visit the<br/>
                  <span className="link" onClick={this.onAudioSetup}>Audio Set Up</span> to learn how to improve<br />
                  your experience.
                </div>
            )
        } else if (which === 'missing-track') {
            return (
                <div className="content">Sorry.<br />
                  <br />
                  This song is not currently available.<br />
                  We are working on it.<br />
                  Please check back later.
                </div>
            )
        } else if (which === 'subscription') {
            return (
                <div className="content">NYA is currently free.<br />
                  <br />
                  Please enjoy and we will let<br />
                  you know when subscriptions are<br />
                  available for purchase.
                </div>
            )
        }else if (which === 'login') {
          return (
              <div className="content">
                <p>You must be logged in to rent this <br/> title—please log in or create a free account.</p>
                <div className="buttons">
                  <div className="buttons-left login">
                      <div className="button blue big subscribe" onClick={this.onLogin}><div className="text">Continue</div></div>
                  </div>
                </div>
              </div>
          )
        } else if (which === 'sign-in') {
          return (
              <div className="content">
                <p>You must be logged in to perform this <br/> action. Please log in or create an account.</p>
                <div className="buttons">
                  <div className="buttons-left login">
                      <div className="button blue big subscribe" onClick={this.onLogin}><div className="text">Continue</div></div>
                  </div>
                </div>
              </div>
          )
        } else if (which === 'subscribe') {
            return (
                <div className="content">
                  Subscribers only beyond this point.
                  <div className="buttons">
                    <div className="buttons-left">
                      {!!hasAuth() &&
                        <div className="button blue big subscribe" onClick={this.onSubscribe}><div className="text">SUBSCRIBE</div></div>
                      }
                      {!hasAuth() &&
                        <Fragment>
                          <div className="button subscribe" onClick={this.onSubscribe}><div className="text">SUBSCRIBE</div></div>
                        <div className="button blue log-in" onClick={this.onLogin}><div className="text">LOG IN</div></div>
                        </Fragment>
                      }
                    </div>
                    <div className="button big explore" onClick={() => {this.setState({showFreePopup: true})}}><div className="text">LEARN WHAT'S FREE</div></div>

                      { this.state.showFreePopup &&
                        <div className='freeItems'>
                            <div className="button not-now" onClick={this.onTimesContrarian}><div className="text">Read the NYA Times-Contrarian</div></div>
                            <div className="button not-now" onClick={this.onFreeSong}><div className="text">Listen to the Song Of The Day</div></div>
                            <div className="button not-now" onClick={this.onFreeAlbum}><div className="text">Listen to our Featured Album</div></div>
                            <div className="button not-now" onClick={this.props.onClose}><div className="text">Continue to browse NYA</div></div>
                            <div className={"close"} onClick={() => {this.setState({showFreePopup: false})}} />
                        </div>
                      }
                  </div>
                </div>
            )
        } else if (which === 'unlimitedOnly' || which === 'unlimitedOnlyV2') {
          const isV2 = which === 'unlimitedOnlyV2';
          return (
              <div className="content">
                {
                  isV2  ? <>You need a higher tier beyond this point</>
                        : <>
                            Rust & Patron Subscribers only
                            <br/>
                            beyond this point.
                          </>
                }
                <div className="buttons">
                  <div className="buttons-left">
                      <Fragment>
                        <div className={`button subscribe ${isV2 && 'blue'}`} onClick={this.onSubscribe}><div className="text">UPGRADE</div></div>
                        {!hasAuth()&&<div className="button blue log-in" onClick={this.onLogin}><div className="text">LOG IN</div></div>}
                      </Fragment>
                  </div>
                  {(!hasAuth() || !userHasSubscription() )&& <div className="button big explore" onClick={() => {this.setState({showFreePopup: true})}}><div className="text">LEARN WHAT'S FREE</div></div>}

                    { this.state.showFreePopup &&
                      <div className='freeItems'>
                          <div className="button not-now" onClick={this.onTimesContrarian}><div className="text">Read the NYA Times-Contrarian</div></div>
                          <div className="button not-now" onClick={this.onFreeSong}><div className="text">Listen to the Song Of The Day</div></div>
                          <div className="button not-now" onClick={this.onFreeAlbum}><div className="text">Listen to our Featured Album</div></div>
                          <div className="button not-now" onClick={this.onSubscribe}><div className="text">Continue to browse NYA</div></div>
                          <div className={"close"} onClick={() => {this.setState({showFreePopup: false})}} />
                      </div>
                    }
                </div>
              </div>
          )
      }else if (which === 'subscribeTicketMonthly') {
            return (
                <div className="content">
                    <div className="text">
                        <br/>
                        <label>Pre-Sale tickets are exclusive to NYA yearly subscribers.</label>
                    </div>
                  <div className="buttons">
                    {!!hasAuth() &&
                      <Fragment>
                        <div className="button not-now" onClick={this.props.onClose}><div className="text">NOT NOW</div></div>
                        <label className="button blue subscribe" to='/account?screen=plans' onClick={this.goToSubscriptionAndScrollToTop}><div className="text">SUBSCRIBE</div></label>
                      </Fragment>
                    }
                    {!hasAuth() &&
                      <Fragment>
                        <div className="button" to='/account?screen=plans' onClick={this.goToSubscriptionAndScrollToTop}><div className="text">SUBSCRIBE</div></div>
                        <label className="button blue" onClick={Login}><div className="text">LOG IN</div></label>
                      </Fragment>
                    }
                  </div>
                </div>
            )
        }else if (which === 'free-pass') {
          return (
              <div className="content">
                  <span className="message"></span>
                  <span className="message">Welcome friends! <br/>I hope you enjoy your visit here to my archive. It’s the home of all my music, movies and books. We made it for all of us.</span>
                  <span className="message">Equal time for Past Present and Future!</span>
                  <span className="message">
                    We built this in a way that we wanted. It may not be what you expect, but we invite you to explore all the nooks and crannies if you have time.
                    If not, come back later! It will still be here. A lot of love goes into this archive from all of us, from our Times-Contrarian newspaper to all the High Resolution Music I have made.
                    More is being added all the time, new and old. It’s alive! You deserve it! I made this for you and I to enjoy together. Please do. NYA is the best we have to offer in the digital realm.
                  </span>
                  <span className="message expiration">ny</span>
                  <div className="button" onClick={()=>{this.handleFreePassSubmit('free-pass')}}><div className="text">Continue</div></div>
              </div>
          )
    } else if (which === 'pass-error') {
        return (
            <div className="content">
                <span className="message">Please try again!</span>
                <br/>
                <br/>
                <span className="message">There was an error creating your free pass that grants you access to the unlimited features of the site.</span>
                  <div className="buttons">
                    <label className="button blue" onClick={this.handleFreePassRetry}><div className="text">Retry</div></label>
                  </div>
            </div>
        )
    }else if (which === 'ticketsUnavailable') {
            return (
                <div className="content">
                    <div className="text">
                        <span>The NYA pre-sale code system is undergoing some maintenance.  Please check back later.  Sorry for any inconvenience.</span>
                    </div>
                </div>
            )
        } else if (which === 'appleSub') {
            return (
                <div className="content">
                    <div className="text">
                        <span>You must cancel your subscription through your Apple iTunes account before your NYA account can be deleted.</span>
                    </div>
                </div>
            )
        } else if (which === 'playback-interruption') {
            const free = isFreeUser(),
                  paid = !free
            return (
                <div className={cn('content', {free, paid})}>
                  <span className="message">Do you want to keep listening?</span>
                  <div className="buttons">
                    <div className="button" onClick={this.onStopPlayback}><span className="text">NO</span></div>
                    <div className="button blue" onClick={this.onResumePlayback}><span className="text">YES</span></div>
                  </div>
                  {free && (
                       <div className="subscribe">
                         <span className="link" onClick={this.onSubscribe}>Subscribe</span> and reduce the frequency<br/>of this message.</div>)}
                </div>
            )
        } else if (which === 'newsletter') {
            return (
                <div className="content">
                    <span className="message">Would you like to receive newsletters from NYA?</span>
                    <form className="form-container">
                        <div className="option">
                            <label>
                                <input type="radio" value={true} checked={this.state.subscribed} onChange={this.handleNewsletterChange}/>
                                <span className="check" />
                                YES
                            </label>
                        </div>
                        <div className="option">
                            <label>
                                <input type="radio" value={false} checked={!this.state.subscribed} onChange={this.handleNewsletterChange} />
                                <span className="check" />
                                NO
                            </label>
                        </div>
                    </form>
                    <span className="message">If you change your mind, you can manage this preference in your account settings.</span>
                    <div className="button" onClick={this.handleNewsletterSubmit}><div className="text">SUBMIT</div></div>
                </div>
            )
        } else if (which === 'email-account') {
          return (
              <div className="content">
                  <span className="message">Hello NYA member!</span>
                  <span className="message">We see that you have been using a Facebook or Google account to access NYA. Starting today we will no longer be supporting the use of Facebook or Google for login and we will be transitioning your account to a simple email and password login.  All you have to do is create a password below.</span>
                    <span className="message">
                    The email you will use for all future login is:
                    </span>
                    <span className="message">
                      <span className="email"> {email} </span>
                    </span>
                    <span className="message">
                    Please create a password:
                    </span>
                    <PasswordField
                        name="password"
                        placeholder="Enter password"
                        value={this.state.auth0User.password}
                        handlePasswordChange={(e)=>this.handlePasswordChange(e)}
                    />

                    <span className="message">
                    Confirm New Password:
                    </span>
                    <PasswordField
                        name="passwordConfirmation"
                        placeholder="Confirm password"
                        value={this.state.auth0User.passwordConfirmation}
                        handlePasswordChange={(e)=>this.handlePasswordChange(e)}
                    />
                    <span className="error">{this.state.inputError}</span>
                  <span className="message">
                  Thank you!
                  </span>
                  <div className="button" onClick={(()=>this.handleCreateUserSubmit(userData))}><div className="text">Continue</div></div>
              </div>
          )
      }else if (which === 'duplicate-account') {
        return (
            <div className="content">
                <span className="message">Hello NYA member!</span>
                <span className="message">Starting today we will no longer be supporting the use of Facebook or Google for login and we will be transitioning all accounts to use a simple email and password login. </span>
                <span className="message">We see that you already have an account that uses email and password for login.  When you click continue we will go ahead and migrate your NYA subscription to that account.  From now on please use your email
                  <span className="email"> {email} </span><br/>
                  and password for login.
                </span>
                  <span className="message">
                  If you need to reset your password  you can do so from the login page.
                  <br/><br/>
                  Thank you!
                  </span>
                <div className="button" onClick={(()=>this.handleLinking(email,'linking'))}><div className="text">Link</div></div>
            </div>
        )
        }else if (which === 'linking-success') {
          return (
              <div className="content">
                    <span className="message">
                      Success! Goodbye Facebook & Google!
                      You are now all set to login with an email and password.
                      <br/> <br/>
                      Your account now uses the below email for login:
                      <span className="email"> {email} </span>
                      <br/><br/>
                      We have also sent you an email confirmation.
                    </span>
                    <span className="message thanks">
                      Thanks for your continued support of NYA and enjoy!
                   </span>
                  <div className="button" onClick={(()=>this.handleEmail(email,name))}><div className="text">Continue</div></div>
              </div>
          )
      }else if (which === 'logout-message') {
        return (
            <div className="content">
                  <span className="message">
                    Just one more step, click Continue to complete the process and log in again using your email
                    <span className="email"> {email} </span><br/>
                  </span>
                <div className="button" onClick={(()=>this.handleLogout())}><div className="text">Continue</div></div>
            </div>
        )
      }else if (which === 'duplicate-subscription') {
        return (
            <div className="content">
                  <span className="message">
                  Hello!
                  <br/><br/>
                  I am afraid there is an issue with your account.
                  Please contact an NYA team member from this
                  <span onClick={(()=>this.handleRedirect('/contact'))}> page </span>
                  and we will get the issue resolved ASAP.
                  </span>
                <div className="button" onClick={(()=>this.handleRedirect('/contact'))}><div className="text">Continue</div></div>
            </div>
        )
      }else if (which === 'account-error') {
        return (
            <div className="content">
                  <span className="message">
                  We couldn't create your account at the moment, we will try again when you come back to the app.
                  </span>
                <div className="button" onClick={(()=>this.handleClose())}><div className="text">Continue</div></div>
            </div>
        )
      }else if (which === 'search-error') {
        return (
            <div className="content">
                  <span className="message">
                  We couldn't obtain your email to create a new account, please try again later.
                  </span>
                  {/* handle and close modal  */}
                <div className="button" onClick={(()=>this.handleClose())}><div className="text">Continue</div></div>
            </div>
        )
     }else if (which === 'linking-error') {
        return (
            <div className="content">
                  <span className="message">
                  We couldn't link the new account with your existing subscription, we will try again when you come back to the app.
                  </span>
                  {/* handle and close modal  */}
                <div className="button" onClick={(()=>this.handleLinking(email,'linking'))}><div className="text">Continue</div></div>
            </div>
        )
     }else if (which === 'login-message') {
        return (
            <div className="content">
                  <span className="message">
                  Hello!
                  <br/><br/>
                  We no longer support Facebook or Google login. You have already completed the email transition for this account, please login with your email
                  <span className="email"> {email} </span><br/>
                  and password.
                  </span>
                <div className="button" onClick={(()=>this.handleLogout())}><div className="text">Continue</div></div>
            </div>
        )
    }else if (which === 'account-success') {
      return (
        <div className="content">
              <span className="message">
              Your transition to your new unique account was completed successfully.
              <br/> <br/>
              Your Account is: {email}
              <br/> <br/>
              We have also sent you a confirmation email. If you are curious to understand our reasons for the change you can read all about why <span onClick={(()=>this.handleEmail(email,name))}>here</span> in the NYA Times-Contrarian.
              <br/> <br/>
              Thanks for your continued support of NYA and enjoy!
              </span>
            <div className="button"  onClick={(()=>this.handleEmail(email,name))}><div className="text">Continue</div></div>
        </div>
      )
    }else if (which === "xstream") {
              return (
                <div className="content">
                  <div className='XStreamIcon' />
                  <div className="content-right">
                    <div>
                      <label className="paragraph">You are now leaving NYA and entering the Xstream by NYA Store powered by Orastream.</label>
                      <label className="paragraph">You will need to create or log in to your Orastream account to make a purchase.</label>
                      <label className="paragraph">NYA subscribers get High Res Audio at Low Res prices - Individual tracks (minimum 5) & Albums.</label>
                    </div>
                    <div className="buttons-wrapper">
                      <button className="button button--back" onClick={this.props.onClose}>GO BACK</button>
                      <button className="button button--continue" onClick={() => {
                        window.open(this.props.link)
                        this.props.onClose()
                      }}>CONTINUE</button>
                    </div>
                  </div>
                </div>
              )
        }else if (which === "freeDownload") {
              return (
                <div className="content">
                  <div className='XStreamIcon' />
                  <div className="content-right">
                    <div>
                      <label className="paragraph">You are now leaving NYA and entering the Xstream by NYA Store powered by Orastream.</label>
                      <label className="paragraph">NYA Subscribers, create or log in to your Orastream account to redeem your free download.</label>
                      <label className="paragraph">Thanks for being part of NYA!</label>
                    </div>
                    <div className="buttons-wrapper">
                      <button className="button button--back" onClick={this.props.onClose}>GO BACK</button>
                      <button className="button button--continue" onClick={() => {
                        window.open(this.props.link)
                        this.props.onClose()
                      }}>CONTINUE</button>
                    </div>
                  </div>
                </div>
              )
        } else if (which === "handStore") {
              return (
                <div className="content">
                  <div>
                    <label className="paragraph">You are now leaving NYA and entering the Greedy Hand Store.</label>
                    <label className="paragraph">This store is managed by our partners at Warner Brothers Records.</label>
                  </div>
                  <div className="buttons-wrapper">
                    <button className="button button--back" onClick={this.props.onClose}>GO BACK</button>
                    <button className="button button--continue" onClick={() => {
                      window.open(this.props.link)
                      this.props.onClose()
                    }}
                    >CONTINUE</button>
                  </div>
                </div>
              )
        } else if (which === "subscriptions-purchase-disabled") {
          return (
            <div className="content">
              Temporarily unavailable
              <div className="buttons">
                <div className="button blue big explore" onClick={() => {this.props.onClose()}}><div className="text">OK</div></div>
              </div>
            </div>
          )
        } else if (which === "tour-mailing-list") {
          return (
            <div className="content">
              <span className="message">
                Would you like to be added to our mailing list to receive NYA news?
              </span>
              <div className="styled-input">
                <div className="label">Email</div>
                <input
                  type="text"
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({...this.state, email: e.target.value})
                  }}
                  className="email-input"
                  placeholder="Email"/>
              </div>
              <div className="buttons">
                <Fragment>
                  <button className="button" onClick={() => {
                    hideTourMailingList()
                    this.props.onClose()
                  }}>
                    <div className="text">NO, THANKS</div>
                  </button>
                  <button className="button blue login" onClick={this.handleOptInMailingList}>
                    <div className="text">CONFIRM</div>
                  </button>
                </Fragment>
              </div>
              <div className="links">
                <a href="https://privacy.wmg.com/warnerrecords/privacy-policy" target="_blank">
                  Warner Records Privacy Policy
                </a>
                <a href="https://www.warnerrecords.com/terms-of-use">
                  Terms of Use | Warner Records
                </a>
              </div>
            </div>
          )
        } else if (which === 'free-trial') {
          return (
            <div className="content">
              <p>
                You are not eligible for the One Month Free Trial as it is available exclusively for new
                users.
              </p>
              <div className="buttons">
                <div className="button blue big subscribe" onClick={this.props.onClose}>
                  <div className="text">CONTINUE</div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="content"></div>
          )
        }
    }

  closeButtonRender() {
    const {isLeavingWarning, which} = this.props
    if (which == 'pass-error') {
      return (<div className={cn("close", {"close--leaving": isLeavingWarning})}
                   onClick={(() => this.handleFreePassSubmit('pass-error'))} />)
      }else{
        return(<div className={cn("close", {"close--leaving": isLeavingWarning})} onClick={this.props.onClose} />)
      }
    }
    render(){
        const { isLeavingWarning, which } = this.props
        const {newsletter} = this.state

        const withoutTableStyling = which === "handStore" || which === "freeDownload" || which === "xstream"
        let cx = cn('warning-message', which, {'warning-message--leaving': isLeavingWarning, ['newsletter--loading']: newsletter === 'loading'})
        return (
          // first option should be this.props.onClose
            <VerticalCenter className="warning-page" onBGClick={which !== 'newsletter' || which !== 'free-pass'|| which !== 'email-account' || which !== 'duplicate-account' || which !== 'linking-success' || which !== 'login-message' || which !== 'logout-message'? null : null} withoutTableStyling={withoutTableStyling}>
              <div className={cx}>
                {this.closeButtonRender()}
                {this.getContent()}
              </div>
            </VerticalCenter>
        )
    }
}
WarningMessage.contextTypes = {
    router: PropTypes.object.isRequired
}
