import { LOAD_ENTRIES, ENTRIES_LOADED, LOAD_SUBSCRIBER_ENTRIES, LOAD_LEFT_SIDE_ICONS, LOAD_RIGHT_SIDE_ICONS, LOAD_POST_IT, DRAWER_LOADED } from '../constants';
import store from '../store';
export const loadEntries = () => {
    return {
        type: LOAD_ENTRIES
    }
}

export const loadSubscriberEntries = () => {
    return {
        type: LOAD_SUBSCRIBER_ENTRIES
    }
}

export const loadHomeData = (data)=> {
    store.dispatch({
        type: LOAD_LEFT_SIDE_ICONS,
        data: data.leftSideIcons || {}
    });
    store.dispatch({
        type: LOAD_RIGHT_SIDE_ICONS,
        data: data.rightSideIcons || {}
    });
    store.dispatch({
        type: LOAD_POST_IT,
        data: data.postIt || {}
    });
}
export const updateEntries = data => {
    store.dispatch({
        type: ENTRIES_LOADED,
        entries: data
    });
};
export const changeDrawerStatus = () => {
    store.dispatch({
        type: DRAWER_LOADED
    });
}