let centralColumn = 1040
let breakPoint = 1920

export function scale(){
    let width = window.innerWidth
    let scale
    if (width < breakPoint) {
        scale = width / breakPoint
    }
    else {
        scale = 1
    }
    return scale
}

export function inverseScale(){
    return 1/scale()
}

export function breakPointWidth(){
    return breakPoint
}

export function centralColumnWidth(){
    return centralColumn
}

let tl_scale = 1
export function setTimelineScale(s) {
    tl_scale = s
}

export function timelineScale(){
    return tl_scale
}
