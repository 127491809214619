import React, { Component } from 'react'
import marked from 'marked'
import cn from 'classnames'
import PropTypes from 'prop-types'
import AdColumn, {articleNewOnNYAId} from './ad-column'
import Excerpt from '../newspaper-iframe/excerpt'
import interpose from '../util/interpose'
import {parseOnlyNumbers} from '../util/strings'
import { updateMetadata } from '../actions/metas'
import { updateEntries } from '../actions/entries'
import {getPageData} from '../services/api';
import { connect }         from 'react-redux';
import SplashScreen from '../splash-screen'
import {updateArticleView, updateArticle} from '../actions/articleView'
import {getArticleById}   from '../services/api'


const layoutColumns = {
    'column-3': ['left', 'center', 'right'],
    'column-4': ['left', 'center', 'right', 'fourth', 'fifth'],
    'column-4-full': ['fifth'],
    'column-4-full-inner': ['left', 'center', 'right', 'fourth'],
    'column-3-full': ['left', 'center', 'right'],
    'column-3-full-inner': ['fourth', 'fifth', 'sixth'],
    'column-3-equal': ['left', 'center', 'right', 'fourth'],
}


const hiddenExcerptsByPage = {
    '15': {
        [articleNewOnNYAId]: true
    }
}

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: {
                layout: null
            },
            articles: {},
            loaded: false,
            numberOfArticles: 5,
            isArticle:true,
            article:{},
            articleLoaded: false


        }
        this.newsInterval = null;
        this._linkToArticle = this._linkToArticle.bind(this)
    }
    componentWillUnmount() {
        clearInterval(this.newsInterval);
    }
    componentWillMount(){
        updateMetadata({ title: 'NYA Times Contrarian' })
    }
    componentDidUpdate(prevProps) {
        const {isArticleView} = this.props

        if((window.location.search.length === 0) && isArticleView){

            updateArticleView(false)
            updateArticle({})
        }
    }
    componentDidMount() {
        const {page} = this.props.params;
        clearInterval(this.newsInterval);
        getPageData(page).then(pageData => {
            updateEntries({articles: pageData});
            this.setState({
                articlesByColumn: pageData,
            }, () => {
                /**
                 * TODO
                 * Clear Interval when all columns are completly filled
                 */
                clearInterval(this.newsInterval);
                this.newsInterval = setInterval(() => {
                    this.setState({
                        numberOfArticles: this.state.numberOfArticles + 5,
                        loaded: true,
                    });
                }, 2000)
            });
        });
    }

    _getArticledata(articleId){
        const { page } = this.props.params

        window.history.pushState({}, null, `/news/${page}/article?id=${articleId}`);

        getArticleById(articleId).then(data => {
            updateArticle(data)
        })
    }

    _linkToArticle (articleId){
        const { updateArticleView} = this.props
        updateArticleView(true)
        this._getArticledata(articleId);
    }

    _renderExcerpts(side, page, layout) {
        const {articlesByColumn} = this.state; //getArticleData()['page-' + page]
        let data = articlesByColumn ? articlesByColumn[side] : null
        const titleData = this.props.pageData.find(p => parseOnlyNumbers(p.title) === parseInt(page));
        const pageStyle    = titleData && titleData.styleName ? titleData.styleName : 'front-page'
        const dataToRender =  data && data.slice(0, this.state.numberOfArticles);

        const inHiddenArticles = hiddenExcerptsByPage[page.toString()] || {};
        const articles = dataToRender
          ? dataToRender.map((data, idx) => (
              !inHiddenArticles[data.id] ? <Excerpt
                key={`excerpt-${page}-${side}-${idx}`}
                data={data}
                idx={idx}
                page={page}
                layout={layout}
                pageStyle={pageStyle}
                link={this._linkToArticle}
              /> : null
            ))
          : [];
        return interpose(articles, idx=><div className="divider" key={`divider-${idx}`} />)
    }

    ColumnsToNum(column) {          //TODO: Dynamic pages: remove
        const columns = {
            left: 0,
            center: 1,
            right: 2,
            fourth: 3,
        }
        return columns[column];
    }

    _renderColumnTitle(side, page) {
        const pageData = this.props.pageData.find(p => parseOnlyNumbers(p.title) === parseInt(page)) || {};
        const pageStyle    = pageData.styleName ? pageData.styleName : 'front-page'
        const headline = pageData[`${side}Headline`]
        if (!headline) return null
        const DividersWidths = pageData.dividersWidths ? pageData.dividersWidths.split(',').map(w => parseInt(w)) : null
        let DividerWidth = DividersWidths ? DividersWidths[this.ColumnsToNum(side)] : 150
        DividerWidth = DividerWidth === 0 ? 150 : DividerWidth

        const columnTitleClasses = cn('column-title', side, pageStyle )                                                 //TODO: Dynamic pages: refactor
        return (
            <div>
                <div className={columnTitleClasses} ref="content" dangerouslySetInnerHTML={{__html:marked(headline)}} />
                {page!==5 || page!== 6 && <div style={{width: `${DividerWidth}px`}} className="divider" />}
            </div>
        )
    }

    _renderColumn(side, page, layout) {
        const columnCn = cn('column', side, layout)
        const innerColumns3 = ( layout === 'column-3-full' && side === 'center' ) ? layoutColumns['column-3-full-inner'] : null
        const innerColumns4 = ( layout === 'column-4-full' && side === 'fifth' ) ? layoutColumns['column-4-full-inner'] : null

        return (
            <div key={`column-${side}-${layout}`} className={columnCn}>
                { this._renderColumnTitle(side, page) }
                { this._renderExcerpts(side, page, layout)}
                { (layout === 'column-3-full-inner' && side !== 'sixth') && this._renderColDivs('inner-column') }
                { (layout === 'column-4-full-inner' && side !== 'fourth') && this._renderColDivs('inner-column') }
                { innerColumns3 && innerColumns3.map(side => this._renderColumn(side, page, 'column-3-full-inner')) }
                { innerColumns4 && innerColumns4.map(side => this._renderColumn(side, page, 'column-4-full-inner')) }
            </div>
        )
    }

    _renderColDivs(layout) {
        switch(layout) {
            case 'column-3':
                return (
                    <div>
                        <div className="center-line" />
                        <div style={{ left: 3 * 347 + 20}} className="center-line" />
                        <div style={{ left: 4 * 347 + 30}} className="center-line" />
                    </div>
                )
            case 'column-4':
                return (
                    <div>
                        <div style={{ left: 1 * 336 + 10}} className="center-line" />
                        <div style={{ left: 2 * 336 + 30}} className="center-line" />
                        <div style={{ left: 3 * 336 + 50}} className="center-line" />
                        <div style={{ left: 4 * 336 + 60}} className="center-line" />
                    </div>
                )
            case 'column-3-full':
                return (
                    <div>
                        <div className="center-line" />
                        <div style={{ left: 1413}} className="center-line" />
                    </div>
                )
            case 'column-4-full':
                return null
            case 'inner-column':
                return (
                    <div className="center-line inner-column" />
                )
            case 'column-3-equal':
            return (
                <div>
                    <div style={{ left: 1 * 425 + 10}} className="center-line" />
                    <div style={{ left: 2 * 425 + 30}} className="center-line" />
                    <div style={{ left: 3 * 425 + 50}} className="center-line" />
                    <div style={{ left: 4 * 425 + 60}} />
                </div>
            )
            default:
                return (
                    <div>
                        <div className="center-line" />
                        <div style={{ left: 3 * 347 + 20}} className="center-line" />
                        <div style={{ left: 4 * 347 + 30}} className="center-line" />
                    </div>
                )
        }
    }

    render() {
        if (!this.state.loaded) return <div className="news-loading"><SplashScreen style={{height: '100vh', width: '100%'}} loadState={100} /></div>;
        const { page } = this.props.params
        const pageData = this.props.pageData.find(p => parseOnlyNumbers(p.title)=== parseInt(page)) || {};
        const { layout = 'column-3'} = pageData;
        const columns = layoutColumns[layout] || []
        const parsedColumns = columns.map(side => this._renderColumn(side, page, layout))
        const notUseAdColumn = [
            'column-3-full',
            'column-4-full',
            'column-4',
            'column-3-equal'
        ];
        return (
            <div className= "main-content">
                <div className="excerpt-wrapper">
                    { parsedColumns }
                    { this._renderColDivs(layout) }
                </div>
                {(notUseAdColumn.indexOf(layout) === -1) && <AdColumn numberOfArticles={this.state.numberOfArticles} page={page} />}
            </div>
        )
    }
}
News.contextTypes = {
    router: PropTypes.object.isRequired
}


const mapStateToProps = function (state) {
    return {
        pageData: state.contrarianPage.all,
        isArticleView: state.articleView.show
    };
  };

export default connect(mapStateToProps,{updateArticleView, updateArticle})(News);
