import React, {Component} from 'react';
import cn                 from 'classnames'

export default class Hanger extends Component {
    render(){
        let {showSides, folderClass} = this.props
        let cx = cn({[folderClass]: true, hanger:true})
        return (
            <div className={cx}>
              <div className="top"></div>
              {showSides?<div className="left"></div>:null}
              {showSides?<div className="right"></div>:null}
            </div>
        )
    }
}
