import {allItems} from './stubs/gen-items'
/* have tested 10,000 items without noticable slowdown in drawer-2, drawer-3 */
// let items = genItems(10000)
let items = allItems
let index = {}
let byYear = {}
items.forEach(item => {
    index[item.id] = item
    let year = item.releaseDate.year
    if(!byYear[year]) byYear[year] = []
    byYear[year].push(item)
})
let archive = {items, index, byYear}
export default archive
