import React, {Component} from 'react'
import PropTypes from 'prop-types'
import GlobalMenu from './global-menu-icon'


export default class BasicPage extends Component {
    constructor(props, ctx){
        super(props, ctx)

        this.onClose = this.onClose.bind(this)
    }
    onClose(){
        this.context.router.goBack()
    }
    render(){
        let className         = "basic-page",
            extra             = this.props.className,
            {backgroundImage} = this.props

        className = extra ? `${className} ${extra}` : className

        let style
        if (backgroundImage){
            style = {backgroundImage: `url('${backgroundImage}')`}
        } else {
            style = {}
        }

        return (
            <div className={className} style={style}>
              {this.props.children}
              <GlobalMenu />
              <div className="close-button" onClick={this.onClose} />
            </div>
        )
    }
}

BasicPage.contextTypes = {
    router: PropTypes.object.isRequired
}
