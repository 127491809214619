import React, {Component} from 'react';

export default class Credits extends Component {
    render(){
        let {credits, isFilm} = this.props

        let className = isFilm ? "film-credits" :"album-credits"

        return (
            <div className={className}>
              {
                  credits.map(({title, credits}, idx)=>(
                      <div className="section" key={idx}>
                        <div className="header">{title}</div>
                        <div className="content">{credits}</div>
                      </div>
                  ))
              }
            </div>
        )
    }
}
