export function titleParser(str){
    return str.split(" ").join('').replace(/^.{1}/g, str[0].toLowerCase())
}

export function parseOnlyLetters(str){
    return typeof(str) === 'string' ? str.replace(/[^a-zA-Z]/g,"").toLowerCase() : str
}
export function parseOnlyNumbers(str){
    let number = typeof(str) === 'string' ? parseInt(str.replace(/\D/g, "")) : str
    if(Number.isInteger(number)) return number
}
export function parseUrlStr(str){
    return str.split('-').join('').toLowerCase().split('/')
}

export function replaceStreamingUrl (url, link){

    return  url.replace('https://nya.orastream.com', link) 

}

export function removeString (str,character){
    return str.split(character).pop()
}