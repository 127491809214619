import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { getThumbnail} from '../../services/vimeo'
import {getBrioVideoInformation} from  '../../services/brio'
import VimeoPlayer from '../../components/vimeo-player';
import { updateSelectedIndex } from '../../actions/ppv';
import {updatePpvMovie, updatePurchaseState}    from '../../actions/ppv';
import { connect } from 'react-redux';
import {hasAuth, getRentedPpvMovie} from "../../services/api";
import {canViewExclusiveContent} from '../../user';
import {parseUrlStr, titleParser} from '../../util/strings';
import moment from "moment";
import  parse from 'html-react-parser';
import movietoneLogo from "../../../images/movie-night/Movietone_Logo.png"

const PPVCategories = ({
  categoryVideosList,
  deeplink,
  selectedIndex,
  modelClickback,
  purchasedStatusUpdated,
  moviePurchased,
}) => {
  const sortOptions = [
    { name: "alphabetical", value: "A - Z", key: 0 },
    { name: "inverseAlphabetical", value: "Z - A", key: 1 },
    { name: "time", value: "DATE", key: 2 },
  ];

  //get prev category from localstorage
  // const prevCategory = localStorage.getItem('movietone-category');

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const [sortOpen, setSortOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(sortOptions[2]);
  const [thumbnailUrls, setThumbnailUrls] = useState({});
  const [videoPlaying, setVideoPlaying] = useState(null);
  const [renderVideoList, setRenderVideoList] = useState([]);
  const [onBottom, setOnBottom] = useState(false);
  const [sortVideosAgain, setSortVideosAgain] = useState(false);

  useEffect(() => {
    if (categoryVideosList.length > 0) {
      let defaultIndex = selectedIndex ? selectedIndex : 2;

      //If selecte index is null, and there is a deeplink, it's the first time on page
      if (deeplink && selectedIndex === null) {
        defaultIndex = getIndexForCategoryId(deeplink);
      }
      //Verify index is within array bounds
      if (defaultIndex >= categoryVideosList.length) {
        defaultIndex = 0;
      }

      // if(prevCategory){
      //   defaultIndex = prevCategory
        //remove prev selected category from local storage
        // localStorage.removeItem('movietone-category');
      // }
      
      //Check if the selected cat has videos, if not choose another
      while (
        !categoryVideosList[defaultIndex].videos ||
        categoryVideosList[defaultIndex].videos.length === 0
      ) {
        defaultIndex += 1;
      }
      
      const index = activeIndex ? activeIndex : defaultIndex;

      setActiveIndex(index);
      //here on get from local storage
      setSelectedCategory(categoryVideosList[index]);
      setSelectedSort(sortOptions[2]);
      if (selectedSort) initialVideoSort(selectedSort);
      addMoreVideosToRenderList(categoryVideosList[index]);
      //Set listener for retrieving more videos when bottom is reached
      const wrapper = document.querySelector(".page-wrapper");
      wrapper.addEventListener("scroll", onScreenScrolled);
      return () => {
        wrapper.removeEventListener("scroll", onScreenScrolled);
      };
    }
  }, [categoryVideosList]);

  useEffect(() => {
    if (purchasedStatusUpdated) {
        addMoreVideosToRenderList(selectedCategory);
    }
  }, [purchasedStatusUpdated]);

  useEffect(() => {
    if (onBottom) {
      setOnBottom(false);
      addMoreVideosToRenderList(selectedCategory);
    }
  }, [onBottom]);

  useEffect(() => {
    if (sortVideosAgain) {
      setSortVideosAgain(false);
      sortRenderedVideos(selectedSort);
    }
  }, [sortVideosAgain]);

  const onScreenScrolled = (e) => {
    const target = e.target;
    const bottom = (target.scrollHeight - target.scrollTop >= target.clientHeight);
    if (bottom) {
      setOnBottom(true);
    }
  };

  const includesRental = (category)=>{
    const title = category && category.toLowerCase();
    return title && title.includes('rental');
  }

  const isRentalCategory = () => {
    return selectedCategory && includesRental(selectedCategory.title)
  }

  const updateVideoStatus = () => {
    let newRenderVideoList = [...renderVideoList];
    let purchaseMovieId = (moviePurchased && moviePurchased.vimeoId) || false;
    renderVideoList.forEach((_video, index) => {
      if (purchaseMovieId === _video.vimeoId) {

        if (isRentalCategory() && !(moviePurchased && moviePurchased.rented)) {
          //remove item from rentals
          newRenderVideoList.splice(index, 1);
        } else {
          newRenderVideoList[index] = moviePurchased;
          //keep previous url and skip other request
          newRenderVideoList[index].thumbnailUrl = _video.thumbnailUrl;
        }
      }
      
    });
    return newRenderVideoList;
  };

  const addMoreVideosToRenderList = (selectedCategory, reset = false) => {
    if (selectedCategory && selectedCategory.videos) {
      let newVideos = selectedCategory.videos;
      if (reset) {
        const sortName =
          (selectedSort && selectedSort.name) || "time";
        const sortedVideos = sortVideos(newVideos, sortName);
        newVideos = sortedVideos.slice(0, 9);
        setRenderVideoList(newVideos);
        getThumbnailUrl(newVideos);
      } else {
        if (renderVideoList.length < selectedCategory.videos.length) {
          let newRenderVideoList = purchasedStatusUpdated ? updateVideoStatus() : renderVideoList;
          
          newVideos = newVideos.slice( newRenderVideoList.length, newRenderVideoList.length + 9);
          newRenderVideoList = newRenderVideoList.concat(newVideos);
          // newRenderVideoList filter duplicated videos by _id
          newRenderVideoList = newRenderVideoList.filter((item, index, self) => self.findIndex(i => i._id === item._id) === index);

          setRenderVideoList(newRenderVideoList);
          setSortVideosAgain(true);
          getThumbnailUrl(newVideos);
        }
        //Purchase updated  set new render set of videos
        if (purchasedStatusUpdated) {

          const newRenderVideoList = updateVideoStatus();

          setRenderVideoList(newRenderVideoList);
          setSortVideosAgain(true);
          updatePurchaseState({state: !purchasedStatusUpdated,moviePurchased: [],});

          //Set a different category in case rental it's empty
          if (isRentalCategory() && selectedCategory.videos.length == 0) {
            const categoryIndex = categoryVideosList.findIndex(_cat=>!(_cat && includesRental(_cat.title)))
            selectCategory(categoryVideosList[categoryIndex], categoryIndex);

          }
        }
      }
    }
  };

  const getIndexForCategoryId = (id) => {
    let query = parseUrlStr(id) || false;
    query = query && query[0];

    if (categoryVideosList) {
      for (let i = 0; i < categoryVideosList.length; i++) {
        const category      = categoryVideosList[i];
        const {title = "" , _id =""} = category
        const categoryTitle = titleParser(title || "").replace("-","").toLowerCase();
        const categoryId    = titleParser(_id || "").toLowerCase();

        if ((categoryId === query || categoryTitle === query) && category.videos.length > 0) {
          //Expected index starts from 1 on the places it's used elsewhere
          return i;
        }
      }

      //If none is found, default to first
      return 0;
    }
  };

  const selectCategory = (newCategory, index) => {
      //send the cat obj and index
    updateSelectedIndex(index);
    setSelectedCategory(newCategory);
    setActiveIndex(index);
    addMoreVideosToRenderList(newCategory, true);
    setSortVideosAgain(true);
  };

  const clickedPlayVideo = (video, openModal) => {
    //Open login modal for non logged in users 
    const { rented = false, price = false, availableForRental = false } = video;
    if (!hasAuth()){
      modelClickback(video);
      //set the category on localstorage
      // localStorage.setItem('movietone-category', selectedIndex)
      return;
    } 

    if (rented) {
      getRentedPpvMovie(video.vimeoId).then((data) => {
        const {
          data: { video },
        } = data;
        if (video && video.rented !== rented) {
          //update video in categories
          modelClickback(video);
          updatePpvMovie({ data: video });
          updatePurchaseState({ state: true, purchasedMovie: video });
        } else {
          if(availableForRental && openModal){
            modelClickback(video);
            return;
          }
          setPlayVideo(video);
        }
      });
      //open modal if it's not rented
    } else if (canViewExclusiveContent() && !(price && availableForRental)) {
      //open modal when cliked on item info
      if( openModal){
        modelClickback(video);
        return;
      }
      setPlayVideo(video);
    } else {
      modelClickback(video);
    }
  };

  const setPlayVideo = (video) => {
    video.isPlaying = true;
    setVideoPlaying(video);
  };

  const clickedSortOpenToggle = () => {
    setSortOpen(!sortOpen);
  };

  const clickedSort = (sortOption) => {
    setSelectedSort(sortOptions[sortOption.key]);
    setSortVideosAgain(true);
    setSortOpen(false);
  };

  const initialVideoSort = (sortOption) => {
    categoryVideosList.map((category) => {
      const sortName = (sortOption && sortOption.name) || "time";
      const sortedVideos = sortVideos(category.videos, sortName);
      category.videos = sortedVideos;
    });
  };

  const sortRenderedVideos = (sortOption) => {
    if (sortOption) {
      const sortedRenderVideos = sortVideos(renderVideoList, sortOption.name);
      setRenderVideoList(sortedRenderVideos);
    }
  };

  const sortVideos = (videos, sort = "time") => {
    if (!videos) {
      return videos;
    }

    switch (sort) {
      case "alphabetical":
        return videos.sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });

      case "inverseAlphabetical":
        return videos.sort((a, b) => {
          if (a.title > b.title) {
            return -1;
          }
          if (a.title < b.title) {
            return 1;
          }
          return 0;
        });

      case "time":
        return videos.sort((a, b) => {
          const firstValue = new Date(a.creationDate);
          const secondValue = new Date(b.creationDate);
          if (firstValue > secondValue) {
            return 1;
          }
          if (firstValue < secondValue) {
            return -1;
          }
          return 0;
        });

      default:
        return videos;
    }
  };

  const getThumbnailUrl = (videosArray) => {
    videosArray &&
      videosArray.map((video) => {
        const orastreamVideoId = video.orastreamVideoId
           
        if(orastreamVideoId && !thumbnailUrls[orastreamVideoId]){
          getBrioVideoInformation(orastreamVideoId).then(result => {
            const {cover, html} = result
            video.thumbnailUrl = cover;
            video.iframe = html;
            updatedThumbnails = { ...updatedThumbnails, [orastreamVideoId]: cover };
            setThumbnailUrls(updatedThumbnails);
        })
        }else if (video && video.vimeoId && !thumbnailUrls[video.vimeoId]) {
          const vimeoId = video.vimeoId;
          getThumbnail(vimeoId).then((result) => {
            video.thumbnailUrl = result;
            let updatedThumbnails = thumbnailUrls;
            updatedThumbnails = { ...updatedThumbnails, [vimeoId]: result };
            setThumbnailUrls(updatedThumbnails);
          });
        }
      });
  };

  const setSortButtonDefault = () => {
    let defaultValue = sortOptions[2].value 
    setSelectedSort(sortOptions[2])
    return defaultValue
  }


  const setSortButton = (sort) => {
    
    let sortValueString = sort && sort.value ? sort.value : setSortButtonDefault()
    return (
      <div className="sort-button" onClick={clickedSortOpenToggle}>
        {sortValueString}
        <div className="arrow-down" />
      </div>
    )
  }

  return (
    <div className="ppv-category-container">
      <div className="divider" />
      <div className="category-titles">
      <div className='movietone-library-category-container'>      
        <img className='movietone-library-logo' src={movietoneLogo} />        
      </div>

        {categoryVideosList.map((category, index) => {
          const className = activeIndex == index ? "category-tv.active" : "category-tv";

          if (category.videos && category.videos.length > 0) {
            return (
              <img
                key={category._id}
                zindex={index}
                className={className}
                src={category.image}
                onClick={() => selectCategory(category, index)}
              />
            );
          }
        })}
      </div>
      <div className="sort-container">
        {setSortButton(selectedSort) }

        {sortOpen && (
          <div className="sorter-card">
            {sortOptions.map((option) => {
              const className =
                selectedSort.key === option.key
                  ? "sort-option active"
                  : "sort-option";
              return (
                <div
                  key={option.key}
                  className={className}
                  onClick={() => clickedSort(option)}
                >
                  {option.value}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="divider" />
      <div className="item-container">
        {renderVideoList &&
          renderVideoList.map((video, index) => {
            const {
              title,
              subtitle,
              price,
              availableForRental,
              runningTime,
              producer,
              expirationDate,
              rented,
              director,
            } = video;
            const vimeoId = video ? parseInt(video.vimeoId) : 0;
            const isOrastreamVideo = video && video.orastreamVideoId != undefined;

            let expirationText = "";
            if (expirationDate) {
              const date = moment.unix(expirationDate);
              expirationText = date.format("h[:]mma, MMMM Do, YYYY");
            }
            return (
              <div key={video._id} className="item">
                <div className="video">
                  {video && videoPlaying && videoPlaying._id === video._id ? (
                    (isOrastreamVideo && video.iframe? 
                      parse(video.iframe)
                      :
                      <VimeoPlayer
                        autoPlay={true}
                        focus={true}
                        videoId={vimeoId}
                      />
                    )
                  ) : (
                    <div className="preview-container">
                      {video.thumbnailUrl && <img src={video.thumbnailUrl} />}
                      <div
                        className="play-icon"
                        onClick={() => clickedPlayVideo(video)}
                      />
                    </div>
                  )}
                </div>
                <div className="item-info" onClick={() => clickedPlayVideo(video,true)}>
                  <div>
                    <div className="title">{title}</div>
                    <div className="subtitle">{director}</div>
                    <div className="subtitle">{subtitle}</div>
                    {producer && (
                      <div className="running-time">
                        {producer}
                      </div>
                    )}
                    {runningTime && (
                      <div className="running-time">
                        Running time: {runningTime}
                      </div>
                    )}
                    {rented && expirationDate && (
                      <div className="expiration">
                        Expires at: {expirationText}
                      </div>
                    )}
                  </div>
                  {availableForRental && price && (
                    <div className={`item-tag ${rented ? 'rented': 'price'}`}>
                      {rented ? "RENTED" : "$" + price}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

PPVCategories.propTypes = {
  categoryVideosList: PropTypes.array.isRequired,
  deeplink: PropTypes.string,
  selectedIndex: PropTypes.number,
  modelClickback: PropTypes.func,
};

const mapStateToProps = (state) => ({
  selectedIndex: state.ppvCategories.selectedIndex,
  categoryList: state.ppvCategories.ppvCategories,
  purchasedStatusUpdated: state.ppvCategories.purchasedStatusUpdated,
  moviePurchased: state.ppvCategories.moviePurchased,
});

export default connect(mapStateToProps, { updateSelectedIndex })(PPVCategories);
