import {
    ENTRIES_LOADED, LOAD_ENTRIES, LOAD_SUBSCRIBER_ENTRIES, FAILED_TO_LOAD_ENTRIES,
} from '../constants';

import { takeEvery } from 'redux-saga';
import { call, put, fork } from 'redux-saga/effects';
import { getInitial, getAll, fetchPlaylists } from './api';
import { loadEntries, loadSubscriberEntries } from '../actions/entries';

function* initialEntryLoader() {
    try {
        const entries = yield call(getInitial);
        yield put({type: ENTRIES_LOADED, entries})
    } catch(e) {
        console.error('Failed to load initial entries %s', e.message);
        yield put({type: FAILED_TO_LOAD_ENTRIES, message: e.message});
    }
}

function* exclusiveEntryLoader() {
    try {
        const entries = yield call(getAll);
        const playlists = yield call(fetchPlaylists);
        yield put({type: ENTRIES_LOADED, entries, playlists})
    } catch(e) {
        console.error('Failed to load exclusive entries %s', e.message);
        yield put({type: FAILED_TO_LOAD_ENTRIES, message: e.message});
    }
}

export function* initial() {
    yield put(loadEntries());
}

export function* exclusive() {
    yield put(loadSubscriberEntries());
}

export function* loadInitialEntries()  {
    yield takeEvery(LOAD_ENTRIES, initialEntryLoader);
}

export function* loadExclusiveEntries()  {
    yield takeEvery(LOAD_SUBSCRIBER_ENTRIES, exclusiveEntryLoader);
}

export function* initialSaga() {
    yield [
        fork(loadInitialEntries),
        fork(initial)
    ]
}

export function* exclusiveSaga() {
    yield [
        fork(loadExclusiveEntries),
        fork(exclusive)
    ]
}
