export function pop_random(array){
    let idx = Math.floor(Math.random() * array.length)
    let result = array[idx]
    array.splice(idx, 1)
    return result
}

export function random_int(min, max){
    return Math.floor(Math.random() * (max - min)) + min
}

export function chance(n){
    return Math.floor(Math.random() * n) === 0
}

export function pick(array){
    return array[Math.floor(Math.random() * array.length)]
}

export function pick_not(array, exclude){
    let chosen = pick(array)
    while (chosen === exclude) {
        chosen = pick(array)
    }
    return chosen
}

//let ordered = false
export function stream_from(array, ordered) {
    if (ordered) {
        let idx = -1
        return ()=> {
            idx++
            if (idx === array.length) idx = 0
            return array[idx]
        }
    } else {
        if (array.length === 1) {
            return ()=>array[0]
        }
        let choice = pick(array)
        return ()=>{
            choice = pick_not(array, choice)
            return choice
        }
    }
}
