// Please delete this function when done
import React, {Fragment, useEffect, useRef, useState} from "react";
import Excerpt from "./excerpt";
import {getArticleById} from "../services/api";
import {updateArticle, updateArticleView} from "../actions/articleView";
import EventBusPublisher from "./event-bus-publisher";

const INITIAL_NUMBER_OF_ARTICLES = 7;

function isDuplicated(id, setOfElements) {
  if (setOfElements.has(id)) {
    return true
  } else {
    setOfElements.add(id);
    return false;
  }
}

export const NewspaperColumn = (props) => {
  const {column, layout, articles, page, router, contrarianPage } = props
  const [numberOfArticles, setNumberOfArticles] = useState(INITIAL_NUMBER_OF_ARTICLES)
  const articleIds = new Set();

  const increaseArticleNumber = () => {
    setNumberOfArticles(numberOfArticles => Math.min(numberOfArticles + 3, Math.max(articles.length, INITIAL_NUMBER_OF_ARTICLES)));
  }

  useEffect(() => {
    setNumberOfArticles(INITIAL_NUMBER_OF_ARTICLES);
  }, [page]);

  useEffect(() => {
    const currentExcerpt = document.querySelector(`.excerpt:nth-child(${numberOfArticles - 3})`);
    if (!currentExcerpt) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        increaseArticleNumber();
      }
    }, { threshold: 0.1 });

    observer.observe(currentExcerpt);

    return () => observer.disconnect(); // Limpia el observer al desmontar el componente
  }, [numberOfArticles, articles]);

  const _getArticleData = async (articleId) => {
    const url = `article?id=${articleId}`
    EventBusPublisher.changePage(`${page}/`+url);
    const response = await getArticleById(articleId);
    updateArticle(response);
    router.push(`/times-contrarian/${page}/${url}`);
  };


  const _linkToArticle = (articleId) => {
    updateArticleView(true)
    _getArticleData(articleId).then(data => {});
  }

  const renderHeader = () =>{
    switch (column) {
     case 'left':
      return (
       <Excerpt
        key="left-header"
        data={{ headlineText: contrarianPage.leftHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     case 'center':
      return (
       <Excerpt
        key="center-header"
        data={{ headlineText: contrarianPage.centerHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     case 'right':
      return (
       <Excerpt
        key="right-header"
        data={{ headlineText: contrarianPage.rightHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     case 'fourth':
      return (
       <Excerpt
        key="fourth-header"
        data={{ headlineText: contrarianPage.fourthHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     case 'fifth':
      return (
       <Excerpt
        key="fifth-header"
        data={{ headlineText: contrarianPage.fifthHeadline }}
        page={page}
        layout={layout}
        pageStyle="front-page"
       />
      );
     default:
      console.warn(`Unhandled column: ${column}`);
      return null;
    }

  }

  return (
    <div className={`column ${column} ${layout}`}>
    {contrarianPage && renderHeader()}
      {articles.slice(0, numberOfArticles).map((article, i) => {
        let hidden = isDuplicated(article._id, articleIds);
        if (hidden) {
          return null;
        }
        return <Excerpt
          key={`excerpt-${layout}-${column}-${i}`}
          data={article}
          layout={layout}
          page={page}
          link={_linkToArticle}
          pageStyle={'front-page'}
        />
      })}
    </div>
  )
}

export default NewspaperColumn;
