import React, {Component} from 'react'
import AdColumn           from './ad-column'
import Billboard          from './billboard'
import {updateMetadata}   from '../actions/metas'

export default class Top40Tracks extends Component {
    componentWillMount(){
      updateMetadata({ title: 'Top 40 Tracks' })
    }
    render(){
        return (
          <div className="main-content article">
            <div className="column article topchart">
              <Billboard which="top40" showNote = {true} />
            </div>
            <AdColumn showLinks />
          </div>
        )
    }
}
