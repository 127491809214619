import React, {useRef, useEffect}    from 'react';
import Vimeo                            from '@vimeo/player'
import Audio                            from './new-player/audio';
import {updateVideoStatus}              from '../actions/movie';
import {VIDEO_PLAYING, VIDEO_PAUSED}    from '../constants';

const VimeoPlayer = (props) => {
    const playerRef = useRef(null);
    const iframeRef = useRef(null);


    const audioCallback = (state) => {
        if (playerRef.current && state.state === 'LIVEPLAY') {
            playerRef.current.pause();
            Audio.removeCallback(audioCallback);
        }
    }

    const onPlay = () => {
        Audio.pause();
        props.onPlay && props.onPlay();
        Audio.addCallback(audioCallback);
        updateVideoStatus(props.videoId, VIDEO_PLAYING);
    }
    const onPause = () => {
        props.onPause && props.onPause();
        updateVideoStatus(props.videoId, VIDEO_PAUSED);
    }
    const playPause = async () => {
        const isPaused = await playerRef.current.getPaused();
        if (playerRef.current) {
            if (isPaused) {
                playerRef.current.play();
            } else {
                playerRef.current.pause();
            }
        }
    }
    const onKeyPress = (e) => {
        if (e.keyCode === 32) {
            e.preventDefault();
            playPause();
        }
    }
    const onLoaded = (player, iframe) => {
        playerRef.current = player;
        if (props.focus || props.autoPlay) {
            iframe.allow = "autoplay";
            if (props.focus) {
                iframe.focus();
                document.addEventListener('keypress', onKeyPress);
            }
            props.autoPlay && player.play();
        }
        updateVideoStatus(props.videoId, VIDEO_PAUSED);
    }

    const isPlaying = () => {
        return !playerRef.current.getPaused();
    }

    // componentDidMount
    useEffect(() => {
        const {onEnded} = props;

        const player = new Vimeo(iframeRef.current);

        player.on('loaded', () => onLoaded(player, iframeRef.current));
        player.on('play', onPlay);
        player.on('pause', onPause);
        if (onEnded) {
            player.on('ended', onEnded);
        }
        return () =>  {
            player.off('loaded');
            player.off('play');
            player.off('pause');
            player.off('ended');
            document.removeEventListener('keypress', onKeyPress);
        }
    }, [])

    const src = `https://player.vimeo.com/video/${props.videoId}?autoplay=${props.autoPlay?1:0}&controls=${props.hideControls?0:1}"`;
    const { supportFS, width, height} = props
    return (
        <iframe src={src} ref={iframeRef} allowFullScreen={supportFS} width={width} height={height} frameBorder='0'/>
    );
}


VimeoPlayer.defaultProps = {
    width: '100%',
    height: '100%',
    supportFS: true,
}


export default VimeoPlayer;