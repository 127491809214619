import { assign, get } from 'lodash'
import {
    SET_METADATA, UPDATE_METADATA, RESET_METADATA
} from '../constants'

const initialState = {
    title: 'Neil Young Archives',
    meta: [
        { property: 'og:url', content: 'https://neilyoungarchives.com/' },
        { property: 'og:title', content: 'Neil Young Archives' },
        { property: 'og:image', content: 'https://neilyoungarchives.com/assets/img/neil-young-archives.jpg' },
        { property: 'og:description', name: 'description', content: 'NYA contains the complete archives of Neil Young. The site is designed for a chronological exploration of artist output including music, books, films, & videos. Music is streamed in high-res with Xstream by NYA.  A living document, NYA is always being updated with new information, content and news.' }
    ]
}

const parse = metas => {
    const { script } = metas

    if (script) {
        metas.script = metas.script.map(content => {
            return {
                type: "application/ld+json",
                innerHTML: JSON.stringify(content)
            }
        })
    }

    return metas
}

export default function update(state = initialState, action) {
    switch (action.type) {
        case SET_METADATA:
            return parse(get(action, 'metas', {}))
        case UPDATE_METADATA:
            return parse(assign({}, initialState, get(action, 'metas', {})))
        case RESET_METADATA:
            return initialState
    }
    return state
}
