import { ENTRIES_LOADED, ALBUMS_LOADED } from '../constants';
import {makeInitialState, index} from './utils'

const initialState = makeInitialState()

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.album) return index(action.entries.album, state);
            break;
        case ALBUMS_LOADED:
            if(action.albums) return index(action.albums, state);
            break;
    }
    return state;
}
