import moment from 'moment'
//import config                from '../config.json';
import superagent from 'superagent';
import fake from '../fake-subscription';
import Cookies from 'js-cookie'

const endpoint = process.env.MANAGEMENT_URL

const unauthenticatedUserInfo = JSON.stringify({
  clientID: "3b2A21B5d9a5Z18dB48f9c9bE4750d98",
  created_at: "2019-03-06T13:18:14.209Z",
  identities: [],
  locale: "en",
  update_at: "2019-03-06T13:18:14.209Z",
  user_id: "3b2A21B5d9a5Z18dB48f9c9bE4750d98",
  user_metadata: {
    verification_email_sent: true,
    firstLogin: false
  }
})

export const getAuth = () => localStorage.getItem('ade:id-token')
export const setAuth = token => localStorage.setItem('ade:id-token', token)
export const setLinkingState = token => localStorage.setItem('ade:linking-state', token)
export const setEmail = email => localStorage.setItem('ade:user-email', email)
export const hasAuth = () => !!getAuth()

export const hasSearchedQuery = () =>  localStorage.getItem('searchedQuery') || false;
export const hasFreePass = (modalType) => localStorage.getItem(`ade:${modalType}`)
export const setFreePassModal = (modalType,display) => localStorage.setItem(`ade:${modalType}`, display)
export const getOrastreamToken = () => localStorage.getItem('ade:orastream-token')
export const setOrastreamToken = token => localStorage.setItem('ade:orastream-token', token)
export const getAccessToken = () => localStorage.getItem('ade:temp-token')
export const setAccessToken = token => localStorage.setItem('ade:temp-token', token)
export const getOrastreamUuid  = () => localStorage.getItem('ade:uuid')
export const setOrastreamUuid  = uuid  => localStorage.setItem('ade:uuid', uuid)

export const getTicketCode = (id) => localStorage.getItem(`ade:ticketCode-${id}`)
export const setTicketCode = (id, code) => localStorage.setItem(`ade:ticketCode-${id}`, code)

export const getUserEmail =() => localStorage.getItem('ade:user-email')


export const paymentProcessReloadDelay = 3000
export const isPaying = () => localStorage.getItem('_isPaying') === 'yes'
export const setIsPaying = () => localStorage.setItem('_isPaying', 'yes')
export const resetPaymentProcess = () => localStorage.removeItem('_isPaying')
export const reloadPaymentProcess = (delay = paymentProcessReloadDelay, reset = false) => {
  reset && resetPaymentProcess()
  setTimeout(() => document.location.reload(), delay)
}

export const reloadPaymentProcessThenGoHome = (delay = paymentProcessReloadDelay, reset = false) => {
  reset && resetPaymentProcess()
  setTimeout(() => location.href="/", delay)
}

export const getUserInfo = () => {
  const userInfo = localStorage.getItem('ade:user-info') || unauthenticatedUserInfo
  try {
    return JSON.parse(userInfo)
  } catch (err) {
    return {}
  }
}

export const setShowModal = (modal) => {
  let modalData = JSON.stringify( {modalType:null,showModal:false })
  try {
    modalData = JSON.stringify(modal)
  } catch (err) {
    modalData
  }
   localStorage.setItem('ade:modal-data', modalData)
}

export const getShowModal =() =>{
  const modalData =  localStorage.getItem('ade:modal-data') ||JSON.stringify( {modalType:null,showModal:false })
  try {
    return JSON.parse(modalData)
  } catch (err) {
    return {}
  }

}

export const freePassToken = () => {
const token = localStorage.getItem('ade:user-content')
  try {
    return JSON.parse(token)
  } catch (err) {
    return token
  }
}
export const setFreePassToken = (token) => {
  let content = JSON.stringify( {content:null, validity:''})
  try {
    content = JSON.stringify(token)
  } catch (err) {
    content
  }
  localStorage.setItem('ade:user-content', content)
}
export const setUserInfo = info => {
  let userInfo;
  try {
    userInfo = JSON.stringify(info)
  } catch (err) {
    userInfo = {}
  }
  localStorage.setItem('ade:user-info', userInfo)
}
export const hasSeenLeavingMessage = (which) => {
  let seen = !!localStorage.getItem(`ade:${which}`)
  localStorage.setItem(`ade:${which}`, true)
  return seen
}

export const hasSeenWelcomeMessage = () => {
  let seen = !!localStorage.getItem('ade:welcome-message-seen')
  localStorage.setItem('ade:welcome-message-seen', true)
  return seen
}

export const hasSeenNewsletterPreference = () => {
  let seen = !!localStorage.getItem('ade:newsletter-pref-seen')
  localStorage.setItem('ade:newsletter-pref-seen', true)
  return seen
}

export const shouldHideTourMailingList = function () {
  let shouldHide  = !!localStorage.getItem('ade:should-display-mailing-list-modal')
  return shouldHide;
}

export const hideTourMailingList = function () {
  localStorage.setItem('ade:should-display-mailing-list-modal', false)
  let userInfo = localStorage.getItem('ade:user-info')
  if (!userInfo)
    return
  userInfo = JSON.parse(userInfo)
  userInfo['is_in_tour_mailing_list'] = true;
  localStorage.setItem('ade:user-info', JSON.stringify(userInfo))
}

export const addToTourMailingList = (email) => {
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/emails/tour-mailing-list')
      .set('Authorization', 'Bearer ' + getAuth())
      .send({ email })
      .end((err, res) => {
        if (err)
          return typeof(reject) === 'function' ? reject(err) : null;
        resolve(res.body)
      })

  })
}

export const hasEmailDuplicate = (modalType) => {
  let seen = !!localStorage.getItem(`ade:${modalType}`)
  localStorage.setItem(`ade:${modalType}`, true)
  return seen
}
export const resetNewsletterPreference = () => {
  let seen = localStorage.removeItem('ade:newsletter-pref-seen');
  return seen
}


export const setEmailModal = (userInfo) =>{
        const {email, type, endpoint, connection } = userInfo
        setEmail(email)
        return EmailDuplicate(email, type, endpoint).then(res=>{
            let {linkModalType} = res.body
            if(linkModalType){
              if(linkModalType.modalType == 'login-message'){
                if(connection){
                  setShowModal(linkModalType)
                }else{
                  setShowModal({modalType:null,showModal:false})
                }
              }else{setShowModal(linkModalType)}
            }
            return res.body
        }).catch(err=>{
          console.log("error set modal", err)
          setShowModal({modalType:'search-error',showModal:true })
        })
}

export const setReferralFreePass = () => {
  if(freePassToken()== null && Cookies.get('userContent') ==undefined){
    return getFreePassToken().then((res)=>{
          const {content, validity} = res
          let date = new Date();
          res.time = date;

          setFreePassModal('free-pass', true)
          setFreePassToken(res)

          date.setHours(date.getHours() + parseInt(validity))
          Cookies.set('userContent', content , { expires: date, path: '/' })
          return true
      }).catch((error)=>{
          setFreePassModal('pass-error', true)
          return false

      })
    }
}

export const generateUuid = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(`https://${process.env.ORASTREAMCONNECTURL}/device/uuid`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  })
}

export const query = (opts = {}) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/query?search=' + opts.query)
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  })
}

let queryCache = {}
export const queryCached = (opts = {}) => {
  let key = opts.query
  if (queryCache[key]) return Promise.resolve(queryCache[key]);
  let result = query(opts)
  result.then(items=>queryCache[key]=items)
  return result
}


export const createJWT = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/search/token')
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  })
}

export const getInitial = (opts = {limit:500}) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/initial')
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  })
}
export const getFreePassToken = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/userContent')
              .end((err, res)=>{
                if (err)return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(res.body)
              })
  })
}

export const verifyFreePassTokenExpiration = () => {
  let token = freePassToken() !== null  ? freePassToken().content : ''

      return new Promise((resolve, reject)=>{
        superagent.get(endpoint + '/api/userContentPoll')
                  .set('Authorization', token)
                  .end((err, res)=>{
                    if (err){
                      return typeof(reject) === 'function' ? reject(err) : null;
                    }else{
                      return resolve(res.body)
                    }
                  })
        })


}
export const getAll = (opts = {limit:500}) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/all')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  })
}

export const getStreamingToken = () => {
  return new Promise((resolve, reject) => {
    try {
      superagent.get(endpoint + '/api/streaming-token')
        .end((err, res) => {
          if (err) {
            return typeof(reject) === 'function' ? reject(err) : null;
          } else{
            resolve(JSON.parse(res.text));
          }
        })
    } catch (e) {
      console.log(e);
    }
  })
}

export const getSubscriptionStatusAndStreamingToken = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/subscriptions')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  })
}

export const fetchPlanData = () =>{
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/subscriptions/plan-prices')
    .end((err, res) => {
      if (err) return typeof(reject) === 'function' ? reject(err) : null;
      else resolve(JSON.parse(res.text))
    })
  })
}

export const getUserSettings = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/account/settings')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  });
}

export const setUserSettings = (settings) => {
  return new Promise((resolve, reject) => {
    superagent.put(endpoint + '/api/account/settings')
              .set('Authorization', 'Bearer ' + getAuth())
              .send(settings)
              .end((err/*, res*/)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve("ok")
              })
  });
}

export const sendEmail = body => {
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/emails')
              .set('Authorization', 'Bearer ' + getAuth())
              .send(body)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve("ok")
              })
  })
}

export const deleteAccount = () => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.delete(endpoint + '/api/account')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve("ok")
              })
  })
}


export const getGiftCode = (code) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/subscriptions/gift/${code}`)
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err,res)=>{
                if (err) {
                  const {body :{ codeError  = false} = {} } = res
                  return  codeError ? reject(res.body) : typeof(reject) === 'function' ? reject(err) :  null;
                }else resolve(JSON.parse(res.text));
              })
  })
}

export const giftAccount = ({ token, firstname, email, date, note,plan, price }) => {
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/subscriptions/gift')
              .set('Authorization', 'Bearer ' + getAuth())
              .send({
                form: {
                  recipientName: firstname,
                  recipientEmail: email,
                  deliveryDate: date,
                  message: note,
                  plan,
                  price
                },
                source: token
              })
              .end((err/*, res*/)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve("ok")
              })
  })
}

export const redeemCode = (code) => {
  return new Promise((resolve, reject)=>{
    superagent.put(endpoint + `/api/subscriptions/gift/${code}`)
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err/*, res*/)=>{
                resetPaymentProcess()
                if (err) return typeof(reject) === 'function' ? reject(err) : null
                reloadPaymentProcess()
                resolve("ok")
              })
  })
}
export const redeemUpgradeCode = (code) => {
  return new Promise((resolve, reject)=>{
    superagent.put(endpoint + `/api/subscriptions/gift/redemption/${code}`)
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err/*, res*/)=>{
                resetPaymentProcess()
                if (err) return typeof(reject) === 'function' ? reject(err) : null
                reloadPaymentProcess()
                resolve("ok")
              })
  })
}

const fakePaymentInformation = {last4:"1234", brand:"Visa", expMonth:"00", expYear:"0000"};

export const fetchPaymentInformation = () => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(()=>res(fakePaymentInformation), 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/subscriptions/card')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) {
                  if (err && err.status === 404) { //TODO: Temporal fix, api shouldn't send a 404 for an empty card scenario
                    resolve(JSON.parse(res.text));
                  }
                  return typeof(reject) === 'function' ? reject(err) : null;
                }
                else resolve(JSON.parse(res.text))
              })
  })
}

export const updatePaymentInformation = ({id}) => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.put(endpoint + '/api/subscriptions/card')
              .set('Authorization', 'Bearer ' + getAuth())
              .send({source:id})
              .end((err/*, res*/)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve("ok")
              })
  })
}

export const cancelPlan = () => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.delete(endpoint + '/api/subscriptions')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err/*, res*/)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve("ok")
              })
  })
}

export const createOrUpdateVideo = ({id}, movieData) => { //NOT USED, WILL BE USED IF PPV ENABLED
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/videoRental')
              .set('Authorization', 'Bearer ' + getAuth())
              .send({source:id, movieData})
              .end((err, res) => {
                resetPaymentProcess()
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                // reloadPaymentProcess()
                resolve(res.body)
              })

  })
}

export const getRentedPpvMovie = (vimeoId) => { //NOT USED, WILL BE USED IF PPV ENABLED
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/videoRental/${vimeoId}`)
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(res.body)
              })
  })
}
export const getRentals = () => { //NOT USED, WILL BE USED IF PPV ENABLED
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/videoRental')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res) => {
                resetPaymentProcess()
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                resolve(res.body)
              })
  })
}
export const createOrUpdatePlan = (info) => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/subscriptions')
              .set('Authorization', 'Bearer ' + getAuth())
              .send(info)
              .end((err, res) => {
                resetPaymentProcess()
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                reloadPaymentProcess()
                resolve(res.body)
              })

  })
}

const receiptStubs = [
    {date:'00/00/00', orderID:'12354678789-0', payment:'Visa **** **** ****1234', price:'$11.94'},
    {date:'00/00/00', orderID:'12354678789-0', payment:'Visa **** **** ****1234', price:'$11.94'},
    {date:'00/00/00', orderID:'12354678789-0', payment:'Visa **** **** ****1234', price:'$11.94'},
    {date:'00/00/00', orderID:'12354678789-0', payment:'Visa **** **** ****1234', price:'$11.94'}
]

function formatReciepts(json) {
  const {invoices} = json
  if (!invoices) return []
  return invoices.map(({number, amount, invoice_date, source = {}, planId, type: invoiceType})=>{
    const formattedDate  = moment(invoice_date * 1000).format('L')
    const formattedPrice = amount ? `${Math.floor(amount / 100)}.${amount % 100}` : '0.00'
    let type = ''

    switch (invoiceType) {
      case 'giftee':
        type = 'Gift'
        break

      case 'recipient':
        type = '*Gift'
        break

      default:
        let planType = planId? planId.split(/\W|_/g) : null;
        planType =  planType  && planType[planType.length - 1]  || 'YEARLY'
        type = planType === "MONTHLY" ? 'Monthly' : 'Annual';
        break
    }


    return ({
      date: formattedDate,
      orderID: number,
      number: number || '\u2013',
      payment: `${source.brand || '****'} **** **** **** ${source.last4 || '****'}`,
      price: formattedPrice,
      type
    })
  })
}

export const fetchDataUsage = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/account/data/gdpr')
    .set('Authorization', 'Bearer ' + getAuth())
    .end((err, res) => {
      if (err) return typeof(reject) === 'function' ? reject(err) : null;
      else resolve(res)
    })
  })
}

export const fetchReceipts = () => {
  if (fake) {
    return new Promise((res, rej)=>{
        setTimeout(()=>res(receiptStubs), 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/subscriptions/invoices')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(formatReciepts(JSON.parse(res.text)))
              })
  })
}

export const fetchInitialTickets = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/tickets/list')
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else resolve(res)
      })
  })
}

export const fetchExclusiveTickets = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/tickets')
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else resolve(res)
      })
  })
}

export const fetchTicketCode = (ticketId, flexibleCodeSource) => { // Accept flexibleCodeSource as a parameter
  return new Promise((resolve, reject) => {
    // Include flexibleCodeSource as a query parameter in the API call
    superagent.get(endpoint + `/api/tickets/${ticketId}/code?source=${flexibleCodeSource}`)
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else resolve(res)
      })
  })
}

export const unlinkAccount = (email, username, password) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/account/unlink`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({email, username, password})
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else resolve(res)
      })
  })
}

// linking and search
export const EmailDuplicate = (email,type, params) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + params)
    .set('Authorization', 'Bearer ' + getAuth())
      .send({email, type, })
      .end((err, res) => {
        if (err){
          return typeof(reject) === 'function' ? reject(err) : null;
        }else{
          resolve(res)
        }
      })
  })
}
// confirmation email
export const linkingConfirmationEmail = (email,name) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/account/linking_confirmation`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({email, name})
      .end((err, res) => {
        if (err){
          return typeof(reject) === 'function' ? reject(err) : null;
        }else{
          resolve(res)
        }
      })
  })
}

export const createAuth0User = ((userData, previousAuth0Id) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/account/new_user`)
    .set('Authorization', 'Bearer ' + getAuth())
      .send({userData, previousAuth0Id})
      .end((err, res) => {
        if (err){
          return typeof(reject) === 'function' ? reject(err) : null;
        }else{
          let {linkModalType} = res.body
          setShowModal(linkModalType)
          resolve(res)
        }
      })
  })
})

export const changeAccountSettings = (values) => {
  return new Promise((resolve, reject) => {
    superagent.put(endpoint + `/api/account/settings`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send(values)
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else resolve(res)
      })
  })
}

export const fetchPlaylists = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + `/api/playlists`)
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else resolve(res.body)
      })
  })
}

export const createPlaylist = (title, tracks = []) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/playlists`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({title, tracks})
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else {
          window.displayNotification({type: 'playlistCreated'})
          resolve(res)
        }
      })
  })
}

export const editPlaylist = (id, updatedPlaylist, type) => {
  return new Promise((resolve, reject) => {
    superagent.put(endpoint + `/api/playlists/${id}`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send(updatedPlaylist)
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else {
          if (type === 'changeOrder') {
            window.displayNotification({type: 'playlistEdited'})
          } else {
            window.displayNotification({type: 'playlistRenamed'})
          }
          resolve(res)
        }
      })
  })
}

export const deletePlaylist = (id) => {
  return new Promise((resolve, reject) => {
    superagent.delete(endpoint + `/api/playlists/${id}`)
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else {
          window.displayNotification({type: 'playlistDeleted'})
          resolve(res)
        }
      })
  })
}
export const getTrackById = (id) =>{
  // /v2/track/:trackId
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + `/api/v2/track/${id}`)
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else parseApiData(resolve, res.body)
      })
  })
}
export const getAlbumInfoById = (id) =>{
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + `/api/v2/album/${id}`)
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else parseApiData(resolve, res.body)
      })
  })
}
export const addTracksToPlaylist = (playlistId, tracks = []) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/playlists/${playlistId}/tracks/add`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({tracks, playlistId})
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else {
          window.displayNotification({type: 'songAdded'})
          resolve(res)
        }
      })
  })
}

export const removeTracksFromPlaylist = (playlistId, tracks = []) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/playlists/${playlistId}/tracks/remove`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({tracks, playlistId})
      .end((err, res) => {
        if (err) return typeof(reject) === 'function' ? reject(err) : null;
        else {
          window.displayNotification({type: 'songRemoved'})
          resolve(res)
        }
      })
  })
}

export const getHomeData = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/home`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}

export const getContrarianPageData = () => {
      return new Promise((resolve, reject)=>{
        superagent.get(endpoint + `/api/v2/contrarianPage`)
        .end((err, res)=>{
          if (err) return typeof(reject) === 'function' ? reject(err) : null;
          else parseApiData(resolve, res.body)
        })
     })
  }
export const getNewspaperData = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/newspaper`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}

export const getPageData = (pageNumber) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/news/page-${pageNumber}`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}
export const getArticleById = (article) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/article/${article}`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}
export const getPlaylists = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/playlist`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}


export const getPpvData = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/ppv`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}
export const getPpvMovie = (categoryId, vimeoId) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/ppv/${categoryId}/${vimeoId}`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}

export const getFreeSongs = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/freeSongs`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}
export const getDrawerData = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/drawer`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}
export const getHearseTheaterData = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/movie-night`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}
export const getInitialData = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/initialData`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}

export const getMovie = (movieId) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/v2/movie/${movieId}`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else parseApiData(resolve, res.body)
              })
  })
}

function parseApiData(resolve, res = {}) {
  let data = res.data;
  if (res.asString) {
    if (typeof(res.data) === 'string') {
      try {
        data = JSON.parse(res.data)
      } catch (e) {
        data = null;
      }
    }
  }
  return resolve(data);
}

export const checkCustomerNextProrationInvoice = (planId) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + '/api/subscriptions/customer-next-charge')
              .set('Authorization', 'Bearer ' + getAuth())
              .send({planId})
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  })
}
export const querySearch = (data) => {
  let token = getAccessToken();
  if(!token)token = createJWT().then(_token=>{
    setAccessToken(_token);
    return _token
  })
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/search`)
      .set('Authorization', token)
      .send(data)
      .end((err, res) => {
        if (err){
          return typeof(reject) === 'function' ? reject(err) : null;
        }else{
          resolve(JSON.parse(res.text))
        }
      })
  })
}


export const getTopChart = (type) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/topChart?type=${type}`)
              .end((err, res)=>{
                if (err) return typeof(reject) === 'function' ? reject(err) : null;
                else resolve(JSON.parse(res.text))
              })
  })
}

export const createSubscriptionFreeTrial = (userInfo)=>{
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/subscriptions/promo')
      .set('Authorization', 'Bearer ' + getAuth())
      .send(userInfo)
      .end((err, res)=>{
        resetPaymentProcess();
        if (err) {
          return reject(false);
        }
        reloadPaymentProcessThenGoHome();
        resolve(res.body);
      })
  })
}

export const checkSubscriptionPromoAvailability = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/subscriptions/promo/available')
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res)=>{
        if (err)
          resolve(false);
        const response = JSON.parse(res.text);
        resolve(response.success)
      })
  });
}

export const verifyPromoCode = (userInfo) => {
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/subscriptions/promo/check-code')
      .send(userInfo)
      .end((err, res)=>{
        if (err) {
          return reject(false);
        }
        resolve(res.body);
      })
  })
}
/* Local Variables:               */
/* web-mode-code-indent-offset: 2 */
/* End:                           */
