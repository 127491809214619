import React, { useState, useEffect, useRef } from 'react';
const userAgent = navigator.userAgent.toLowerCase().indexOf('chrome') > -1 
                  ? 'chrome': navigator.userAgent.toLowerCase().indexOf('firefox') > -1 
                  ? 'firefox' : 'safari'

const getArticleURL = () => {
    return window.location.href;
}
const parseBreaklines = (text = '') => {
    return text.replace(/\n/ig, ' ').trimStart();
}
const getFirstWords = (text = '', numberOfWords = 20) => {
    return text.split(' ').slice(0, numberOfWords).join(' ') + '...';
}
const getNewLine = (text = '') => {
    const breakline = '%0D%0A%0D%0A';
    const newLine = text.length ? `${breakline} ${text}` : '';
    return newLine;
}
const handleCloseWindow = (newWindow) => {
    if(userAgent === 'chrome') return;
    newWindow.close()    
}

const ShareCardLink = ({articleURL}) => {
    const [linkCopied, setLinkCopied] = useState(false);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(articleURL);
        setLinkCopied(true);
    }

    return (
        <div className="share-card-link">
            <div className='share-link-text-container'>
                <div className={`copy-link ${!linkCopied ? 'blue-highlight' : ''}`} onClick={handleCopyLink}>
                    {!linkCopied ? 'COPY LINK' : 'LINK COPIED.'}
                </div>
                <span className='share-link-text'>{articleURL}</span>
            </div>
        </div>
    )
}

const ShareCard = ({ shareLink, setShareLink, handleCloseShareMenu, articleRef, articleURL }) => {    
    
    const getEmail = () => {
        const [mainTitleElement] = articleRef.current.getElementsByClassName('main-title');
        const [subTitleElement] = articleRef.current.getElementsByClassName('subtitle');
        const [articleTextElement] = articleRef.current.getElementsByClassName('article-text');

        const title = parseBreaklines(mainTitleElement ? mainTitleElement.innerText : '');
        const subtitle = parseBreaklines(subTitleElement ? subTitleElement.innerText : '');
        const bodyFiller = getFirstWords(parseBreaklines((articleTextElement ? articleTextElement.innerText : '')));

        const subject = `neilyoungarchives.com: ${title}`;
        const body = `From Neil Young Archives: 
            ${getNewLine(title)} 
            ${getNewLine(subtitle)}
            ${getNewLine(bodyFiller)}
            ${getNewLine(articleURL)}
        `;
        const mail = `
            mailto:?subject=${subject}
            &body=${body}
        `;
        return mail;
    }

    const handleOpenShareLinkMenu = () => {
        setShareLink(!shareLink);
    }

    const handleSendEmail = () => {
        const mail = getEmail();
        handleCloseShareMenu();
        let newWindow = window.open(mail, 'emailWindow');
        handleCloseWindow(newWindow)
    }

    const handlePrint = () => {
        // Render a copy of the article in print-area div to print it
        const printAreaElement = document.getElementById("print-area");
        printAreaElement.appendChild(articleRef.current.cloneNode(true));
        handleCloseShareMenu();
        window.print();
        printAreaElement.replaceChildren(); // Clean print-area
    }

    return (
        <div className="share-card">
            <div className='share-card-content-wrapper'>
                <div className='share-card-option right-arrow' onClick={handleOpenShareLinkMenu}>
                    COPY LINK
                </div>
                <div className='share-card-option' onClick={handleSendEmail}>
                    EMAIL
                </div>
                <div className='share-card-option' onClick={handlePrint}>
                    PRINT
                </div>
            </div>
        </div>
    )
}

const ShareButton = ({ articleRef }) => {
    const [shareOpen, setShareOpen] = useState(false);
    const [shareLink, setShareLink] = useState(false);
    const [disableShareButton, setDisableShareButton] = useState(false);
    const ref = useRef(null);
    const articleURL = getArticleURL()

    const handleOpenShareMenu = () => {
        if (disableShareButton) return;
        setShareOpen(true);
        setDisableShareButton(true);
    }

    const handleCloseShareMenu = () => {
        setShareOpen(false);
        setShareLink(false);
        setDisableShareButton(false);
    }

    useEffect(() => {
        //Detects clicks outside ShareButton to close the menu
        function handleOutsideClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleCloseShareMenu();
            }
        }
        
        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref]);

    return (
        <div
            ref={ref}
            className='share-btn'
            onClick={handleOpenShareMenu}
        >
            {shareOpen && 
                <ShareCard 
                    shareLink={shareLink} 
                    setShareLink={setShareLink} 
                    handleCloseShareMenu={handleCloseShareMenu} 
                    articleRef={articleRef}
                    articleURL={articleURL}
                />
            }
            {(shareOpen && shareLink) && 
                <ShareCardLink articleURL={articleURL} />
            }
        </div>
    )
}

export default ShareButton;