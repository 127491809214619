import React, {Component} from 'react'
import cn from 'classnames'

export const URLOpener = url => () => window.open(url)

export class Button extends Component {
    render(){
        let {action, title, blue} = this.props
        return (
            <div className={cn('button', {blue})} onClick={action}>{title}</div>
        )
    }
}

