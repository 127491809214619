import {albums} from './gen-albums'
import _ from 'lodash';

let seen = {}
let removeDuplicates = ({id})=>{
    if (seen[id]) return false;
    seen[id] = true
    return true
}
let tracks = album=>{return album.tracks}

export const allItems = _.filter(_.flatten(_.map(albums.all, tracks)), removeDuplicates)



/* allItems[0].firstItem = true
 * allItems[allItems.length - 1].lastItem = true*/
