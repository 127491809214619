import {Howl} from 'howler'
import _ from 'lodash'
import {stream_from}                from './util/random.js'

document.body.addEventListener('click', function handler() {
    try {
        const suspended = _.get(Howler, 'ctx.state', 'running') === 'suspended'
        if (suspended === true){
            Howler.ctx.resume().then(function() {
                console.log('Howler is resumed...')
                if (this) {
                    this.removeEventListener('click', handler)
                }
            })
        }
    } catch (error) {
        console.warn('Howler can\'t resume...', error)
    }
})

const suffixes = ['01','02','03','04','05','06','07','08','09','10']

const slowSprites    = suffixes.map(s=>[`slow_slide${s}`, `/sounds/slow_scroll_v2/slow_${s}_v2.mp3`])
const fastSprites    = suffixes.map(s=>[`fast_slide${s}`, `/sounds/fast_scroll_v3/fast5clicks_${s}_v2.mp3`])

const howls = slowSprites.concat(slowSprites)
                         .map(pair=>{
    return new Howl({src:[pair[1]], pool:100, preload:true})
})

//slice 0,5 because we get skipping if we try to use all samples.
const nextHowl = stream_from(_.shuffle(howls.slice(0,5)), false)

export function sfxToLoad(){
    return fastSprites.concat(slowSprites)
}

let playing = false

//three speeds, low, slow, and fast.

window.START_TIMEOUT = 50 //gap to smooth over spurious stop/starts

const itemHeight = 60.0
let prevPos = -1

function doPlay(){
    /* howls[0].play()*/
    nextHowl().play()
}

let play

//set the minimum spacing between samples in ms
export const setMaxSpeed = speed => {
    play = _.throttle(doPlay, speed, {leading:true, trailing:false})
    window.maxSpeed = speed
}

setMaxSpeed(50)

export function setSpeed(pos){
    if (prevPos === -1) {
        prevPos = pos
        return
    }

    const here  = Math.floor(pos / itemHeight)
    const there = Math.floor(prevPos / itemHeight)

    prevPos = pos

    if (here !== there) {
        play()
    }
}

let interval = null

function startSFX(){
    if (playing) return
    playing = true
}

function stopSFX(){
    console.log("STOPPED")
    clearInterval(interval)
    interval = null;
    playing = false;
    prevPos = -1
}

let shouldStart = true
let startCounter = 0

export function startSFXForScroll() {
    startCounter++;
    if (!shouldStart) return
    shouldStart = false
    startSFX()
}
let stopTimeout;
export function stopSFXForScroll() {
    let counter = startCounter
    clearTimeout(stopTimeout)
    stopTimeout = setTimeout(()=>{
        shouldStart = true
        if (counter !== startCounter) {
            /* console.log('IGNORING STOP FOR SCROLL') */
            return
        }
        console.log('STOPPING FOR SCROLL')
        stopSFX()
    }, window.START_TIMEOUT)
}
