import React, {Component} from 'react';
import _                  from 'lodash';

export default class VerticalCenter extends Component {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    onClick(e) {
        this.props.onClick && this.props.onClick(e)

        if ((e.target === this.refs.inner ||
            e.target === this.refs.outer) && !this._hasClicked) {
            this._hasClicked = true
            this.props.onBGClick && this.props.onBGClick(e)
        }
    }
    render(){
        const { withoutTableStyling } = this.props
        let cn    = _.has(this.props, 'className') ? this.props.className : ""
        let outer = withoutTableStyling ? {} : {display:'table'}
        let inner = withoutTableStyling ? {width:'100%',height:'100%'} : {display:'table-cell', 'verticalAlign':'middle'}
        let innerClass = this.props.innerClassName || ""

        outer = _.extend({}, this.props.style || {}, outer)

        return (
            <div className={cn} style={outer} onClick={this.onClick} ref="outer">
              <div style={inner}
                   className={innerClass}
                   ref="inner">{this.props.children}</div>
            </div>
        )
    }
}
