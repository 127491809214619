import _            from 'lodash'
import {monthName}  from './util/months'

let currentDate
let currentTrack

export function setCurrentDate(date) {
    let {year, month, day} = date

    if (!day) day = 1
    if (_.isFinite(month)) month = monthName(month)
    year = parseInt(year, 10)

    currentDate = {year, month, day}
    currentTrack = null
}

export function setCurrentTrack(track) {
    setCurrentDate(track.releaseDate)
    currentTrack = track
}


export function getCurrentDate() {
    return currentDate
}

export function getCurrentTrack(){
    return currentTrack
}

export function fmt(o){
    var r = []
    Object.keys(o).forEach(k=>{
        r.push(`${k}=${o[k]}`)
    })
    return r.join('&')
}

export function getCurrentDateAsQueryString(includePrevTrack){
    let date = new Date()
    //month and day us set as defautl so the url won't display null
    if (!currentDate && date) currentDate = {'day':1, 'month':1, 'year': date.getFullYear()}


    let {day, month, year} = currentDate
    let params = {day, month, year}
    if (currentTrack) {
        let {id, album}  = currentTrack
        params.currTrack = id 
        if (album) {
            params.hl = album.id
            if (includePrevTrack) params.prevTrack = id
        }
    }
    return fmt(params)
}
