import React, {Component} from 'react';
import _                  from 'lodash';
import cn                 from 'classnames';
import FolderTabs         from './folder-tabs';
import VerticalCenter     from './vertical-center';

let positions = {
    left:   ['title', 'photos', 'documents', 'press', 'memorabilia'] ,
    center: ['photos', 'documents','title', 'press', 'memorabilia'],
    right:  ['photos', 'documents', 'press', 'memorabilia','title'] 
}

export default class OpenFolder extends Component {
    getIndexClass(position, selected){
        //index-0, index-1, etc
        let idx = _.findIndex(positions[position], selected)
        return `index-${idx}`
    }
    render(){
        let {track, selected} = this.props
        let {position}        = track
        let index = this.getIndexClass(position, selected)
        let cx=cn({
            'open-folder':true,
            position, [index]:true 
        })

        let folder_background = cn({'folder-background':true, [position]:true})

        return (
            <div className={cx}>
              <VerticalCenter style={{height:'100%'}}>
                <div className="card">
                  <FolderTabs track={track} selected={selected} />
                  <div className="close" onClick={this.props.onClose}></div>
                  <div className={folder_background}></div>
                  <div className="folder-body">
                    {this.props.children}
                  </div>
                </div>
              </VerticalCenter>
            </div>
        )
    }
}
