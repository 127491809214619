import { ENTRIES_LOADED, ARTISTS_LOADED } from '../constants';

const initialState = {};

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.artist) return action.entries.artist;
            break;
        case ARTISTS_LOADED:
            if(action.artists) return action.artists;
            break;
    }
    return state;
}
