
import React from 'react';
import {compareDates} from './utils';
import notebookImg from '../../../images/timeline/notebook.png';
const NotebookIcon = ({startPos, width, height, performance}) => {
    if (performance.asset.hasLog) {
        const notebookStyle = {left:`${startPos}%`, top: `-${height}px`, width: `${width}px`, height: `${height}px`};
        return <img className="log-icon" style={notebookStyle} src={notebookImg} alt="performance log notebook"/>
    }

    return null;

}
const Performance = (props) => {
    const {
        performances,
        calcPosition,
        dateElement,
        onClick,
        tour,
        month,
        year,
        iconDimensions
    } = props
    if (!performances || !performances.list || performances.list.length === 0) {return null;}// size early-out
    const {startDate, endDate} = performances;
    if (endDate[dateElement] !== props[dateElement]) {return null;} // early-out

    const onUserClick = (event) => {
        onClick(event, tour)
    }
    
    const calcFixedPosition = (startDate, endDate) => {

        let performanceEndDate = endDate;
        let performanceStartDate = startDate;

        let startPositionOffset = 0;
        let endPositionOffset = 0;
        let split = false;
        if (performanceStartDate[dateElement] !== performanceEndDate[dateElement]) {

            if (performanceEndDate[dateElement] === props[dateElement]) {
                // we are being bled into
                split = true;
                if (dateElement === "month") {
                    const startPos = calcPosition(performanceStartDate);
                    performanceStartDate = { ...performanceEndDate, day: 0};
                    startPositionOffset = startPos - 100;
                } else {
                    const startPos = calcPosition(performanceStartDate);
                    performanceStartDate = { ...performanceEndDate, month: 1, day: 0};
                    startPositionOffset = startPos - 100;
                }
            } 
            else if (performanceStartDate[dateElement] === props[dateElement]){
                // we are bleeding into the next
                if (dateElement === "month") {
                    const yearDiff = performanceEndDate.year - performanceStartDate.year;
                    const monthDiff = (performanceEndDate.month + (12 * yearDiff)) - performanceStartDate.month;
                    endPositionOffset = monthDiff + 100 * (yearDiff + 1);
                } else {
                    const yearDiff = performanceEndDate.year - performanceStartDate.year;
                    const monthDiff = performanceEndDate.month + (12 * yearDiff) - performanceStartDate.month;
                    endPositionOffset = (yearDiff + (monthDiff / 12)) * 100;
                }

            }
        }
        const startPos = startPositionOffset + calcPosition(performanceStartDate);
        const endPos = endPositionOffset + calcPosition(performanceEndDate);
        
        return {startPos, endPos, split};
    }
    
    const notebooks = performances.list.map(performance => {
        const perfStartDate = performance.asset.startDate;
        const perfEndDate = performance.asset.endDate;
        // we don't care if they start next month/year
        if(perfStartDate[dateElement] > props[dateElement]) {return null;}
        // we don't care about it if it starts and ends in another month/year
        if (perfStartDate[dateElement] !== props[dateElement] && perfEndDate[dateElement] !== props[dateElement]) {return null;}

        const {startPos, endPos} = calcFixedPosition(perfStartDate, perfEndDate);

        const left = startPos + (endPos - startPos) / 2
        return <NotebookIcon startPos={left} performance={performance} width={iconDimensions.width} height={iconDimensions.height}/>

    })
    const { startPos, endPos } = calcFixedPosition(startDate, endDate);

    const left = `${startPos}%`;
    let classes = "performance";// + (split ? " last" : "");

    // select the color class
    const yearDiff = endDate.year - startDate.year;
    const monthDiff = endDate.month + (12 * yearDiff) - startDate.month;
    const dayDiff = endDate.day + (31 * monthDiff) - startDate.day + 1;
    // we add +1 to the day diff because we count the starting day
    // i.e: march20-march22 --> this show lasted 3 days [20, 21, 22] but
    // substracting the days we get 22-20 = 2, so we need to add 1.

    if (compareDates(startDate, endDate) && performances.list.length === 1) {
        classes += " same-day"
    }
    if (dayDiff > 2) {
        classes += " multiple"
    }
    if (performances.isContinous) {
        classes += " continous"
    }
    
    const style = {left: left, width: `${endPos - startPos}%`};
    return (
        <>
            <div onClick={onUserClick} className={classes} style={style}/>
            {notebooks}
        </>
    );
}

Performance.defaultProps = {
    iconDimensions: {width: 55, height: 55}
}
export default Performance