import _ from 'lodash'
import { ENTRIES_LOADED } from '../constants';

const initialState = []

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.lyrics) {
                //convert to array
                // console.log("got lyrics.")
                return _.map(action.entries.lyrics, it=>it)
            }
            break;
    }
    return state;
}
