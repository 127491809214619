import React, { Component } from 'react';
import { fetchDataUsage } from '../services/api.js';

class DataUsagePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            fetchingData: false,
        };
        this.onButtonClick = this.onButtonClick.bind(this)
        this.encode = this.encode.bind(this)
    }

    encode (s) {
        var out = [];
        for (var i = 0; i < s.length; i++) {
            out[i] = s.charCodeAt(i)
        }
        return new Uint8Array(out)
    }

    onButtonClick () {
        this.setState({fetchingData: true})
        fetchDataUsage()
            .then(result => {
                var data = JSON.stringify(result.body, null, 2)
                this.setState({data, fetchingData: false})
            })
            .catch(err => {
                this.setState({fetchingData: false})
                console.error(err)
            })
    }

    fetchDataButton () {
        let {state: {data}} = this
        if (!data) {
            return <div className="button" onClick={this.onButtonClick}>SHOW ME MY DATA</div>
        } else {
            return null
        }
    }

    renderData () {
        let {state: {data}} = this
        let rows = []
        const onCopy = () => {
            const targetId = "_hiddenCopyText_"
            let target = document.createElement("textarea")
            target.style.position = "absolute"
            target.style.left = "-9999px"
            target.style.top = "0"
            target.id = targetId
            document.body.appendChild(target)
            target.textContent = data

            const currentFocus = document.activeElement
            target.focus()
            target.setSelectionRange(0, target.value.length)

            // copy the selection
            let succeed
            try {
                  succeed = document.execCommand("copy")
            } catch(e) {
                succeed = false
            }
            // restore original focus
            if (currentFocus && typeof currentFocus.focus === "function") {
                currentFocus.focus()
            }

            target.textContent = ""

            return succeed
        }

        function eachRecursive(obj) {
            for (var k in obj) {
                if (typeof obj[k] === "object" && obj[k] !== null) {
                    eachRecursive(obj[k])
                } else {
                    rows.push({key: k, data: obj[k] + ''})
                }
            }
        }
        if (data) {
            eachRecursive(JSON.parse(data))
            return (
                <div className='data-container'>
                    <div className="button clear" onClick={onCopy}>COPY MY DATA</div>
                    <span className="message">Click <a href="/contact?option=3">here</a> to request changes to your data</span>
                    {rows.map((item, ind) => (
                        <div className='row' key={`row-${ind}`}>
                            <div className='key'>{item.key}</div>
                            <div className='data'>{item.data}</div>
                        </div>
                    ))}
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        const { fetchingData } = this.state
        return (
            <div className="data-panel panel">
                <div className="title">MY DATA</div>
                <div className="data-content">
                    <span className="data-message">This shows you the data we save about you and your account.</span>
                    <span className="description-message">Please refer to our <a href="/privacy.html" target="blank" >Privacy Policy</a>. Our goal is to be fully transparent and to store the least amount of data possible. Please note that, whether disclosed or not, most every site captures data.</span>
                    { fetchingData && <span className="data-message">Fetching Data...</span>}
                    {this.fetchDataButton()}
                    {this.renderData()}
                    <span className="data-message">NYA uses third parties to run, please find links to their privacy policies below.</span>
                    <ul className="privacyLinks">
                        <li className="privacyLink"><a href='https://policies.google.com/privacy' target="_blank">Google Analytics</a></li>
                        <li className="privacyLink"><a href='https://stripe.com/privacy-center/legal' target="_blank">Stripe</a></li>
                        <li className="privacyLink"><a href='https://auth0.com/privacy' target="_blank">Auth0</a></li>
                        <li className="privacyLink"><a href='https://www.heroku.com/policy/security' target="_blank">Heroku</a></li>
                        <li className="privacyLink"><a href='https://www.orastream.com/privacy' target="_blank">Orastream</a></li>
                        <li className="privacyLink"><a href='http://www.wmg.com/privacy/wmg' target="_blank">WBR</a></li>
                        <li className="privacyLink"><a href='https://www.papertrail.io/privacy/' target="_blank">Papertrail</a></li>
                        <li className="privacyLink"><a href='https://www.groovehq.com/our/privacy' target="_blank">Groove</a></li>
                        <li className="privacyLink"><a href='https://www.mailgun.com/privacy-policy' target="_blank">Mailgun</a></li>
                        <li className="privacyLink"><a href='https://slack.com/privacy-policy' target="_blank">Slack</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default DataUsagePanel;