import React, { Component } from 'react';
import cn from 'classnames';
import { Link } from 'react-router';
import { ScrollContainer } from 'react-router-scroll';
import PropTypes from 'prop-types';
import VerticalCenter from './components/vertical-center';
import { updateMetadata } from './actions/metas';
import { connect } from 'react-redux';
import defaultBackground from '../images/map/background-map.png';
import mapLayout from '../images/map/map-layout.png';
import closeButton from '../images/map/x-button.png';
import greedyStoreImage from '../images/map/greedy_hand_H.png';
import hearseTheaterImage from '../images/map/hearse_theater_H.png';
import findImage from '../images/map/find_H.png';
import booksImage from '../images/map/Timeline_books_H.png';
import ticketsImage from '../images/map/tickets_H.png';
import giftImage from '../images/map/gift_H.png';
import { updateArticleView, updateArticle } from './actions/articleView';

// Map component
class Map extends Component {
  // Constructor
  constructor(props, context) {
    super(props, context);
    this.onClickX = this.onClickX.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    let page = parseInt(props.params ? props.params.page : 1) || 1;

    // Initial state
    this.state = {
      page,
      routes: {},
      maxPage: 0,
      pages: [],
      pagesData: [],
      navRoutes: {},
      isMounted: false,
      playlistSOTW: '',
      freeAlbum: '',
      top40: [],
    };
    this.headerHidden = false;
  }

  // Lifecycle method: Component will mount
  componentWillMount() {
    updateMetadata({ title: 'NYA Map' });
    return;
  }

  // Lifecycle method: Component did mount
  componentDidMount() {
    setTimeout(() => {
      this.setState({ ready: true });
    }, 50);
    document.addEventListener('keydown', this.escFunction, false);
  }

  // Lifecycle method: Component will unmount
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  // Handler for click on X
  onClickX() {
    const { router } = this.context;
    router.push(router.createLocation('/welcome'));
  }

  // Handler for scroll event
  handleScroll(e) {
    const target = e.target || {};
    let scrollTop = target.scrollTop;
    if (scrollTop <= 50) {
      if (this.headerHidden) {
        this.setState({ hideHeader: false });
        this.headerHidden = false;
      }
    } else {
      if (!this.headerHidden) {
        this.setState({ hideHeader: true });
        this.headerHidden = true;
      }
    }
  }

  // Parse routes
  routesParse(pagesData) {
    let existingPages = [];
    let routes = {};
    let pages = { styles: 'front-page' };
    let routesList = ['/map'];
    let themeAperance = 'light-mode';
    let backgroundImage = defaultBackground;
    let mapLayoutImage = mapLayout;

    const maxPage = existingPages.length;
    const positionRoutes = Object.assign({}, routesList);

    // Render clickable area
    const renderClickableArea = (areaId, position, size, onClickHandler) => {
      const zoomFactor = window.devicePixelRatio || 1;
      const adjustedLeft = `${parseFloat(position.left) * zoomFactor}px`;
      const adjustedTop = `${parseFloat(position.top) * zoomFactor}px`;

      const areaStyle = {
        position: 'absolute',
        left: adjustedLeft,
        top: adjustedTop,
        width: size.width,
        height: size.height,
        cursor: 'pointer',
        backgroundColor: 'transparent',
      };

      return (
        <div key={areaId} style={areaStyle} onClick={onClickHandler}></div>
      );
    };

    // Add a CSS class for the hover effect
    const hoverClass = 'hovered';

    // Render clickable areas
    const renderClickableAreas = () => {
      const { freeAlbum = null } = this.props;
      console.log('freeAlbum', freeAlbum);

      const areas = [
        {
          id: 'home',
          position: { left: '881px', top: '325px' },
          size: { width: '159px', height: '80px' },
          borderRadius: '10px',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/welcome'));
          },
        },
        {
          id: 'close',
          position: { left: '1636px', top: '50px' },
          size: { width: '31px', height: '29px' },
          borderRadius: '10px',
          image: closeButton,
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/welcome'));
          },
        },
        {
          id: 'xstream',
          position: { left: '822px', top: '148px' },
          size: { width: '112px', height: '55px' },
          borderRadius: '10px',
          onClickHandler: () => {
            window.open('https://store.neilyoungarchives.com/', '_blank');
          },
        },
        {
          id: 'greedy-store',
          position: { left: '961px', top: '137px' },
          size: { width: '135px', height: '70px' },
          image: greedyStoreImage,
          borderRadius: '10px',
          onClickHandler: () => {
            window.open('https://neilyoung.warnerrecords.com/', '_blank');
          },
        },
        {
          id: 'find',
          position: { left: '1301px', top: '53px' },
          size: { width: '55px', height: '73px' },
          image: findImage,
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/search'));
          },
        },
        {
          id: 'books',
          position: { left: '1013px', top: '607px' },
          size: { width: '67px', height: '80px' },
          image: booksImage,
          borderRadius: '10px',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/timeline?album=B_05&day=30&month=8&year=2019'));
          },
        },
        {
          id: 'hearse-theater',
          position: { left: '618px', top: '161px' },
          size: { width: '157px', height: '79px' },
          image: hearseTheaterImage,
          borderRadius: '10px',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/movie-night/home'));
          },
        },
        {
          id: 'times-contrarian',
          position: { left: '1074px', top: '217px' },
          size: { width: '178px', height: '63px' },
          borderRadius: '10px',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/news/1'));
          },
        },
        {
          id: 'top-40-tracks',
          position: { left: '1269px', top: '190px' },
          size: { width: '75px', height: '48px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/news/top-40-tracks'));
          },
        },
        {
          id: 'subscribe',
          position: { left: '1204px', top: '69px' },
          size: { width: '84px', height: '45px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/account?screen=plans'));
          },
        },
        {
          id: 'whats-new',
          position: { left: '1110px', top: '158px' },
          size: { width: '85px', height: '40px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/news/1'));
          },
        },
        {
          id: 'tickets',
          position: { left: '1273px', top: '133px' },
          size: { width: '75px', height: '35px' },
          image: ticketsImage,
          borderRadius: '10px',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/account?screen=tickets'));
          },
        },
        {
          id: 'gifts',
          position: { left: '1204px', top: '124px' },
          size: { width: '62px', height: '66px' },
          image: giftImage,
          borderRadius: '10px',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/account?screen=gift'));
          },
        },
        {
          id: 'album-week',
          position: { left: '1274px', top: '251px' },
          size: { width: '63px', height: '56px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation(`/album?id=${freeAlbum.id}`));
          },
        },
        {
          id: 'movietone',
          position: { left: '628px', top: '272px' },
          size: { width: '125px', height: '125px' },
          borderRadius: '50%',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/movie-night/movietone'));
          },
        },
        {
          id: 'screen-1',
          position: { left: '573px', top: '108px' },
          size: { width: '55px', height: '55px' },
          borderRadius: '50%',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/movie-night/fireside-sessions'));
          },
        },
        {
          id: 'screen-2',
          position: { left: '618px', top: '65px' },
          size: { width: '55px', height: '55px' },
          borderRadius: '50%',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/movie-night/hearse-daily'));
          },
        },
        {
          id: 'screen-3',
          position: { left: '668px', top: '99px' },
          size: { width: '55px', height: '55px' },
          borderRadius: '50%',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/movie-night/subscriber-features'));
          },
        },
        {
          id: 'cabinet',
          position: { left: '771px', top: '375px' },
          size: { width: '71px', height: '177px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/drawer?position=100'));
          },
        },
        {
          id: 'playlists',
          position: { left: '676px', top: '454px' },
          size: { width: '77px', height: '67px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/playlist?id=P_002'));
          },
        },
        {
          id: 'daily30',
          position: { left: '576px', top: '472px' },
          size: { width: '86px', height: '19px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/playlist?id=P_006'));
          },
        },
        {
          id: 'songs-of-the-week',
          position: { left: '597px', top: '502px' },
          size: { width: '60px', height: '34px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(router.createLocation('/playlist?id=P_002'));
          },
        },
        {
          id: 'concerts-performance',
          position: { left: '1122px', top: '670px' },
          size: { width: '135px', height: '68px' },
          borderRadius: '50%',
          onClickHandler: () => {
            const { router } = this.context;
            router.push(
              router.createLocation(
                '/timeline?month=may&year=2001'
              )
            );
          },
        },
        {
          id: 'timeline',
          position: { left: '890px', top: '449px' },
          size: { width: '175px', height: '40px' },
          onClickHandler: () => {
            const { router } = this.context;
            router.push(
              router.createLocation('/timeline?day=1&month=oct&year=2023')
            );
          },
        },
      ];

      // Styles for the render areas
      return areas.map((area) => {
        const areaStyle = {
          position: 'absolute',
          left: area.position.left,
          top: area.position.top,
          width: area.size.width,
          height: area.size.height,
          cursor: 'pointer',
          // backgroundColor: 'rgba(255, 255, 144, 1)', // Set initial color to YELLOW FOR DEBUGGING
          backgroundColor: 'rgba(0, 0, 0, 0)', // Set initial opacity to 0 (transparent black)
          borderRadius: area.borderRadius || '0',
          backgroundImage: 'none',
          backgroundSize: area.id === 'find' ? '70%' : 'cover', // Adjust the size as needed
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        };

        // Event listeners for hover effect
        const handleMouseEnter = (event, id) => {
          if (area.image) {
            event.target.style.backgroundImage = `url(${area.image})`; // Set the image when mouse enters
            event.target.style.backgroundSize = id === 'find' ? '70%' : 'cover'; // Adjust the size as needed
          } else if (id === 'timeline') {
            event.target.style.background =
              'radial-gradient(circle at center, rgba(118, 117, 115, 0.65), transparent)';
          } else {
            event.target.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // Set opacity to 0.5 (semi-transparent black) on hover for other areas
          }
        };

        const handleMouseLeave = (event, id) => {
          if (area.image) {
            event.target.style.backgroundImage = 'none'; // Remove the image when mouse leaves
          } else if (id === 'timeline') {
            event.target.style.background = 'none'; // Remove gradient when not hovering over 'timeline'
          } else {
            event.target.style.backgroundColor = 'rgba(0, 0, 0, 0)'; // Set opacity back to 0 (transparent black) when not hovering over other areas
          }
        };

        return (
          <div
            key={area.id}
            style={areaStyle}
            onClick={area.onClickHandler}
            onMouseEnter={(event) => handleMouseEnter(event, area.id)}
            onMouseLeave={(event) => handleMouseLeave(event, area.id)}
            className={hoverClass}
          ></div>
        );
      });
    };

    return {
      routes,
      pages,
      maxPage,
      existingPages,
      positionRoutes,
      themeAperance,
      backgroundImage,
      mapLayoutImage,
      renderClickableArea,
      renderClickableAreas,
    };
  }

  render() {
    const {
      pagesData = [],
      isArticleView = false,
      articleData = {},
    } = this.props;
    let { page, hideHeader } = this.state;

    const routes = this.routesParse(pagesData);

    let pageStyle = routes && routes.pages['styles'];
    let backgroundImage = routes && routes.backgroundImage;
    let mapLayoutImage = routes && routes.mapLayoutImage;
    let themeAperance = routes && routes.themeAperance;

    const showArticle = isArticleView && !_.isEmpty(articleData);
    const strollStyle = showArticle
      ? { overflowY: 'hidden', visibity: 'hidden' }
      : {};
    hideHeader = showArticle ? false : hideHeader;

    const mapBackgroundStyle = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    };

    const mapImageBackgroundStyle = {
      mapLayoutImage: `url(${mapLayoutImage})`,
      mapSize: 'cover',
      mapPosition: 'center center',
      mapRepeat: 'no-repeat',
    };

    const clickableAreasContainer = document.getElementById("clickable-areas");
    const currHeight = clickableAreasContainer?.clientHeight || 838;
    const scale = currHeight / 838;
    const translateX =  735.646251 * scale - 958.750039; // Translate in X according zoom
    const translateY =  417.708721 * scale - 443.380673; // Translate in Y according zoom

    const wrapperStyle = {
      transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
    }

    return (
      <div className="map-page">
        <ScrollContainer scrollKey={this.props.location.pathname}>
          <div id="clickable-areas" style = { currHeight > 840 ? wrapperStyle : null}>
            {routes.renderClickableAreas()}
          </div>
          {this.props.children}
          <VerticalCenter
            style={{ width: '100%', height: '100%' }}
          ></VerticalCenter>
        </ScrollContainer>
      </div>
    );
  }
}

Map.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  const freeAlbums = state.commonValues.freeAlbums || {};
  const freeAlbum =
    Object.keys(freeAlbums).length > 0
      ? freeAlbums[Object.keys(freeAlbums)[0]]
      : null;
  return {
    freeAlbum,
  };
};

export default connect(mapStateToProps, { updateArticleView, updateArticle })(
  Map
);
