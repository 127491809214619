import { hasAuth, getUserInfo } from '../services/api'

export const getABTestURL = film => {
	const {
		liveStreamingUrlPrimary,
		liveStreamingUrlSecondary,
		liveStreamingUrlTertiary
	} = film

	const liveStreamingUrls = _.compact([
		liveStreamingUrlPrimary,
		liveStreamingUrlSecondary,
		liveStreamingUrlTertiary
	])

	// No streaming url has set
	if (liveStreamingUrls.length === 0) {
		return false
	}

	// We need user email's first letter to switch between urls,
	// unless we will support A/B testing for anonymous users
	if (!hasAuth()) {
		return _.nth(liveStreamingUrls, 0)
	}

	try {
		const { email } = getUserInfo()
		const firstLetter = email.charAt(0).toLowerCase()
		const chars = 'abcdefghijklmnopqrstuvwxyz'.split('')
		const chunk = _.chunk(chars, Math.ceil(chars.length / liveStreamingUrls.length))
		const idx = _.findIndex(chunk, group => _.includes(group, firstLetter))

		return _.nth(liveStreamingUrls, idx)
	} catch (err) {
		console.error('Error at getABTestURL', err)
		return _.nth(liveStreamingUrls, 0)
	}
}