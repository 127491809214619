import {scale}             from './scale'

/* the drawer open animation needs to be recalculated on window resize for 
   non-chrome browsers, since the pure css solution performs poorly */

function updateDrawerTransitionAnimation() {

    var h = Math.floor(window.innerHeight * (1/scale()))
    var b = 108 /* height of the global bar */

    var top = h

    var css = `

.x-drawer-slide-down-enter {
  -webkit-transform: translateY(-${ top - b }px);
          transform: translateY(-${ top - b }px); }
  .x-drawer-slide-down-enter-active {
    -webkit-transform: translateY(${ 0 }px);
            transform: translateY(${ 0 }px);
    -webkit-transition: -webkit-transform 1000ms ease-in-out;
    transition: -webkit-transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out, -webkit-transform 1000ms ease-in-out; }

.x-drawer-slide-down-leave {
  -webkit-transform: translateY(-${ top }px);
          transform: translateY(-${ top }px); }
  .x-drawer-slide-down-leave-active {
    -webkit-transform: translateY(${ -b }px);
            transform: translateY(${ -b }px);
    -webkit-transition: -webkit-transform 1000ms ease-in-out;
    transition: -webkit-transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out, -webkit-transform 1000ms ease-in-out; }

.x-drawer-slide-up-enter {
  -webkit-transform: translateY(${ top - b }px);
          transform: translateY(${ top - b }px); }
  .x-drawer-slide-up-enter-active {
    -webkit-transform: translateY(${ 0 }px);
            transform: translateY(${ 0 }px);
    -webkit-transition: -webkit-transform 1000ms ease-in-out;
    transition: -webkit-transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out, -webkit-transform 1000ms ease-in-out; }

.x-drawer-slide-up-leave {
  -webkit-transform: translateY(-${ top }px);
          transform: translateY(-${ top }px); }
  .x-drawer-slide-up-leave-active {
    -webkit-transform: translateY(-${ (top * 2) - b }px);
            transform: translateY(-${ (top * 2) - b }px);
    -webkit-transition: -webkit-transform 1000ms ease-in-out;
    transition: -webkit-transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out;
    transition: transform 1000ms ease-in-out, -webkit-transform 1000ms ease-in-out; }

    `

    var el = document.getElementById('drawer-open-transition')
    if (el.styleSheet) el.styleSheet.cssText = css
    else {
        el.innerText = ''
        el.appendChild(document.createTextNode(css))
    }
   
}

export function handleDynamicDrawerTransition() {
    window.addEventListener('resize', updateDrawerTransitionAnimation, false);
    updateDrawerTransitionAnimation()
}
