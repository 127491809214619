import React, {Component} from 'react'
import cn                 from 'classnames'
import {scale}            from '../scale'
import PropTypes          from 'prop-types'

function getImageScale(photo) {
    let isAlbumArt = (photo.type === 'albumArt')

    let imgs   = scale()
    console.log('scale = ', imgs)

    let cutoff = 0.8, adjust = 0.8

    imgs = imgs > cutoff
         ? adjust * imgs
         : (isAlbumArt ? 0.95 : imgs)

    imgs = 1 / imgs

    return imgs
}
function center(photo, image, window, pct){
    /* center the top left corner in the window,
       then adjust so that pct in centered */
    let large    = image * getImageScale(photo)
    let centerTL = (window / 2) + ((large - image) / 2)
    return centerTL - (large * pct)
}

export default class DocumentsModal extends Component {
    constructor(props){
        super(props)

        let {initialX, initialY} = this.props
        let x = parseFloat(initialX || 0.5, 10),
            y = parseFloat(initialY || 0.5, 10)

        let S = scale()

        let inverseScale = 1/S

        this.state = {scale: S}

        let {photo} = this.props

        if (photo) this._positionImage(photo, x, y, inverseScale);

        this._mouseX = 0; this._mouseY = 0

        this.mouseMove  = this.mouseMove.bind(this)
        this.onClick    = this.onClick.bind(this)
        this.onResize   = this.onResize.bind(this)
        this.dragStart  = this.dragStart.bind(this)
        this.dragEnd    = this.dragEnd.bind(this)
        this.onBack     = this.onBack.bind(this)
        this.touchStart = this.touchStart.bind(this)
        this.touchMove  = this.touchMove.bind(this)
        this.touchEnd   = this.touchEnd.bind(this)

    }
    _positionImage(photo, x, y, inverseScale){
        /* center image in viewport */
        let s = inverseScale

        let width, height

        if (photo.oversize) {
            width = photo.oversizeWidth
            height = photo.oversizeHeight
        } else {
            width = photo.fullWidth
            height = photo.fullHeight
        }

        let imgs = getImageScale(photo)

        let w = width  * imgs,
            h = height * imgs

        let W = window.innerWidth * s,
            H = window.innerHeight * s

        this.x = center(photo, w, W, x)
        this.y = center(photo, h, H, y)
    }
    componentWillReceiveProps(newprops){
        if (newprops.photo && newprops.photo !== this.props.photo) {
            console.log('positioning new image')
            this._positionImage(newprops.photo, 0.5, 0.5, 1/scale())
        }
    }
    onClick(){
        if (this._didMove) return
        this.props.onDismiss && this.props.onDismiss()
    }
    dragStart(e){
        return this._start(e, e.pageX, e.pageY)
    }
    mouseMove(e){
        return this._move(e, e.pageX, e.pageY)
    }
    dragEnd(e){
        return this._end(e)
    }
    touchStart(e){
        if (e.touches.length > 1) return
        let touch = e.touches[0]
        return this._start(e, touch.pageX, touch.pageY)
    }
    touchMove(e){
        if (e.touches.length > 1) return
        let touch = e.touches[0]
        return this._move(e, touch.pageX, touch.pageY)
    }
    touchEnd(e){
        if (e.touches.length > 1) return
        return this._end(e)
    }
    _start(e, pageX, pageY){
        e.preventDefault()
        e.stopPropagation()
        this._dragging = true
        let is = 1/scale(),
            x = pageX * is,
            y = pageY * is
        this._initialMousePosition = {x,y}
        this._didMove = false
    }
    _move(e, pageX, pageY){
        if (!this._dragging) return

        this._didMove = true

        e.preventDefault()
        e.stopPropagation()

        let {x,y} = this._initialMousePosition

        let is   = 1/scale()
        let delx = (pageX * is) - x
        let dely = (pageY * is) - y

        let nx = this.x + delx;
        let ny = this.y + dely

        let imgs = getImageScale(this.props.photo)

        this.refs.image.style.transform = `translate(${nx}px, ${ny}px) scale(${imgs})`

        this._mouseX = nx 
        this._mouseY = ny 
    }
    _end(e){
        this._dragging = false
        e.preventDefault()
        e.stopPropagation()
        this.x = this._mouseX
        this.y = this._mouseY
    }
    componentDidMount(){
        window.addEventListener('resize', this.onResize)
    }
    onResize(){
        this.setState({scale:scale()})
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize)
    }
    getPhotoInfo(photo, key){
        let wKey = key+'Width'
        let hKey = key+'Height'

        let imgs = getImageScale(photo)

        let width  = photo[wKey] * imgs + 'px'
        let height = photo[hKey] * imgs + 'px'

        let url = photo[key]
        let backgroundImage = `url(${url})`

        let transform = `translate(${this.x}px, ${this.y}px) scale(${imgs})` 

        return {url, style: {width, height, transform, backgroundImage}}
    }
    onBack(){
        this.context.router.goBack()
    }
    render(){
        let {photo} = this.props

        let isAlbumArt = photo && (photo.type === 'albumArt')

        let style, url

        if (photo) {

            let info = null

            if (photo.oversize) info = this.getPhotoInfo(photo, 'oversize');
            else                info = this.getPhotoInfo(photo, 'full')

            url   = info.url
            style = info.style
        }

        let isDark = !!this.props.darkBackground

        let cx = cn({normal:(!isAlbumArt && !isDark),
                     dark: isDark,
                     'album-art':isAlbumArt})

        return (
            <div id="documents-modal" className={cx}>
              { photo ?
              <div className="document-pan"
                   onClick={this.onClick} >
                <div className="image-view"
                     ref="image" style={style}
                     onMouseDown={this.dragStart}
                     onMouseUp={this.dragEnd}
                     onMouseMove={this.mouseMove}
                     onTouchStart={this.touchStart}
                     onTouchMove={this.touchMove}
                     onTouchEnd={this.touchEnd}
                />
              </div>
              : null }
              { this.props.showZoom ?
                <div className="zoom-out" onClick={this.onBack} />
                : null}
               <div className="close-button" onClick={this.onBack} />
            </div>
        )
    }
}

DocumentsModal.contextTypes = {
    router: PropTypes.object.isRequired
}
