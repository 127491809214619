import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import fallbackImage from '../../images/movie-night/fallback.jpg';

export default class VideoTable extends Component {
  constructor(props) {
    super(props);
    this.state = {hover:false};
    this.renderTableData = this.renderTableData.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }
  renderRequests(requests) {
    let userRequests = [];
    if (!_.isEmpty(requests)) {
      requests.map((request, key) => {
        if (key <= 1) {
          userRequests.push(<div key={key}>{request.userName}</div>);
        }
      });
    }

    return <div className="user-requests">{userRequests}</div>;
  }

  displayVideo(vimeoId) {
    const { router } = this.props.context;
    const {canViewExclusiveContent} = this.props

    if(!canViewExclusiveContent()){
      window.unlimitedAcced();
      return;
    }
    router.push(router.createLocation(`/video?vimeoId=${vimeoId}`));
  }
  onMouseEnter(videoId) {
    this.setState({ hover: videoId });
  }
  onMouseLeave() {
    this.setState({ hover: false });
  }
  renderTableData() {
    const {
      videos,
      userRequestItems,
      showRequests,
    } = this.props;
    const {hover} = this.state

    const sortedVideos = videos.sort((a,b)=>{
      if (!_.has(a, "asset.date") || !_.has(b, "asset.date")) return;
      const {
        asset: {
          date: {
            day: aday = "1",
            month: amonth = "1",
            year: ayear = "1969",
          },
        },
      } = a;
      const {
        asset: {
          date: {
            day: bday = "1",
            month: bmonth = "1",
            year: byear = "1969",
          },
        },
      } = b;
      return (
       new Date(`${bmonth}/${bday}/${byear}`) -  new Date(`${amonth}/${aday}/${ayear}`) 
       
      );
    })
    return sortedVideos.map((video, index) => {
      let {
        _id,
        thumbnail,
        requestViewed,
        asset: { date, vimeoId, title },
        userRequests,
      } = video;
      const {
        day = "1",
        month = "1",
        year = "1969",
      } = date;
      
      // create a new date object substracting one month since the BE sends 
      // months starting from 1 instead of 0.
      const dateOptions = {day:"numeric", month:"numeric",year:'2-digit'};
      const dateObj = new Date(year, month - 1, day);
      const displayDate = dateObj.toLocaleDateString('en', dateOptions);

      let showUserRequest = false;
      let userRequesImage = "";
      let requestVisited = requestViewed ? " active" : " innactive";
      let hoverState     = hover == index ? " hover" : ''
      
      if (!_.isEmpty(userRequestItems) && !_.isEmpty(userRequests)) {
        showUserRequest = userRequestItems.reduce((prev, curr) => {
          return Math.abs(curr.minRequest - userRequests.length) <
            Math.abs(prev.minRequest - userRequests.length)
            ? curr
            : prev;
        });
      }
      if (
        !_.isEmpty(showUserRequest) &&
        _.has(showUserRequest, "symbol.fields.file")
      ) {
        let { url } = showUserRequest.symbol.fields.file;
        userRequesImage = url;
      }
      return (
        <tr key={_id}>
          <td
            className={`perf-video ${hoverState}`}
            onClick={() => this.displayVideo(vimeoId)}
            onMouseEnter={() => this.onMouseEnter(index)}
            onMouseLeave={() => this.onMouseLeave() }
            style={{ backgroundImage: `url(${thumbnail || fallbackImage} )` }}
          ></td>
          <td className="perf-name" onClick={() => this.displayVideo(vimeoId)}>
            <span className="video-title" valign="top">
              <p>{title}</p>
            </span>
          </td>
          <td className="perf-date" valign="top">{displayDate}</td>

          <td className="perf-request"
            onClick={() =>
              showRequests({
                mediaId: _id,
                requestsSelected: userRequests,
                mediaData: "videos",
              })
            }
          >
            {showUserRequest && (
              <span>
                <img
                  className={`${requestVisited} ${showUserRequest.type}`}
                  src={userRequesImage}
                />
              </span>
            )}
          </td>
        </tr>
      );
    });
  }
  render() {
    return (
      <div>
        <table id="performances-table" className="video-table">
          <tbody>
            <tr>
              <th></th>
              <th className="perf-nav-title">title</th>
              <th>date</th>

              <th className="perf-nav-user">
                User
                <br />
                Requests
              </th>
            </tr>
            {this.renderTableData()}
          </tbody>
        </table>
      </div>
    );
  }
}