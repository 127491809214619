import React, { Component } from 'react';

class PasswordField extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          hidden: true
        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
      }
    

    render() {
        const {hidden} = this.state
        const {handlePasswordChange, value, name, placeholder} = this.props
     
        return (
            <div className="password-wrapper">
               <input 
                    type={hidden? "password" : "text"}
                    id="password"
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e)=>handlePasswordChange(e)}
                    className="password-input"
                />
                <span onClick={this.toggleShow} className={hidden? 'hidden' : 'show'}/>
            </div>
        );
    }
}

export default PasswordField;