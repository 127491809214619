export default function sizeToFit(itemWidth, itemHeight, areaWidth, areaHeight) {
    let widthToHeight = itemHeight / itemWidth
    let heightToWidth = itemWidth / itemHeight

    let w = areaWidth, h = areaHeight
    let candidate_width = h * heightToWidth
    if (candidate_width > w) {
       //size to fit in width 
        let height = w * widthToHeight
        return {width: w, height}
    } else {
       //size to fit in height
       return {height: h, width: candidate_width}
    }
}
