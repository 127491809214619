import React, {Component} from "react";
import SplashScreen from "../splash-screen";
import Input from "../components/input";
import {
  checkCustomerNextProrationInvoice,
  fetchPlanData,
  getGiftCode,
  getSubscriptionStatusAndStreamingToken,
  getUserInfo, hasAuth,
  redeemCode
} from "../services/api";
import {getPlanInfo} from "../plans";

export default class ClaimGift extends Component {
  constructor(props, ctx) {
    super(props, ctx);
    this.loading = this.loading.bind(this)
    this.giftcodeOnChange = this.giftcodeOnChange.bind(this)
    this.loadGiftInformation = this.loadGiftInformation.bind(this)
    this.renderInitial = this.renderInitial.bind(this)
    this.renderConfirmation = this.renderConfirmation.bind(this)
    this.backToInitial = this.backToInitial.bind(this)
    this.initiateRedemption = this.initiateRedemption.bind(this)

    const step = 'initial';
    const userInfo = getUserInfo();
    const router = this.props.router;
    const queryCode = router.location.query.code;

    this.state = {
      giftcode: '' || queryCode,
      loading: false,
      step,
      giftCodeInfo: {},
      planData: {},
      nextCharge: {},
      userInfo,
      error: ''
    }

    this.planNames = {
      "NYA-UNLIMITED-MONTHLY" : "NYA CLASSIC",
      "NYA-UNLIMITED-YEARLY" : "NYA CLASSIC",
      "NYA-UNLIMITEDV2-MONTHLY" : "NYA CLASSIC",
      "NYA-UNLIMITEDV2-YEARLY" : "NYA CLASSIC",
      "NYA-UNLIMITED-YEARLY-RUST" : "NYA RUST",
      "NYA-UNLIMITEDV2-YEARLY-RUST" : "NYA RUST",
      "NYA-UNLIMITED-YEARLY-PATRON" : "NYA PATRON",
    };

    if (queryCode) {
      this.loadGiftInformation()
    }
  }

  backToInitial() {
    this.setState({...this.state, step:'initial'})
  }

  giftcodeOnChange(value) {
    this.setState({...this.state, giftcode: value, error: ''})
  }

  checkIfUpgradeOrDowngrade(currentPlanName, newPlanName) {
    let action = "upgrade";
    if (
      (currentPlanName == "NYA RUST" && newPlanName == "NYA CLASSIC") ||
      (currentPlanName == "NYA PATRON" && (newPlanName == "NYA CLASSIC" || newPlanName == "NYA RUST"))
    ) {
      action = "downgrade";
    }
    return action;
  }

  async loadGiftInformation() {
    const giftcode = this.state.giftcode || ''
    const _hasAuth = hasAuth()
    if (!_hasAuth) {
      window.displayWarningMessage('sign-in')
      return
    }
    if (giftcode === '')
      return

    this.setState({...this.state, loading: true, error: ''})
    try {
      const giftCodeInfo = await getGiftCode(giftcode);
      const planData = await fetchPlanData();
      const {subscription: subsInfo} = await getSubscriptionStatusAndStreamingToken()

      const planId = giftCodeInfo.planId || ''
      const currentPlanId = this.props.purchasedPlan.product_id
      const planTitle = this.planNames[planId]
      const currentPlanTitle = this.planNames[currentPlanId] || '';

      const action = this.checkIfUpgradeOrDowngrade(currentPlanTitle, planTitle)

      if (action === 'downgrade') {
        this.setState({...this.state, error: `You cannot change to ${planTitle} plan at this time. You have a ${currentPlanTitle} subscription`, step: 'initial', loading: false})
        return
      }

      if ((currentPlanId || '').includes("PATRON")) {
        this.setState({...this.state, error: 'You already have a Patron subscription', step: 'initial', loading: false})
        return
      }

      if (currentPlanId === giftCodeInfo.planId && subsInfo.active) {
        this.setState({...this.state, error: 'You already have this subscription plan', step: 'initial', loading: false})
        return
      }

      this.setState({...this.state, giftCodeInfo, planData, step: 'confirmation'})
    } catch (err) {
      this.setState({...this.state, error: 'Gift code is invalid or is already claimed!'})
    }
    this.setState({...this.state, loading: false})
  }

  async initiateRedemption () {
    this.setState({...this.state, loading: true, error: ''})

    redeemCode(this.state.giftcode).then(() => {
      window.history.replaceState({}, "", "/account?screen=overview");
    }).catch((e) => {
      console.log(e)
      this.setState({...this.state, loading: false, step: 'initial', error: e})
    });
  }

  loading() {
    return (
      <div className="panel claim-panel">
        <div className="content initial spinner">
          <div style={{"marginBottom": "20px"}}>
            Loading...
          </div>
          <SplashScreen loadState={50}/>
        </div>
      </div>
    )
  }

  renderInitial() {
    const {error} = this.state
    return (
      <div className="claim-panel panel">
        <div className="title">Claim Gift</div>
        <div className="content">
          <div className="gift-message">
            Claim your gift to unlock exclusive benefits!
          </div>
          <div className="gift-form">
            <Input
              className="input--giftcode input"
              placeholder="Enter Gift Code"
              limit={8}
              value={this.state.giftcode}
              onChange={this.giftcodeOnChange}
              name="code"
            />
          </div>
          {error && <div className="error">{error}</div>}

          <div className="description-section">
            <div className="confirmation-buttons">
              <div className="confirm-purchase" onClick={this.loadGiftInformation}>
                CLAIM GIFT
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderConfirmation() {
    const { giftCodeInfo, planData, userInfo } = this.state
    const planId = giftCodeInfo.planId
    const currentPlanId = this.props.purchasedPlan.product_id
    const planTitle = this.planNames[planId]
    const currentPlanTitle = this.planNames[currentPlanId] || '';
    const subscriptionType = `${planTitle} - YEARLY`

    const action = this.checkIfUpgradeOrDowngrade(currentPlanTitle, planTitle)
    const planPrice = planData.planPrices.find((price) => price.product_id === planId)
    const { renewalDate, interval } = this.props.purchasedPlan;

    return (
      <div className="claim-panel panel">
        <div className="title">Claim Gift</div>
        <div className="content">
          <div className="gift-message">
            Gift Details:
            <div className="gift-details">
              <h2>{subscriptionType}</h2>
              <div className="gift-description-text">
                <p>{'$ ' + planPrice.price} - {'1 ' + planPrice.interval}</p>

                <p>Code: {this.state.giftcode}</p>
              </div>
            </div>
          </div>
          <div className="description-section">
            <div className="confirmation-message padding40">
              <h3 className="confirmation-title"> What you need to know:</h3>
              {action === 'upgrade' ?(
                <div className="explanation">
                  <p>Your subscription will be upgraded to{" "}
                    {planTitle.toUpperCase()}</p>
                </div>
              ) : null}
            </div>
            <div className="confirmation-buttons">
              <div className="confirm-purchase" onClick={this.initiateRedemption}>
                CONFIRM
              </div>
              <div className="back-purchase" onClick={this.backToInitial}>
                BACK
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {loading, step} = this.state
    if (loading)
      return this.loading()

    switch (step) {
      case 'initial':
        return this.renderInitial()
      case 'confirmation':
        return this.renderConfirmation()
      default:
        return this.renderInitial()
    }
  }
}
