import React, {Component} from 'react';
import Hanger             from './hanger';
import cn                 from 'classnames'

export default class EmptyFolder extends Component {
    render(){
        let cx = cn({'empty-folder':true})
        return (
            <div className={cx}>
              <Hanger folderClass={this.props.folder} showSides={false} />
            </div>
        )
    }
}
