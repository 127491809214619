import React, {Component} from 'react'
import _                  from 'lodash'
import cn                 from 'classnames'
import {inverseScale}     from '../scale'

let minYear = 1950
let maxYear = 2029

function shorten(y){
    return y.toString().slice(2, 4)
}

let years = []
{
    for (let i = minYear; i <= maxYear; i++) {
        years.push({year: i, short:shorten(i)})
    }

}

function yearToIndex(year) {
    return year - minYear
}
function indexToYear(idx) {
    return minYear + idx
}

let itemHeight = 36 /* must match css */
function offsetForIndex(idx){
    return -(itemHeight * idx)
}

function yearForOffset(offs){

    let idx  = Math.floor(-offs / itemHeight) + 1
    let year = Math.max(minYear, Math.min(indexToYear(idx), maxYear))

    return year
}

function end(event){
    if (event.stopPropagation()) event.stopPropagation()
    if (event.preventDefault()) event.preventDefault()
    return false;
}

export default class YearsDisplay extends Component {
    constructor(props){
        super(props)

        this.state = {dragging: false}

        this.onClick    = this.onClick.bind(this)
        this.mouseDown  = this.mouseDown.bind(this)
        this.mouseMove  = this.mouseMove.bind(this)
        this.mouseUp    = this.mouseUp.bind(this)
        this.touchStart = this.touchStart.bind(this)
        this.touchMove  = this.touchMove.bind(this)
        this.touchEnd   = this.touchEnd.bind(this)
    }
    _mouseMap(){
        return {mousemove: this.mouseMove, mouseup:this.mouseUp}
    }
    _touchMap(){
        return {touchmove:this.touchMove, touchend:this.touchEnd}
    }
    _addHandlers(map){
        _.each(map, (value, key)=>{
            document.addEventListener(key, value, false)
        })
    }
    _removeHandlers(map){
        _.each(map, (value, key)=>{
            document.removeEventListener(key, value, false)
        })
    }
    onClick(e){
        if (this._didDrag) return

        let year = parseInt(e.target.getAttribute('data-year'), 10)
        this.props.onClick(year)
    }
    mouseDown(e){
        this._didDrag = false

        this._addHandlers(this._mouseMap())

        let offset = this.calcOffs()

        this._initialY      = e.clientY
        this._initialOffset = offset

        return end(e)
    }
    mouseMove(e){
        if (!this._didDrag) {
            let offset = this._initialOffset
            this.setState({dragging:true, offset})
            this._didDrag = true
        } else {
            let y = (e.clientY - this._initialY) * inverseScale()
            this.setState({offset:this._initialOffset + y})
        }
        return end(e)
    }
    mouseUp(e){
        this._removeHandlers(this._mouseMap())

        let year = this.props.boundYear(yearForOffset(this.state.offset))

        if (this._didDrag) {
            this.setState({dragging: false, animating:true, targetYear: year}, ()=>{
                this.props.onClick(year, ()=>{
                    this.setState({animating:false})
                })
            })
        }

        return end(e)
    }
    touchStart(e){
        if (e.touches.length > 1) return
        this._didDrag = false

        this._addHandlers(this._touchMap())

        let offset = this.calcOffs()

        this._initialY      = e.touches[0].clientY
        this._initialOffset = offset

        return end(e)
    }
    touchMove(e){
        if (e.touches.length > 1) return
        if (!this._didDrag) {
            let offset = this._initialOffset
            this.setState({dragging:true, offset})
            this._didDrag = true
        } else {
            let y = (e.touches[0].clientY - this._initialY) * inverseScale()
            this.setState({offset:this._initialOffset + y})
        }
        return end(e)
    }
    touchEnd(e){
        if (e.touches.length > 1) return
        this._removeHandlers(this._touchMap())

        let year = this.props.boundYear(yearForOffset(this.state.offset))

        if (this._didDrag) {
            this.setState({dragging: false, animating:true, targetYear: year}, ()=>{
                this.props.onClick(year, ()=>{
                    this.setState({animating:false})
                })
            })
        }

        return end(e)
    }
    shouldComponentUpdate(newprops, newstate){
        return (newprops.year !== this.props.year) || !_.isEqual(this.state, newstate)
    }
    calcOffs(year=this.props.year){
        return offsetForIndex(yearToIndex(year))
    }
    render(){
        let {dragging, offset, animating, targetYear } = this.state
        let {year} = this.props

        let top;
        if (dragging)       top = offset;
        else if (animating) top = this.calcOffs(targetYear);
        else                top = this.calcOffs(this.props.year)

        let style = {top: `${top}px`}

        let cx = cn('years', {dragging})

        return (
            <div className="years-display">
              <div className="base" />
              <div className="years-display-clip">
                <div className={cx} style={style} ref="years"
                     onMouseDown={this.mouseDown}
                     onTouchStart={this.touchStart}
                >
                  {years.map((y)=>{
                       let cx = "year"
                       if (y.year === year) cx+=" current"
                       return <div key={y.year} className={cx}
                                   data-year={y.year}
                                   onClick={this.onClick} />
                   })}
                </div>
                <div className="shader"/>
              </div>
              <div className="years-view-frame"></div>
            </div>
        )
    }
}
