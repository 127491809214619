
export const calcPositionInYear = ({month, day}) => {
    return (((1/12) * (month-1)) + ((1/31) * day * (1/12))) * 100
}

export const calcPositionInMonth = ({day}) => {
    return ((1/31) * day) * 100
}

export const compareDates = (date1, date2) => {
    return date1.year === date2.year && date1.month === date2.month && date1.day === date2.day
}

export const mergeTourPerformances = (tour) => {
    if (tour.performances.length === 0) {return [];} // early-out
    return tour.performances.reduce((acc, performance, index, allPerformances) => {
        /*
         MergedPerformance: {
             startDate: { year, month, day },
             endDate: { year, month, day },
             list: [Requestable<Performance>], 
         }
        */

        if (index > 0 && allPerformances[index - 1].asset.merge) {
            acc[acc.length - 1].endDate = performance.asset.endDate;
            acc[acc.length - 1].list.push(performance);


            const prevPerformanceIsContinous = compareDates(performance.asset.startDate, allPerformances[index - 1].asset.endDate)
            const prevPerformanceIsSameDay = compareDates(performance.asset.startDate, allPerformances[index - 1].asset.startDate)
            if (!prevPerformanceIsContinous && !prevPerformanceIsSameDay) {
                acc[acc.length - 1].isContinous = false
            }
        } else {
            acc.push({startDate: performance.asset.startDate, endDate: performance.asset.endDate, list: [performance], isContinous: true})
        }
        // if (index > 0 && !compareDates(performance.asset.startDate, allPerformances[index - 1].asset.endDate) && !compareDates(performance.asset.startDate, allPerformances[index - 1].asset.endDate) ) {
        //     // they're together so we merge them with the last performance.
        //     tour.isContinous = false;
        // }
        return acc;
    },[]);
}


export const calcCenterInYears = (startDate, endDate) => {

    const initialDate = new Date(startDate.year, startDate.month - 1, startDate.day);
    const finalDate = new Date(endDate.year, endDate.month - 1, endDate.day);
    const diff = finalDate.getTime() - initialDate.getTime();
    let midpoint = new Date(initialDate.getTime() + diff / 2);

    
    return {year: midpoint.getFullYear(), month: midpoint.getMonth() + 1, day: midpoint.getDate()}
} 