import React from 'react';
import { connect } from 'react-redux';
import SplashScreen from './splash-screen';
import FolderBrowser from './components/folder-browser'

class Container extends FolderBrowser {
    contentType(){
        return 'press'
    }
    selectedTab(){
        return 'press'
    }
    extractPhotosFromTrack(track){
        return track.press
    }
}

const mapStateToProps = function (state) {
    if (state.status !== "drawerLoaded") {
        return {
            loaded: false
        }
    }
  
    return { loaded: true};
};

const ContainerWrapper = (props) => {
    if (!props.loaded) return <div className="info-card-section">
            <SplashScreen loadState={100} />
        </div>;
    return <Container {...props}/>
  }
  
  export default connect(mapStateToProps)(ContainerWrapper);