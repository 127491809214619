import React, {Component}            from 'react'
import _                             from 'lodash'
import cn                            from 'classnames'
import { Formik, Form }              from 'formik'
import { Link }                      from 'react-router'
import PropTypes                     from 'prop-types'
import {
    getUserInfo,
    setUserInfo,
    deleteAccount,
    unlinkAccount,
    changeAccountSettings
}                                    from '../services/api';
import Auth                          from '../services/auth';
import {userLastPaymentMethod} from '../user';
import Input                         from '../components/input'
import Modal                         from '../components/modal'
import { connect }                   from 'react-redux'

class Ellipses extends Component {
    constructor(props, context){
        super(props, context)
        this.state = {count:1}
        this.update = this.update.bind(this)
    }
    componentDidMount(){
        this.interval = setInterval(this.update, 300)
    }
    componentWillUnmount(){
        clearInterval(this.interval)
    }
    update(){
        let count = (this.state.count % 4) + 1
        this.setState({count})
    }
    render(){
        let ellipses = '', hidden = ''
        for (let i = 0; i < 5; i++) {
            if (i < this.state.count)
                ellipses += '.'
            else
                hidden += '.'
        }
        return (
            <span className="ellipses">{ellipses}<span className="hidden">{hidden}</span></span>
        )
    }


}

class AccountInfo extends Component {
    constructor(props){
        super(props)

        this.state = {
            showUserModal: false,
            showEmailModal: false
        }
    }

    render(){
        const { userInfo } = this.props
        const isSSO = userInfo.user_id.indexOf('auth0') === -1
        let {user_metadata: metadata} = userInfo
        metadata = metadata || {}
        let firstname = metadata.customFirstname || userInfo.username || userInfo.given_name || userInfo.nickname || ''
        firstname = firstname.split(' ')[0]
        const email = metadata.customEmail || userInfo.email
        const {inline, NoEdit} = this.props
        const { showUserModal, showEmailModal } = this.state

        const onCloseModal = () => {
            showUserModal ? this.setState({showUserModal: false}) : this.setState({showEmailModal: false})
        }

        return (
            <div className={cn("account-info", {inline})}>
              {<div>
                <div className="label">Firstname</div>
                <div className="value">{firstname}</div>
                { !NoEdit && <div className="edit" onClick={() => { this.setState({showUserModal: true}) }}>Edit</div> }
              </div>}
              {email && <div>
                <div className="label">Email</div>
                <div className="value">{email}</div>
                { !NoEdit && <div className="edit" onClick={() => { this.setState({showEmailModal: true}) }}>Edit</div> }
              </div>}
              <Modal
                isOpen={showUserModal || showEmailModal}
                onRequestClose={onCloseModal}
              >
                  <Formik
                    initialValues={{ firstname: firstname, email: email }}
                    onSubmit={(values, actions) => {
                        let putNewSettings

                        if (showUserModal) {
                            putNewSettings = {
                                type: 'username_change',
                                values: {
                                    username: values.firstname
                                }
                            }
                        } else {
                            putNewSettings = {
                                type: 'email_change',
                                values: {
                                    email: values.email
                                }
                            }
                        }

                        changeAccountSettings(putNewSettings)
                            .then((result) => {
                                actions.setSubmitting(false)
                                if (showUserModal) {
                                    const newUserMetadata = {
                                        customFirstname: values.firstname,
                                        customEmail: userInfo.user_metadata && userInfo.user_metadata.customEmail
                                    }
                                    setUserInfo(Object.assign(userInfo, {user_metadata: newUserMetadata}))
                                } else {
                                    const newUserMetadata = {
                                        customFirstname: userInfo.user_metadata && userInfo.user_metadata.customFirstname,
                                        customEmail: values.email
                                    }
                                    setUserInfo(Object.assign(userInfo, {user_metadata: newUserMetadata}))
                                }
                                onCloseModal()
                            })
                            .catch((err) => {
                                actions.setSubmitting(false)
                                actions.setFieldError(showUserModal ? 'firstname' : 'email', 'Something went wrong')
                            })
                    }}
                    validate={(values, props) => {
                        let errors = {}

                        if (showUserModal) {
                            if (!values.firstname) {
                                errors.firstname = 'Required'
                            } else if (values.firstname.length >= 30) {
                                errors.firstname = 'You have exceeded max number of characters'
                            }
                        }

                        if (showEmailModal) {
                            if (!values.email) {
                                errors.email = 'Required'
                            } else if (values.email.length >= 40) {
                                errors.email = 'You have exceeded max number of characters'
                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                errors.email = 'Invalid email address'
                            }
                        }

                      return errors
                    }}
                    render={props => (
                        <Form className="content">
                          <Input
                            className='input'
                            label={showUserModal ? 'Edit firstname' : 'Edit email'}
                            limit={showUserModal ? 30 : 40}
                            isFormikField
                            mustRemoveSpacesFor='firstname'
                            name={showUserModal ? 'firstname' : 'email'}
                          />
                          <div className="buttons-wrapper">
                            <div className="button button--back" onClick={onCloseModal}>CANCEL</div>
                            <button
                                className={cn("button button--continue", {'disabled': props.isSubmitting})}
                                type="submit"
                            >SAVE</button>
                          </div>
                        </Form>
                        )}
                    />
              </Modal>
            </div>
        )
    }
}


/*
 *
 * state:
 *  initial -> confirm -> deleting -> success
 *     ^ ^       |          ^  |
 *     | +-------+          |  +----> error
 *     |                    |           |
 *     +--------------------+-----------+
 *
 */

class AccountDetails extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {state:'initial', userInfo: getUserInfo()}

        this.error = this.error.bind(this)
        this.unlink = this.unlink.bind(this)
        this.initial = this.initial.bind(this)
        this.confirm = this.confirm.bind(this)
        this.deleting = this.deleting.bind(this)
        this.unlinkSuccess = this.unlinkSuccess.bind(this)
        this.clickDeleteInitial = this.clickDeleteInitial.bind(this)
        this.clickUnlinkInitial = this.clickUnlinkInitial.bind(this)
        this.clickChangeInitial = this.clickChangeInitial.bind(this)
        this.clickUpdateInitial = this.clickUpdateInitial.bind(this)
        this.clickCancelConfirm = this.clickCancelConfirm.bind(this)
        this.clickDeleteConfirm = this.clickDeleteConfirm.bind(this)
        this.clickCancelError   = this.clickCancelError.bind(this)
        this.clickDeleteError   = this.clickDeleteError.bind(this)
        this.clickUpdateGift = this.clickUpdateGift.bind(this)
        this.maybeRefreshOrLogout = this.maybeRefreshOrLogout.bind(this)
    }
    componentWillUpdate(newprops, newstate){
        if (newstate.state !== this.state.state) {
            this.maybeRefreshOrLogout(newstate.state)
        }
    }
    maybeRefreshOrLogout(state){
        if (state === 'success' || state === 'unlinkSuccess'){
            clearTimeout(this.emit)
            this.emit = setTimeout(() => {
                if (state === 'success') {
                    Auth.logout();
                } else if (state === 'unlinkSuccess') {
                    Auth.logout('/account?view=overview&relogin=true');
                }
                else {
                    document.location.href = '/'
                }
            }, 1000)
        }
    }
    callDelete(){
        this.setState({state:'deleting'}, ()=>{
            deleteAccount()
                .then(()=>this.setState({state:'success'}))
                .catch(()=>this.setState({state:'error'}))
        })
    }
    getContent(){ return this[this.state.state]() }
    clickChangeInitial(){
        const {router} = this.context
        router.push(router.createLocation('/account?screen=plans'))
    }
    clickUpdateInitial(){
        const {router} = this.context
        router.push(router.createLocation('/account?screen=subscription'))
    }
    goToItunesStore() {
        window.open('https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions', '_blank');
    }
    clickUpdateGift(){
        const {router} = this.context
        router.push(router.createLocation('/account?screen=gift'))
    }
    initialPanelFree(){
        return (
              <div className="overview-panel right">
                <div className="fields-container">
                    <div className="plan-name">FREE</div>
                </div>
                <div className="button-wrapper-bottom gift-label">
                  <div className="button" onClick={this.clickChangeInitial}>CHANGE PLAN</div>
                </div>
              </div>
        )

    }
    _paidOverview(){
        const {isGift, relevantSubscriptionDate = '', customerCoupon = '', userSubscriptionStatus:state, proration_credit} = this.props.userData;
        const {price: planPrice = '0.00'}= this.props.purchasedPlan

        if (state === 'active' && customerCoupon === 'csh0fOx7') {
            return (
                <div className="plan-info">
                  You have a lifetime NYA Membership
                </div>
            )
        } else if (state === 'active' && isGift) {
            return (
                <div className="plan-info">
                  Your gift subscription will expire at the end of the current subscription period <b>{relevantSubscriptionDate}</b>.
                  <br/>
                 {(proration_credit && proration_credit !=='false')&&<p>Available credit : <b>${proration_credit}</b></p>}
                </div>
            )
        } else if (state === 'active') {
            return (
                <div>
                  Your plan will automatically renew on<br />
                  <b>{relevantSubscriptionDate}</b> and you will be charged<br />
                  ${planPrice}.<br/>
                </div>
            )
        } else if (state === 'cancelled-active') {
            return (
                <div>
                  You have cancelled your subscription;<br />
                  this cancellation will take effect <br />
                  at the end of the current subscription period <b>{relevantSubscriptionDate}</b>
                </div>
            )
        } else if (state === 'declined-active') {
            return (
                <div>
                  Your last payment failed,<br />
                  Your account will be cancelled on <b>{relevantSubscriptionDate}</b>
                </div>
            )
        // NOTE: this would never shown as we display NYA FREE copy for cancelled and inactivated subscriptions
        } else if (state === 'cancelled-inactive') {
            return (
                <div>
                  You have cancelled your subscription;<br />
                  this cancellation took effect: <b>{relevantSubscriptionDate}</b>
                </div>
            )
        }

        //'declined-inactive'
        return (
            <div>
                Your payment failed and your account<br />
                became inactive on <b>{relevantSubscriptionDate}</b>
            </div>
        )
    }
    initialPanelPaid(){
        const {last4, card, expirationDateText} = userLastPaymentMethod()
        const { userSubType} = this.props.userData
        const {planTitle}= this.props.purchasedPlan;
        const isSubscriptionTrial = this.props.userData.isSubscriptionTrial

        return (
              <div className="overview-panel right">
                <div className="fields-container">
                    <div className="plan-name">{planTitle}</div>
                    {isSubscriptionTrial
                        ? <div className="free-trial-badge">
                          FREE TRIAL
                        </div>
                        : null}
                    <div className="plan-desc">
                    {this._paidOverview()}
                    </div>
                    { last4 !== '0000' &&
                        <div className="plan-payment-method">
                            <div className="payment-method-title">Payment method</div>
                            <b>{card} **** **** ****{last4}</b><br />
                            <b>Expires {expirationDateText}</b>
                        </div>
                    }
                    { userSubType === 'apple' &&
                        <div className="plan-payment-method">
                            <div className="payment-method-title">Payment method</div>
                            <b>APPLE SUBSCRIPTION</b>
                        </div>
                    }
                </div>
                {!isSubscriptionTrial &&
                  < div className="button-wrapper-bottom gift-label">
                    <div className="button clear" onClick={userSubType === 'apple' ? this.goToItunesStore : this.clickUpdateInitial}>UPDATE</div>
                  </div>
                }
              </div>
        )
    }
    initial(){
        const {userHasSubscriptionInfo,userSubscriptionStatus:state, userSubType, userIsFree}= this.props.userData
        const rightPanel = userHasSubscriptionInfo && state !== 'cancelled-inactive' ?
                           this.initialPanelPaid()
                         : this.initialPanelFree()

        return (
            <div className="content">
              <div className="overview-panel left">
                <AccountInfo userInfo={this.state.userInfo} inline={true} />
                    <div className="button-wrapper-bottom">
                        <div
                            className="button clear"
                            onClick={userSubType === 'apple' && !userIsFree ? window.appleSubWarning : this.clickDeleteInitial}
                        >DELETE MY ACCOUNT</div>
                    </div>
              </div>
              {rightPanel}
            </div>
        )
    }
    clickDeleteInitial(){
        this.setState({state:'confirm'})
    }
    clickUnlinkInitial(){
        this.setState({state:'unlink'})
    }
    confirm(){
        return (
            <div className="content content--confirmDelete">
              <AccountInfo userInfo={this.state.userInfo} NoEdit={true} />
              <div className="message">
              Are you sure you want to delete your account?<br />
              </div>
              <div className="buttons">
                <div className="button clear" onClick={this.clickCancelConfirm}>CANCEL</div>
                <div className="button" onClick={this.clickDeleteConfirm}>DELETE MY ACCOUNT</div>
              </div>
            </div>
        )
    }

    unlink(){
        const { state } = this.state
        // don't let user to unlink after they delete their account
        if (state === 'success') return null

        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
        const { userInfo } = this.state
        let {user_metadata: metadata} = userInfo
        metadata = metadata || {}
        let firstname = metadata.customFirstname || userInfo.username || userInfo.given_name || userInfo.nickname
        firstname = firstname.split(' ')[0]

        return (
            <Formik
                initialValues={{ username: firstname, email: '', password: '' }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                    unlinkAccount(values.email, values.username, values.password)
                        .then((result) => {
                            actions.setSubmitting(false)
                            this.setState({
                                state: 'unlinkSuccess',
                                unlinkSuccessProps: {
                                    email: values.email,
                                    username: values.username
                                }
                            })
                        })
                        .catch((err) => {
                            actions.setSubmitting(false)
                            if (err.status === 406) {
                                actions.setFieldError('email', 'This email is already in use')

                            } else {
                                actions.setFieldError('email', 'Something went wrong')
                            }
                        })
                }}
                validate={(values, props) => {
                    let errors = {}

                    if (!values.username) {
                        errors.username = 'Required'
                    } else if (values.username.length >= 30) {
                        errors.username = 'You have exceeded max number of characters'
                    }

                    if (!values.email) {
                        errors.email = 'Required'
                    } else if (values.email.length >= 40) {
                        errors.email = 'You have exceeded max number of characters'
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = 'Invalid email address'
                    }

                    if (!values.password) {
                        errors.password = 'Required'
                    } else if (values.password.length >= 30) {
                        errors.password = 'You have exceeded max number of characters'
                    }

                  return errors
                }}
                render={props => (
                    <Form className="content content--unlink">
                        <div className='fields-wrapper'>
                            <Input
                                className='input'
                                label='Firstname'
                                limit={30}
                                isFormikField
                                mustRemoveSpacesFor='username'
                                name='username'
                            />
                            <Input
                                className='input'
                                label='Email'
                                limit={40}
                                isFormikField
                                name='email'
                            />
                            <Input
                                className='input'
                                label='Password'
                                limit={30}
                                isFormikField
                                name='password'
                                type='password'
                            />
                        </div>
                        <p>You are about to unlink your Facebook/Google account.</p>
                        <div className="buttons-wrapper">
                            <button className="button clear button--back" onClick={this.clickCancelConfirm}>CANCEL</button>
                            <button
                                className={cn("button button--continue", {'disabled': props.isSubmitting})}
                                type="submit"
                            >SUBMIT</button>
                        </div>
                    </Form>
                    )}
                />
        )
    }
    unlinkSuccess(){
        const { unlinkSuccessProps } = this.state
        const name  = unlinkSuccessProps.username
        const email = unlinkSuccessProps.email
        return (
            <div className="content">
                <div className="account-info">
                  {name && <div>
                    <div className="label">Firstname</div>
                    <div className="value">{name}</div>
                  </div>}
                  {email && <div>
                    <div className="label">Email</div>
                    <div className="value">{email}</div>
                  </div>}
                </div>
              <div className="message">
                You have unlinked NYA from your Facebook/Google account. <br />From now on please log in with your email and password.
              </div>
            </div>
        )
    }
    clickCancelConfirm(){
        this.setState({state:'initial'})
    }
    clickDeleteConfirm(){
        this.callDelete()
    }
    deleting(){
        return (
            <div className="content">
              <AccountInfo userInfo={this.state.userInfo} />
              <div className="message message--deleting">
              Deleting<Ellipses />
              </div>
            </div>
        )
    }
    success(){
        return (
            <div className="content">
              <div className="message">
                Your Account has been deleted.<br /><br />
                <span className="bold">Redirecting<Ellipses /></span>
              </div>
            </div>
        )
    }
    error(){
        return (
            <div className="content content--column">
              <AccountInfo userInfo={this.state.userInfo} />
              <div className="message">
              Sorry! We were unable to delete your account.<br />
              Please try again.
              </div>
              <div className="buttons">
                <div className="button clear" onClick={this.clickCancelError}>CANCEL</div>
                <div className="button" onClick={this.clickDeleteError}>DELETE MY ACCOUNT</div>
              </div>
            </div>
        )
    }
    clickCancelError(){
        this.setState({state:'initial'})
    }
    clickDeleteError(){
        this.callDelete()
    }
    render() {
        return (
            <div className="account-overview-panel panel">
              <div className="title">Account Overview</div>
              {this.getContent()}
            </div>
        )
    }
}

AccountDetails.contextTypes = {
    router: PropTypes.object.isRequired
}

class OverviewPanel extends Component {
    render() {
        return (
            <AccountDetails userData={this.props.userData} purchasedPlan={this.props.purchasedPlan} />
        )
    }
}

const mapStateToProps = function (state) {
    return { userData: state.userData};
};
export default connect(mapStateToProps)(OverviewPanel);
