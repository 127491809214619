import React, { useState } from 'react';
import { connect } from 'react-redux';
import { formatDate } from '../../news/date-utils';
import PropTypes from 'prop-types';
import EventBusPublisher from '../event-bus-publisher';
import { parseOnlyNumbers } from '../../util/strings';
import useDeviceType from '../../util/useDeviceType';

export function NewspaperMidline(props) {
 const volume = props.data.volume;
 const today = formatDate(new Date());
 const router = props.router;
 const page = props.page;
 const pages = props.pagesData;
 const isFixed = props.fixed;
 const isArticleView = props.isArticleView;
 const {isMobile} = useDeviceType()

 const maxPage = pages.length;
 const pageIndex = pages.findIndex((thePage) => {
  return thePage.order === page;
 });

 const canGoBack = () => {
  return pageIndex + 1 > 1;
 };

 const canGoForward = () => {
  return pageIndex + 1 < maxPage;
 };

 const goToPreviousPageInHistory = () => {
  window.history.back();
 }

 const goBack = () => {
  const _prevPageIndex = pageIndex - 1;
  const _pageTitle = pages[_prevPageIndex].title;
  const _prevPageNumber = _pageTitle.includes('page')
   ? parseOnlyNumbers(_pageTitle)
   : _pageTitle || 1;
  router.push(`/times-contrarian/${_prevPageNumber}`);
  EventBusPublisher.changePage(_prevPageNumber);
 };

 const goForward = () => {
  const _nextPageIndex = pageIndex + 1;
  const _pageTitle = pages[_nextPageIndex].title;
  const _nextPageNumber = _pageTitle.includes('page')
   ? parseOnlyNumbers(_pageTitle)
   : _pageTitle || 1;
  router.push(`/times-contrarian/${_nextPageNumber}`);
  EventBusPublisher.changePage(_nextPageNumber);
 };


 return (
  <div className={`middleline line ${isFixed ? 'fixed' : ''}`}>
   <div
    className={`swipe-button left ${(canGoBack() || isArticleView) ? 'visible' : 'invisible'}`}
    onClick={() => {
     (isArticleView && isMobile) ? goToPreviousPageInHistory() : goBack();
    }}
   ></div>
   <div className="left-side">
    <p>{volume}</p>
   </div>
   <div className="center">{today}</div>
   <div className="right-side">
    <div className="box mobile-hidden">
     {canGoBack() ? (
      <div className="button" onClick={goBack}>
       PREVIOUS
      </div>
     ) : null}
    </div>
    <div className="box index">
     {pageIndex + 1} of {maxPage}
    </div>
    <div className="box mobile-hidden">
     {canGoForward() ? (
      <div className="button" onClick={goForward}>
       NEXT
      </div>
     ) : null}
    </div>
   </div>
   {isArticleView && isMobile ? null : <div
    className={`swipe-button right ${canGoForward() ? 'visible' : 'invisible'}`}
    onClick={() => {
     goForward();
    }}
   ></div>}
  </div>
 );
}

NewspaperMidline.contextTypes = {
 router: PropTypes.object,
 page: PropTypes.number,
};

const mapStateToProps = (state) => {
 let pagesData = state?.contrarianPage?.all || [];
 pagesData = pagesData.sort((a, b) => a.order - b.order);
 return {
  data: state?.newsPage?.page || '',
  pagesData: pagesData,
 };
};

export default connect(mapStateToProps, {})(NewspaperMidline);
