import React, {Component} from 'react';
import _                  from 'lodash';
import cn                 from 'classnames';
import TitleTab           from './title-tab'
import PropTypes          from 'prop-types'

class Tab extends Component {
    render(){
        let {selected, title, id, onClick, position, hidden, disabled} = this.props
        let cx = cn({tab:true, selected, title, disabled, [id]:true, [position]:true})
        let style = {visibility: hidden ? 'hidden':'visible'}
        return <div key={id} className={cx} style={style}>
          <div onClick={onClick} data-id={id} className="image"></div>
        </div>
    }
}

let tabs=[
    {id: 'photos',      url:'/info-card/photos' },
    {id: 'documents',   url:'/info-card/documents' },
    {id: 'press',       url:'/info-card/press' },
    {id: 'memorabilia', url:'/info-card/memorabilia' }
]

export default class FolderTabs extends Component {
    constructor(props, b){
        super(props, b)

        this.onTabClick = this.onTabClick.bind(this)
    }
    goto(url){
        let router = this.context.router

        let pathname = url
        let query = {track: this.props.track.id }

        let location = router.createLocation({pathname, query})

        delete location.search

        router.push(location)
    }
    onTabClick(e){
        let id = e.target.getAttribute('data-id')
        
        let tab = _.find(tabs, {id})
        this.goto(tab.url)
    }
    getTabs(selected, position){
        let {track, disabled} = this.props
        return tabs.map(({id})=>{

            //hidden if there is an empty array on the track for this id
            // -- means that is has been loaded, but there's nothing there
            let hidden = track[id] && !track[id].length

            return <Tab onClick={disabled ? window.subs : this.onTabClick}
                        disabled={disabled}
                        hidden={hidden}
                        key={id} id={id} position={position}
                        selected={id === selected} title={false} />
        })
    }
    render(){
        let {selected, track} = this.props
        let {position}                  = track

        let tabs = this.getTabs(selected, position)

        let titleTab = (<Tab key="title"
                             position={position}
                             onClick={this.onTabClick} id="title"
                             selected={selected==='title'} title={true} />)

        if      (position === 'left')   tabs.unshift(titleTab)
        else if (position === 'right')  tabs.push(titleTab)
        else                            tabs.splice(2,0,titleTab)
        
        let left  = position === 'left',
            right = position === 'right';

        let cx = cn({'folder-tabs':true, left, right})

        let tabcontainer = cn({'title-tab-container':true, left, right})

        return (
            <div className={cx}>
              {tabs}
              <div className={tabcontainer}>
                <TitleTab item={track} index={-1} onClick={()=>{this.goto('/info-card')}} />
              </div>
            </div>
        )
    }
}

FolderTabs.contextTypes = {
    router: PropTypes.object.isRequired
}
