import _ from 'lodash'
import { ENTRIES_LOADED } from '../constants';

const initialState = {byYear: {}, byId: {}, all:[]}

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.tourZI) {
                let byYear = _.clone(initialState.byYear)
                let byId = _.clone(initialState.byId)
                let all = initialState.all.slice()
                Object.keys(action.entries.tourZI).forEach(k=>{
                    let item = action.entries.tourZI[k]
                    let year = item.startDate.year
                    let month = item.startDate.month

                    let y = year, m = month
                    for (var i = 0; i < item.tiles.length; i++, m++) {
                        if (m > 12) {
                            m = m - 12
                            y = y + 1
                        }
                        byYear[y]    = byYear[y] || {}
                        byYear[y][m] = byYear[y][m] || []
                        byYear[y][m].push(item)
                        byId[item.id] = item
                        all.push(item)

                    }

                })
                return {byYear, byId, all}
            }
            break;
    }
    return state;
}
