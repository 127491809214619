export default (str) => {
  try {
    const list = ['/#/', '#/', '#']
    const hash = list.find(mix => str.indexOf(mix) === 0)
    return str.replace(hash, '/')
  } catch (error) {
    console.warn(error)
    return str
  }
}
