import { ENTRIES_LOADED } from '../constants';

export default function update(state =[], action) {
  const {type, entries} = action

  switch (type) {
    case ENTRIES_LOADED:
      if (entries.movieFilmCategory) {
        entries.movieFilmCategory.sort((a,b)=>parseFloat(a.categoryOrder - b.categoryOrder))
      return entries.movieFilmCategory
      }
      break;
  }
  return state;
}