import React, {Component} from 'react'
import VerticalCenter     from './components/vertical-center'
import errorImg from '../images/404/404_error.png'
import {resetMetadata} from './actions/metas'


export default class Page404 extends Component {
    componentWillMount(){
        resetMetadata()
    }
    render(){
        return (
            <VerticalCenter className="page-404">
              <img src={errorImg} />
            </VerticalCenter>
        )
    }
}
