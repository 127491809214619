import _ from 'lodash'
import {
  ENTRIES_LOADED,
  SET_PPV_DATA,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_FEATURED,
  SET_PPV_MOVIE,
  SET_PURCHASE_STATE,
} from "../constants";

export default function update(state ={ ppvCategories:[], selectedIndex:null, selectedFeaturedIndex:null, purchasedStatusUpdated:false, moviePurchased:[] }, action) {
  const {type, entries, data} = action
  switch (type) {
    case ENTRIES_LOADED:
      if (entries.ppvCategory) {
        let result = entries.ppvCategory;
        Object.keys(entries.ppvCategory).forEach(k => {
            let ppvCategories = entries.ppvCategory[k]

            if (ppvCategories && ppvCategories.id) {
                result.byId[ppvCategories.id] = ppvCategories
                result.all.push(ppvCategories)
            }
        })
        return result;
      }
      break;
    case SET_PPV_DATA:
      return {
        ...state,
        ppvCategories: action.data.categories,
        featured: action.data.featured
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedIndex: data
      }

    case SET_SELECTED_FEATURED:
      return {
        ...state,
        selectedFeaturedIndex: data
      }
    
      case SET_PPV_MOVIE:
       let result = _.cloneDeep(state.ppvCategories)

       for(let i = 0; i < result.length; i++ ){
         let category = result[i]
         let index = category.videos.findIndex(video=> video.vimeoId == data.data.vimeoId )
         if(index !== -1){
          //equal to rental category
            if(category.title && category.title.includes('Rentals')){
              // remove rental array
              result[i].videos.splice(index,1)
            }else{
              result[i].videos[index] = data.data
            }
        }
       }

        return {
          ...state,
          ppvCategories: result
        }
      case SET_PURCHASE_STATE : 
      return {
        ...state,
        purchasedStatusUpdated: data.state,
        moviePurchased : data.purchasedMovie
      }
    }
  return state;
}