import {allYears} from './gen-years'

export let allMonths = []
export let monthsByYear = {}

let _id = 0

let colors    = ['#333', '#3b3b3b', '#222', '#2b2b2b']
function pick(array){
    return array[Math.floor(Math.random() * array.length)]
}

function pick_not(array, exclude){
    let chosen = pick(array)
    while (chosen === exclude) chosen = pick(array)
    return chosen
}

let color = pick(colors)

allYears.forEach(year => {
    let months = [
        {name: 'jan'},
        {name: 'feb'},
        {name: 'mar'},
        {name: 'apr'},
        {name: 'may'},
        {name: 'jun'},
        {name: 'jul'},
        {name: 'aug'},
        {name: 'sep'},
        {name: 'oct'},
        {name: 'nov'},
        {name: 'dec'}
    ]
    months.forEach((m, idx)=>{
        color = pick_not(colors, color)
        m.color       = color
        m.index       = idx
        m.monthNumber = idx + 1
        m.month       = m.name
        m.id          = `${_id++}`
        m.year        = year.year
    })
    allMonths = allMonths.concat(months)
    monthsByYear[year.year] = months
})
