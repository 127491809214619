import React, {Component, useEffect} from 'react'
import SectionPage        from '../components/section-page'
import marked             from 'marked'
import { resetMetadata } from '../actions/metas'

export default function ContentfulPageContainer (page)  {
  return Page(page)
}

const Page = page => props => {
  useEffect(() => {
    resetMetadata()
  }, []);

  return (
    <SectionPage className="audio-setup-page"
                 headerImageUrl={page.headerImage}
                 headerText = {page.headerText}
                 html={marked(page.content)}
                 shownInGettingStarted = {page.showInGettingStarted}
    />
  )
}
