import React            from 'react'
import HorizontalSlider from './horizontal-slider'


export default class InfoCardSlider extends HorizontalSlider {
    shouldComponentUpdate(newprops, newstate){
        return super.shouldComponentUpdate(newprops, newstate) ||
               newprops.date !== this.props.date ||
               newprops.dragging !== this.props.dragging ||
               newprops.disabled !== this.props.disabled
    }
    render(){
        return (
            <div className="info-card-slider-container">
              <div ref="line" className="horizontal-slider-line"></div>
              <div ref="thumb" style={this.getThumbStyle()}
                   onMouseDown={this.mouseDown} onTouchStart={this.touchStart}
                   className="horizontal-slider-thumb">
                <div className="thumb-image" />
              </div>
            </div>
        );
    }
}
