import React, {Component} from 'react'
import _                  from 'lodash'

function end(event){
    if (event.stopPropagation()) event.stopPropagation()
    if (event.preventDefault()) event.preventDefault()
    return false;
}

const motionTimeout = 500

export default class VerticalSlider extends Component {
    constructor(props,b){
        super(props,b);
        this.mouseDown  = this.mouseDown.bind(this)
        this.mouseMove  = this.mouseMove.bind(this)
        this.mouseUp    = this.mouseUp.bind(this)
        this.touchStart = this.touchStart.bind(this)
        this.touchMove  = this.touchMove.bind(this)
        this.touchEnd   = this.touchEnd.bind(this)
        this.moveStart  = this.moveStart.bind(this)
        this.moveEnd    = this.moveEnd.bind(this)

        this.pct = props.pct
        this.isMoving = false
    }
    stopTrackingDeltas(){
        clearInterval(this._speedInterval)
    }
    startTrackingDeltas(){
        this.stopTrackingDeltas()
        this._lastPct = this.pct
        this._speedInterval = setInterval(()=>{
            let delta = Math.abs(this.pct - this._lastPct)
            this._lastPct = this.pct
            this.props.onDelta && this.props.onDelta(delta)
        }, 500)
    }
    componentWillUnmount(){
        this.stopTrackingDeltas()
    }
    _mouseMap(){
        return {mousemove: this.mouseMove, mouseup:this.mouseUp}
    }
    _touchMap(){
        return {touchmove:this.touchMove, touchend:this.touchEnd}
    }
    _addHandlers(map){
        _.each(map, (value, key)=>{
            document.addEventListener(key, value, false)
        })
    }
    _removeHandlers(map){
        _.each(map, (value, key)=>{
            document.removeEventListener(key, value, false)
        })
    }
    _getRect(){
        //can't cache it unless we listen for resize events
        return this.refs.line.getBoundingClientRect();
    }
    calcPct(rawY){
        let rect = this._getRect()
        let y    = rawY - this._initialOffset

        let min = rect.top
        let max = min + rect.height

        if (y < min) return 0
        if (y > max) return 100

        return ((y - min) / rect.height) * 100
    }
    calcClient(pct){
        let rect = this._getRect()
        return rect.top + (rect.height * (pct / 100))
    }
    moveStart(){
        if (this.isMoving) return
        this.isMoving = true
        this.props.moveStart && this.props.moveStart()
        this.startTrackingDeltas()
    }
    moveEnd(){
        if (!this.isMoving) return
        this.isMoving = false
        this.props.moveEnd && this.props.moveEnd()
        this.stopTrackingDeltas()
    }
    mouseDown(e){
        this._active = true
        this._addHandlers(this._mouseMap());
        this._initialOffset = e.clientY - this.calcClient(this.pct)

        this.props.dragStart && this.props.dragStart()

        return end(e)
    }
    mouseMove(e){
        let pct = this.calcPct(e.clientY)
        this.refs.thumb.style.top = pct + '%'
        this.pct = pct
        this.props.onScroll(pct)

        this.moveStart()
        if (this.endTimer) clearTimeout(this.endTimer)
        this.endTimer = setTimeout(this.moveEnd, motionTimeout)

        return end(e)
    }
    mouseUp(e){
        this._active = false
        this._removeHandlers(this._mouseMap())

        this.props.dragEnd && this.props.dragEnd()

        this.moveEnd()
        return end(e)
    }
    touchStart(e){
        if (e.touches.length > 1) return
        this._initialOffset = e.touches[0].clientY - this.calcClient(this.pct)
        this._addHandlers(this._touchMap())

        this.props.dragStart && this.props.dragStart()

        return end(e)
    }
    touchMove(e){
        if (e.touches.length > 1) return
        let y = e.touches[0].clientY
        this.props.onScroll(this.calcPct(y))

        this.moveStart()
        if (this.endTimer) clearTimeout(this.endTimer)
        this.endTimer = setTimeout(this.moveEnd, motionTimeout)

        return end(e)
    }
    touchEnd(e){
        this._removeHandlers(this._touchMap())

        this.props.dragEnd && this.props.dragEnd()
        this.moveEnd()

        return end(e)
    }
    shouldComponentUpdate(newprops){
        if (this._active) return false

        this.refs.thumb.style.top = newprops.pct + '%'
        this.pct = newprops.pct
        return false;
    }
    render(){
        let thumbStyle = {
            top: `${this.props.pct}%`
        }
        return (<div className="vertical-slider-container">
                  <div ref="line" className="vertical-slider-line"></div>
                  <div ref="thumb" className="vertical-slider-thumb-wrapper" style={thumbStyle}>
                    <div ref="thumbImage" onMouseDown={this.mouseDown} onTouchStart={this.touchStart}
                         className="vertical-slider-thumb"></div>
                  </div>
                </div>);
    }
}
