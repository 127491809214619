import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import hasClass from "has-class";
import back from "../util/back";
import { resetMetadata } from "../actions/metas";
import _ from "lodash";

class ModalWrapper extends Component {
  constructor(props, b) {
    super(props, b);

    this.state = { };

    this.onBGClick = this.onBGClick.bind(this);
  
  }
  componentWillMount() {

    resetMetadata();
  }
  componentDidMount() {
    const {params, itinerary, location} = this.props;
    if(location && location.query){
      const {query} = location 
      let itineraryKey =  Object.keys(itinerary)
      //set an itinerary by default 
      if (!_.has(query, 'tourid')){ 
        this.clickRelated(`performance-log/tour?tourid=${itineraryKey[0]}`)
      }
    }
  }

  clickRelated(path) {
    let { router } = this.context;
    router.push(router.createLocation(path));
  }
  onBGClick(e) {
    let el = e.target;
    if (
      el === this.refs.background ||
      hasClass(el, "album-modal-contents-center") ||
      hasClass(el, "album-modal-contents-center-inner") ||
      hasClass(el, "background-mousefix-left") ||
      hasClass(el, "background-mousefix-right")
    ) {
      e.preventDefault();
      e.stopPropagation();
      back(this.context.router);
    }
  }
  

  renderContent() {
    return (
      <div
        ref="background"
        style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        id="generic"
        className="album-modal"
        onClick={this.onBGClick}
      >
        {this.props.children}
      </div>
    );
  }
  render() {
    return <div>{this.renderContent()}</div>;
  }
}

ModalWrapper.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  return { itinerary: state.tour.byContentfulId};
};

export default connect(mapStateToProps)(ModalWrapper);
