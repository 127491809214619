import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router'
import { isMobile } from '../../platform';
import {movieNightParser} from '../../drawer-items'
import {getHearseTheaterData} from '../../services/api'
import SplashScreen from '../../splash-screen'
import MovietoneLogo from '../movietoneLogo';

import MovieNightScreen from './screen'

const glowImg = 'https://s3.amazonaws.com/neilyoungarchives.com/assets/hearse-theather/glow.png';
const titleImg = 'https://s3.amazonaws.com/neilyoungarchives.com/assets/hearse-theather/title.png';
const carImg = 'https://s3.amazonaws.com/neilyoungarchives.com/assets/hearse-theather/hearse-car.png';

const MAX_SCREENS_AMOUNT = 4

const getScreens = (screens) => {
    return screens.map(screen => <MovieNightScreen screen={screen} key={screen.category.name}/>);
}

const MovieNightHome = ({ screens,loaded }) => {
    if (isMobile) {
        return <Redirect to="/movie-night" />;
    }
    useEffect(()=>{
        if(!loaded){
            getHearseTheaterData().then(data=>{
                movieNightParser(data)
            })
        }
    })

    if(!loaded){
        return(<div id="timeline"><SplashScreen loadState={100} /></div>)
    }else{
        return (
            <div className="movie-night-home" /* root */>
                <div className="glow-background-container">
                    <img className="glow-background-img" src={glowImg} alt ="Hearse Theather Glow" /* title image container *//>
                </div>
                <div className="screens-content"/* top content container */>                    
                    <div className="screen-list-container"/* screens container */>
                        <div className="screen-list-content"/* screens container */>
                            {getScreens(screens)}
                            <MovietoneLogo />
                        </div>
                    </div>
                </div>
                <div className="title-container">
                    <img className="title-img" src={titleImg} alt ="Hearse Theather" /* title image container *//>
                    <img className="hearse-car" src={carImg} alt ="Hearse Car" /* hearse *//>
                </div>
            </div>
        );
    }

};


const mapStateToProps = (state) => {
    const categories = state.movieNightCategory || [];
    const filmsByCategory = state.movieNightFilm.byCategoryName || {};
    let loaded = false
    
    if (state.movieNightFilm.movieNightFilmLoaded) {
        loaded = true
    }
    let screens = categories.reduce((acc, category) => {
        const films = filmsByCategory[category.name] || [];
        const featuredFilm = films.find(film => film.isFeatured);        
        acc.push({ category, films, featuredFilm });        
        return acc;
    }, []);
    return { screens: screens.slice(0, MAX_SCREENS_AMOUNT) ,loaded };
}

export default connect(mapStateToProps)(MovieNightHome);