import React, { Component } from "react";
import PropTypes from "prop-types";
import PlaylistManager from "../components/new-player/playlist-manager";
import Audio from "../components/new-player/audio";
import PlayerBtn from './player-btn'
import { connect } from "react-redux";

class ItineraryHeader extends Component {
  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      showPopup: false,
      showPlaylistPopup: false,
      showDotsPopup: false,
      showPlaylistDotsPopup: false,
      showEditTitleModal: false,
    };
    this.audioCallback = this.audioCallback.bind(this);
    this.playlistCallback = this.playlistCallback.bind(this);
  }
  componentDidMount() {
    Audio.addCallback(this.audioCallback);
    PlaylistManager.addListener(this.playlistCallback);
  }
  componentWillUnmount() {
    Audio.removeCallback(this.audioCallback);
    PlaylistManager.removeListener(this.playlistCallback);
  }

  audioCallback({ state: playerStatus }) {
      this.setState({ playerStatus });
  }
  playlistCallback({ track: currTrack }) {
    this.setState({ currTrack });
  }

  render() {
    let {
      itineratyData: { performer, tourTitle },
      dateText,
      venue,
      performanceTrack,
      tourMp3,
      tourOS,
      onTrackElementClick,
      mediaTag,
      free,
      venueLocation,
      venueCountry,
      isMp3Playing,
      isOsPlaying,
      audioPlaying = true,
    } = this.props;

    const currentTrack = this.state.currTrack
    const {trackId = '' } = performanceTrack || { }
    const currentlyPlaying = (currentTrack && currentTrack.trackId) == trackId;
    let style = { transform: `scale(${35 / 66})` };/* for scaling down the music player inline */
    return (
      <div className="header">
        <div className="fields">
          {performer && performer.name && (
            <span>
              <p>Performer: </p> {performer.name}
            </span> 
          )}
          <span>
            <p>Tour: </p> {tourTitle}
          </span>
          <span>
            <p>Dates: </p> {dateText}
          </span>
          {venue && (
            <span>
              <p>Venue: </p>{" "}
              {`${venue}${
                venueLocation ? ` - ${venueLocation}, ${venueCountry}` : ""
              }`}
            </span>
          )}
          {(tourOS || tourMp3) && mediaTag == "setList" && (
            <div
              className="performance-btn"
              onClick={() =>
                onTrackElementClick(
                  {
                    track: performanceTrack,
                    disabledbyId: false,
                    free,
                  },
                  this.state.playerStatus
                )
              }
            >
              {currentlyPlaying ? (
                <table className="performance-player">
                <tbody>
                <tr>
                <PlayerBtn
                  track={performanceTrack}
                  currentlyPlaying={
                    currentlyPlaying ? isMp3Playing || isOsPlaying : false
                  }
                  tour
                  isMp3Playing={audioPlaying}
                  disabledbyId={false}
                  style={style}
                  onTrackElementClick={this.onTrackElementClick}
                />
                </tr>
                </tbody>
                </table>
              ) : (
                <div className="btn-holder" />
              )}
              <p>PLAY THIS SHOW</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ItineraryHeader.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  return{
    audioPlaying : state.tracks.playing
  }
};

export default connect(mapStateToProps)(ItineraryHeader);