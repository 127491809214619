import React from 'react';

const Separator = ({ id, startDate, endDate, offset, calcPosition, onClick, tour }) => {

    const startPos = calcPosition(startDate);
    const endPos = calcPosition(endDate);

    const styleContainer = {left:`${startPos}%`, width: `calc(${endPos - startPos}% - ${offset})`}
    const styleTop = { top: 0 }
    const styleBottom = { bottom: 0 }

    const onUserClick = (event) => {
        onClick(event, tour)
    } 
    return (
        <div onClick={onUserClick} id={id} className="separator-wrapper" style={styleContainer} >
            <div className="separator" style={styleTop}/>
            <div className="separator" style={styleBottom}/>
            
        </div>
    );
}

export default Separator