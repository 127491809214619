import { useState, useEffect } from 'react';

const useDeviceType = () => {
 const [deviceType, setDeviceType] = useState({
  isMobile: false,
  isTablet: false,
  isLaptop: false,
  isLaptopLarge: false,
 });

 useEffect(() => {
  const checkDeviceType = () => {
   if (window.matchMedia('(max-width: 768px)').matches) {
    setDeviceType({
     isMobile: true,
     isTablet: false,
     isLaptop: false,
     isLaptopLarge: false,
    });
   } else if (window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches) {
    setDeviceType({
     isMobile: false,
     isTablet: true,
     isLaptop: false,
     isLaptopLarge: false,
    });
   } else if (window.matchMedia('(min-width: 1024px) and (max-width: 1390px)').matches) {
    setDeviceType({
     isMobile: false,
     isTablet: false,
     isLaptop: true,
     isLaptopLarge: false,
    });
   } else if (window.matchMedia('(min-width: 1390px)').matches) {
    setDeviceType({
     isMobile: false,
     isTablet: false,
     isLaptop: false,
     isLaptopLarge: true,
    });
   }
  };

  // Llamada inicial
  checkDeviceType();
 }, []);

 return deviceType;
};

export default useDeviceType;
