import getUserAgent from "./getUserAgent";

// Get zoom percentage in browser
export default function getZoomPercentage() {
    let zoom = Math.floor((window.devicePixelRatio / 2) * 100);
    const userAgent = getUserAgent();

    if(userAgent === 'safari') {
        zoom = Math.floor((( window.outerWidth) / window.innerWidth) * 100); /// Get zoom in safari
    }
    return zoom;
}