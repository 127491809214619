//reset any transforms on the body
import {pathInResponsiveViews} from "./route-utils";

document.body.style.transform = 'none'

//prevent pinch-to-zoom
document.addEventListener('touchmove', function (event) {
  if (pathInResponsiveViews())
    return
  if (event.scale !== 1) { event.preventDefault(); }
}, false);

//prevent double-tap-to-zoom
var lastTouchEnd = 0;
document.addEventListener('touchend', function (event) {
  var now = (new Date()).getTime();
  if (pathInResponsiveViews())
    return
  if (now - lastTouchEnd <= 1200) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}, false);
