/*
   this file adapted from https://github.com/onefinestay/react-lazy-render

   Copyright 2014 Likealike, Ltd. DBA onefinestay

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

   http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.

 */

import React from 'react'
import cn from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { inverseScale } from '../scale'
import createReactClass from 'create-react-class'

function end(event){
    if (event.stopPropagation()) event.stopPropagation()
        if (event.preventDefault()) event.preventDefault()

            return false;
}

var _lastScrollTop = 0

var LazyRender = createReactClass({
    propTypes: {
        /* children: PropTypes.array.isRequired,*/
        maxHeight: PropTypes.number.isRequired,

        className: PropTypes.string,
        itemPadding: PropTypes.number
    },
    getDefaultProps: function() {
        return {
            itemPadding: 0
        };
    },

    getInitialState: function() {
        return {
            childrenTop: 0,
            childrenToRender: 10,
            scrollTop: _lastScrollTop,
            height: this.props.maxHeight,
            childHeight: 60
        };
    },
    getMaxPosition: function(){
        let scroller    = this.refs.container
        let content     = this.refs.content
        let maxPosition = content.offsetHeight - scroller.offsetHeight;
        return maxPosition
    },
    onScroll: function() {
        var content   = this.refs.content
        var container = this.refs.container
        var scrollTop = container.scrollTop;

        var childrenTop = Math.floor(scrollTop / this.state.childHeight);

        var childrenBottom = (this.props.childCount - childrenTop -
                              this.state.childrenToRender);

        if (childrenBottom < 0) {
            childrenBottom = 0;
        }

        this._lock()
        this.setState({
            childrenTop: childrenTop,
            childrenBottom: childrenBottom,
            scrollTop: scrollTop
        }, ()=> {
            this._unlock()
            {
                let scroller = container

                let position    = scroller.scrollTop;
                let maxPosition = this.getMaxPosition()
                let percentage  = (position / maxPosition) * 100;

                this.props.onScroll(percentage);
            }
        })


        _lastScrollTop = scrollTop
    },
    _lock(){ this._setting = true },
    _unlock() { this._setting = false },
    setScroll(percentage) {
        if (this._setting) return

        let scroller    = this.refs.container;
        let maxPosition = this.getMaxPosition()
        let position    = maxPosition * (percentage / 100)

        scroller.scrollTop = position
    },

    getHeight: function(numChildren, childHeight, maxHeight) {
        var fullHeight = numChildren * childHeight;
        if (fullHeight < maxHeight) {
            return fullHeight;
        } else {
            return maxHeight;
        }
    },

    componentWillMount(){
        this._update(this.props)
    },

    componentWillReceiveProps: function(nextProps) {
        this._update(nextProps)
    },

    _update: function(nextProps){

        var childrenTop = Math.floor(this.state.scrollTop / this.state.childHeight);
        var childrenBottom = (nextProps.childCount - childrenTop -
                              this.state.childrenToRender);

        if (childrenBottom < 0) {
            childrenBottom = 0;
        }

        var height = this.getHeight(
            nextProps.childCount,
            this.state.childHeight,
            nextProps.maxHeight
        );

        var numberOfItems = Math.ceil(height / this.state.childHeight);

        if (height === this.props.maxHeight) {
            numberOfItems += this.props.itemPadding;
        }

        this.setState({
            childrenTop: childrenTop,
            childrenBottom: childrenBottom,
            childrenToRender: numberOfItems,
            height: height
        });
    },

    componentDidMount: function() {
        var childHeight = this.getChildHeight();

        var height = this.getHeight(
            this.props.childCount,
            childHeight,
            this.props.maxHeight
        );

        var numberOfItems = Math.ceil(height / childHeight);

        if (height === this.props.maxHeight) {
            numberOfItems += this.props.itemPadding;
        }

        this.refs.container.scrollTop = this.state.scrollTop

        this.setState({
            childHeight: childHeight,
            childrenToRender: numberOfItems,
            childrenTop: 0,
            childrenBottom: this.props.childCount - numberOfItems,
            height: height
        });
    },

    componentDidUpdate: function() {
        //important to update the child height in the case that the children change(example: ajax call for data)
        if (this.state.childHeight !== this.getChildHeight()) {
            this.setState({childHeight: this.getChildHeight()});
        }
    },

    componentWillUnmount: function(){
        this._removeHandlers(this._mouseMap())
    },


    getChildHeight: function() {
        return 60;
    },


    /* represents space taken by the open folder */
    getCushionPadding(){
        return 0

        let childHeight = 60
        let {selected}  = this.props
        let top         = this.state.childrenTop
        let amt         = 0

        /* selected items are 3x the height */
        if (selected !== -1 && selected < top) {
            amt += 2
        }

        return amt * childHeight;
    },

    getPrePadding(){
        let childHeight = 60
        let top         = this.state.childrenTop

        return top * childHeight;
    },

    getPostPadding(){
        let childHeight = 60
        let h = this.state.childrenBottom * childHeight;
        return h
    },

    render: function() {
        var start = this.state.childrenTop;
        var end   = this.state.childrenTop + this.state.childrenToRender;

        var childrenToRender = this.props.getItems(start, end);
        var children = childrenToRender;

        let prePadding     = this.getPrePadding()
        let cushionPadding = this.getCushionPadding()
        let postPadding    = this.getPostPadding()

        let {previous} = this.props

        let previousAbove = previous !== -1 && previous < start
        let cushion = cn({cushion: true, 'in-motion': previousAbove})

        children.unshift(<div style={{ height: cushionPadding }}
                              className={cushion} key="cushion"></div>)

        children.unshift(<div style={{ height: prePadding }} key="top"></div>);

        children.push(<div style={{ height: postPadding }} key="bottom"></div>);

        let {height} = this.state

        let contentStyle = {
            height: this.props.items.length * 60
        }

        return (
            <div style={{ height, 
                          overflowY: 'auto',
                          position: 'relative',
                          /* -120 to hide gap at top when selected is out-of-window */
                          top: '-120px'}}
                 className={this.props.className}
                 ref="container"
                 id="lazyRenderContainer"
                 onScroll={this.onScroll}
                 onMouseDown={this.mouseDown}
            >
              <div ref="content" style={contentStyle}>
                {children}
              </div>
            </div>
        );
    },
    /* grab-scrolling */
    _mouseMap: function(){
        return {mousemove: this.mouseMove, mouseup:this.mouseUp}
    },
    mouseDown: function(e){
        this._addHandlers(this._mouseMap());
        this._lastOffset = e.clientY
        this._didMove = false
        return end(e)
    },
    mouseMove: function(e){
        let offs = this._lastOffset - e.clientY

        this._lastOffset = e.clientY
        this._didMove = true

        this.refs.container.scrollTop += (offs * inverseScale())

        return end(e)
    },
    mouseUp: function(e){
        this._removeHandlers(this._mouseMap())
        return end(e)
    },
    _addHandlers: function(map){
        _.each(map, (value, key)=>{
            document.addEventListener(key, value, false)
        })
    },
    _removeHandlers: function(map){
        _.each(map, (value, key)=>{
            document.removeEventListener(key, value, false)
        })
    }
});

export default LazyRender
