export default function mapRange(array, lower, upper, fn){
    let start = Math.max(lower, 0)
    let end   = Math.min(upper + 1, array.length)

    let idx = start
    let result = []
    while (idx < end) {
        result.push(fn(array[idx], idx))
        idx++
    }
    return result
}
