import { ENTRIES_LOADED } from '../constants';

export default function update(state = [], action) {
  const {type, entries} = action
  switch (type) {
    case ENTRIES_LOADED:
      if (entries.topChart) {
        let result = {}
        Object.keys(entries.topChart).forEach(k => {
            let chart = entries.topChart[k]
            if (chart && chart.id) {
              const {id, topList, updateString, type, underMaintenance, devMaintenance} = chart
              result[type] = {id, topList, updateString, type, underMaintenance, devMaintenance};
            }
        })
        return result
      }
      break;
  }
  return state;
}
