import { ARTICLE_VIEW , SET_ARTICLE} from '../constants';

export default function update(state = {show:false,article:{}} , action) {
  const {type, status:show, article} = action
  switch (type) {
    case ARTICLE_VIEW:
        return {...state, show};
    case SET_ARTICLE:
      return {...state, article}
  }
  return state;
}