import React from "react";
import { Formik, Form } from "formik";
import Modal from "./modal";
import Input from "./input";
import { createPlaylist } from "../services/api";
import { addPlaylistAction } from "../actions/playlists";
import cn from 'classnames';

const CreatePlaylistModal = ({ showAddPlaylistModal, onCloseModal, modalerror, tracks, dispatch, customPlaylists }) => {
  return (
    <Modal
      isOpen={showAddPlaylistModal}
      onRequestClose={onCloseModal}
      shouldCloseOnEsc={false}
    >
      <Formik
        initialValues={{ title: "" }}
        onSubmit={(values, actions) => {
          createPlaylist(values.title, tracks)
            .then((result) => {
              actions.setSubmitting(false);

              dispatch(addPlaylistAction(result.body));
              onCloseModal();
            }).catch((err) => {
              actions.setSubmitting(false);
              actions.setFieldError("title", "Something went wrong");
            });
        }}
        validate={(values, props) => {
          let errors = {};

          if (!values.title) {
            errors.title = "Required";
          } else if (values.title.length > 20) {
            errors.title = "You have exceeded max number of characters";
          } 
          // else if (customPlaylists.length >= 10) {
          //   errors.title = "You have exceeded the max number of playlist";
          // }
          return errors;
        }}
        render={(props) => {
          if (modalerror) {
            props.setFieldError("title", modalerror);
            this.setState({ modalerror: null });
          }
          return (
            <Form className="content">
              <Input
                className="input"
                label="PLAYLIST NAME"
                limit={20}
                isFormikField
                name="title"
                setAutofocus
              />
              <div className="buttons-wrapper">
                <div className="button button--back" onClick={onCloseModal}>
                  CANCEL
                </div>
                <button
                  className={cn("button button--continue", {
                    disabled: (props.isSubmitting || !props.isValid),
                  })}
                  type="submit"
                >
                  SAVE
                </button>
              </div>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default CreatePlaylistModal;
