import React, { Component } from "react";
import cn from "classnames";
import marked from "marked";
import { Button, URLOpener } from "./common.js";
import downIcon from "../../images/movie-night/down-icon.png";
import upIcon from "../../images/movie-night/up-icon.png";
import fallbackImage from "../../images/movie-night/fallback.jpg";
import scheduleTitle from "../../images/movie-night/schedule-title.png";
import { NO_SUBCATEGORY } from "./movies";

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDescription: false,
    };
    this.onWatchFilm = this.onWatchFilm.bind(this);
    this.toogleDescription = this.toogleDescription.bind(this);
  }

  onWatchFilm() {
    this.props.scrollTop();
    const film = this.props.item;
    if (!this.props.canViewFilm(film)) {
      window.subs();
      return;
    }
    return this.props.watchFilm(film);
  }
  toogleDescription() {
    this.setState({
      showDescription: !this.state.showDescription,
    });
  }
  render() {
    let { item } = this.props;
    let {
      isLiveStreaming,
      title,
      director,
      dateText,
      time,
      isAfterStartDate,
      buyLink,
      imageURL,
      nowShowing,
      dateAvailability,
      description,
      thumbnail,
      liveStreamingPrePoster,
      liveStreamingPostPoster,
      message,
      requiresSubscription,
    } = item;
    let style = { backgroundImage: `url(${imageURL})` };
    let thumbnailUrl = thumbnail ? thumbnail : imageURL;

    let showDate = false;
    if (isLiveStreaming) {
      showDate = true;
      thumbnailUrl = isAfterStartDate
        ? liveStreamingPrePoster
        : liveStreamingPostPoster;
    }
    let showScheduleButton = !nowShowing ? true : false;

    return (
      <div id={this.props.id} className={cn("item", { isAfterStartDate })}>
        <div
          className={cn(
            "image",
            { requiresSubscription },
            { showScheduleButton }
          )}
          onClick={() => {
            if (nowShowing) this.onWatchFilm();
          }}
        >
          {" "}
          <img src={thumbnailUrl || fallbackImage} />{" "}
        </div>
        <div className="description">
          <div
            style={{ cursor: description ? "pointer" : "default" }}
            onClick={this.toogleDescription}
          >
            <div className="title">{title}</div>
            <div className="info">
              <div className="line">Directed by {director}</div>
              {time && <div className="line">Running time: {time}</div>}
              {message && <div className="line">{message}</div>}
              {showDate && <div className="line">{dateText}</div>}
            </div>
          </div>

          {description && this.state.showDescription ? (
            <div
              className="film-description line"
              dangerouslySetInnerHTML={{ __html: marked(description || "") }}
            />
          ) : null}
        </div>
        <div className="buttons">
          {nowShowing && (
            <Button action={this.onWatchFilm} title={`play`} blue={true} />
          )}
          {showScheduleButton && (
            <div className="line scheduled-date">{dateAvailability}</div>
          )}
          {buyLink && (
            <Button action={URLOpener(buyLink)} title="buy film" blue={true} />
          )}
        </div>
        {description && (
          <img
            className="show-description"
            src={this.state.showDescription ? upIcon : downIcon}
            onClick={this.toogleDescription}
          />
        )}
      </div>
    );
  }
}

export default class Schedule extends Component {
  constructor(props) {
    super(props);
  }
  getItems(movies, watchFilm, canViewFilm, scrollTop) {
    // console.log("HERE", movies);
    let items = {
      nowShowing: [],
      live: [],
    };

    movies.nowShowing.forEach((subcategory) => {
      if (subcategory.films.length > 0)
        items.nowShowing.push(
          <div className="subCategory">
            {subcategory.title !== NO_SUBCATEGORY ? subcategory.title : ""}
          </div>
        );
      subcategory.films.forEach((movie, index) => {
        const type = "nowShowing";
        items[type].push(
          <Item
            id={`${type}-${index}`}
            key={`${subcategory.title}-${type}-${index}`}
            item={movie}
            watchFilm={watchFilm}
            canViewFilm={canViewFilm}
            scrollTop={scrollTop}
          />
        );
      });
    });

    movies.scheduled.forEach((subcategory) => {
      if (subcategory.films.length > 0)
        items.live.push(
          <div className="subCategory">
            {subcategory.title !== NO_SUBCATEGORY ? subcategory.title : ""}
          </div>
        );
      subcategory.films.forEach((movie, index) => {
        const type = "live";
        items[type].push(
          <Item
            id={`${type}-${index}`}
            key={`${subcategory.title}-${type}-${index}`}
            item={movie}
            watchFilm={watchFilm}
            canViewFilm={canViewFilm}
            scrollTop={scrollTop}
          />
        );
      });
    });

    return items;
  }
  showMoreSchedule() {
    this.setState(
      {
        showingMoreSchedule: !this.state.showingMoreSchedule,
      },
      () => {
        if (this.state.showingMoreSchedule) {
          const movieNightContainer = document.querySelector(".movie-night");
          const firstScheduleVideo = document.getElementById("live-0");
          if (
            movieNightContainer &&
            firstScheduleVideo &&
            movieNightContainer.scrollTop < firstScheduleVideo.offsetTop
          ) {
            firstScheduleVideo.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    );
  }
  render() {
    const { watchFilm, canViewFilm, movies, scrollTop } = this.props;
    const items = this.getItems(movies, watchFilm, canViewFilm, scrollTop);
    const scheduledItems = movies.scheduled.reduce((acc, el)=>{
      return acc + el.films.length;
    }, 0) > 0;
    return (
      <div className="schedule">
        {items.nowShowing}
        {scheduledItems && (
          <div className="more-schedule">
            <img src={scheduleTitle} />
          </div>
        )}
        {items.live}
      </div>
    );
  }
}
