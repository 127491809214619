import { fetchExclusive, fetchInitial } from '../store'
import Audio from '../components/new-player/audio'
import {
  determineUserStatusFromSubscriptionResponse,
  setUserLastPaymentMethod
} from '../user'
import {
  fetchPaymentInformation,
  getStreamingToken,
  getSubscriptionStatusAndStreamingToken,
  setOrastreamToken
} from './api'
import fakeSubscription from '../fake-subscription'
import Auth from '../services/auth'

const setStreamingToken = jwt => {
  setOrastreamToken(jwt)
  Audio.setStreamingToken(jwt)
}

export function fetchInitialEntries() {
  return getStreamingToken().then(result => {
    setStreamingToken(result.orastream_jwt)
    // fetchInitial()
  })
}

export async function fetchExclusiveEntries() {
  await Auth.retrieveSession()

  return getSubscriptionStatusAndStreamingToken().then(result => {
    setStreamingToken(result.orastream_jwt)
    fetchExclusive()

    if (!fakeSubscription) {
      determineUserStatusFromSubscriptionResponse(result.subscription)
    }

    const FREE = 'NYA-FREE', TRIAL = 'NYA-TRIAL', TRIAL2 = 'TRIAL-PERIOD', { subscription: { planId } } = result

    if (planId === FREE || planId === TRIAL || planId === TRIAL2) {
      //do nothing
    } else {
      //fetch billing info
      return fetchPaymentInformation().then(billingInfo => {
        setUserLastPaymentMethod(billingInfo)
      })
    }
  })
}