import React , {useEffect} from 'react'

export const EmbedModal = (props) => {
    useEffect(() => {
            document.addEventListener('mousedown', hidePopup)
        // effect
        return () => {
            document.removeEventListener('mousedown', hidePopup)
            // cleanup
        }
    }, []);
    const hidePopup = (e) =>{
        if(
           e.target.className.includes('copy')
        ){
            return;
        }
        props.toggleEmbedModal(false);
    }

    const {album = false ,track, setCopy, copy, toggleEmbedModal , style = {} } = props
    const iframe = album ? `<iframe src="${process.env.SITE_URL}/embed?albumId=${album}" width="720" height="470" style="border:0;"></iframe>` : `<iframe src="${process.env.SITE_URL}/embed?trackId=${track}" width="720" height="320" style="border:0;"></iframe>`

    return (
        <div className="playlist-popup copy"  style={style}>
        <div className ="close-btn" onClick={()=>toggleEmbedModal(false)}></div>
                  <div
                  className="copy-btn"
                  onClick={()=>{
                        if(album || track){
                            navigator.clipboard.writeText(iframe).then((()=>{
                                  setCopy()
                              }), function(err) {
                                    console.error('Async: Could not copy text: ', err);
                                });
                        }


                  }

                }>{copy}</div>
                  <div className="copy-terms">By embeding the NYA player on your website, you are agreeing to NYA's <a href="/terms.html" target="_blank" className="link-copy" >terms of services</a> </div>
          </div>
    )
}
