import { ENTRIES_LOADED } from '../constants';

export default function update(state ={unlimitedAccess:false}, action) {
  const {type, entries} = action
  switch (type) {
    case ENTRIES_LOADED:
      if (entries.access) {
        let result = {unlimitedAccess:false};
        Object.keys(entries.access).forEach(k => {
            let access = entries.access[k]

            if (access  && access.unlimitedAccess) {
              const {unlimitedAccess} = access 
              result.unlimitedAccess = unlimitedAccess;
            }
        })
        return result;
      }
      break;
  }
  return state;
}