import React, {Component} from 'react'
import VerticalCenter     from './components/vertical-center'
import cn                 from 'classnames'
import _                  from 'lodash'
import PropTypes          from 'prop-types'

export default class LiveStreamTeaserContainer extends Component {
    constructor(props, ctx){
        super(props, ctx)

        //working around a funky bug here...
        this.goBack = _.debounce(this.goBack.bind(this))
    }
    goBack(){
        this.context.router.goBack()
    }
    getContent(){
        return (
            <div className="content">You're early!<br />
              <br />
              Streaming doesn't start until 8pm EST.<br />
              <br />
              If you are seeing this message after 8pm,<br />
              reload this page or go to<br />
              <a href="http://facebook.com/neilyoung" target="_blank">facebook.com/neilyoung</a>
            </div>
        )
    }
    render(){
        let cx = cn('warning-message', 'live-teaser')
        return (
            <VerticalCenter className="warning-page" onBGClick={this.goBack}>
              <div className={cx}>
                <div className="close" onClick={this.goBack} />
                {this.getContent()}
              </div>
            </VerticalCenter>
        )
    }
}

LiveStreamTeaserContainer.contextTypes = {
    router: PropTypes.object.isRequired
}
