import { SET_USERDATA } from '../constants';

export default function update(state = {}, action) {
    switch (action.type) {
        case SET_USERDATA:
            if(action.userData) return action.userData
            break;
    }
    return state;
}
