import cn                        from 'classnames'
import React, {Component}        from 'react';
import {getTourZIByYearAndMonth} from '../drawer-items'

let calcPosition = ({day}) => {
    return ((1/31) * day) * 100
}

export default class TourZI extends Component {
    constructor(props){
        super(props)

        this.onClick    = this.onClick.bind(this)
        this.mouseEnter = this.mouseEnter.bind(this)

    }
    shouldComponentUpdate(newprops){
        return newprops.selectedEventId    !== this.props.selectedEventId ||
               newprops.selectedEventIndex !== this.props.selectedEventIndex
    }
    onClick(e){
        let id = e.target.getAttribute('data-id')
        if (!id) return
        let idx = e.target.getAttribute('data-index')
        if (id === this.props.selectedEventId && idx === this.props.selectedEventIndex) return
        e.preventDefault()
        e.stopPropagation()
        this.props.onClick(id, idx)
    }
    mouseEnter(e){
        let id = e.target.getAttribute('data-id')
        if (!id) return
        let idx = e.target.getAttribute('data-index')
        if (id === this.props.selectedEventId && idx === this.props.selectedEventIndex) return
        e.preventDefault()
        e.stopPropagation()
        this.props.onClick(id, idx)
    }
    getTileForYearAndMonth(tour, year, month, key) {
        let idx = 0
        {
            let {month:m, year:y} = tour.startDate

            while (!(y === year && m === month)) {
                m++
                if (m > 12) {
                    m = 1
                    y++
                }
                idx++
            }
        }

        let image = tour.tiles[idx]

        let style = {backgroundImage:`url(${image.url})`,
                     width:image.width, height: image.height}

        let selected = tour.id === this.props.selectedEventId && idx === this.props.selectedEventIndex

        let snapleft = false, snapright = false;

        if (tour.tiles.length === 1) {

            style.left = calcPosition(tour.startDate) + '%'

        } else {

            snapleft = idx === tour.tiles.length - 1
            snapright = !snapleft
        }

        let cx = cn('tour', {snapleft, snapright, selected})

        return (
            <div key={key} className={cx} style={style} />
        )

    }
    render(){
        let {year, month} = this.props
        let tours = getTourZIByYearAndMonth(year, month)

        if (!tours) return null
        let tiles = tours.map((tour, idx)=>this.getTileForYearAndMonth(tour, year, month, idx))

        return (
            <div className="tour-wrapper">
              {tiles}
            </div>
        )
    }
}
