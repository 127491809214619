import { ENTRIES_LOADED, UPDATE_MOVIE, UPDATE_FILMS } from '../constants';

export default function update(state = {byContentfulId:{}, all:[], movieNightFilmLoaded:false}, action) {
  if (!action || !action.type) {
    console.error("Action or action type is null");
    return state;
  }

  try {
    switch (action.type) {
      case ENTRIES_LOADED:
        if (action.entries && action.entries.movieNightFilm) {
          return {
            ...state, 
            ...action.entries.movieNightFilm, 
            movieNightFilmLoaded: true
          };
        }
        return state;

      case UPDATE_MOVIE:
        const { movie } = action;
        if (!movie) {
          console.warn("Movie data is null, which may be intentional.");
          return state;
        }
        const result = { ...state };
        result.byContentfulId[movie._id] = movie;
        result.all = result.all.map(it => it._id === movie._id ? movie : it);
        return result;

      case UPDATE_FILMS:
        if (action.movie) {
          return action.movie;
        }
        return state;

      default: 
        return state;
    }
  } catch (error) {
    console.error("Error processing action in movieNightFilm reducer:", error);
    return state;  // Return current state to avoid breaking the app
  }
}

