import marked             from 'marked'
import React, {Component} from 'react'
import VerticalCenter     from './vertical-center'
import LyricsSlider       from './lyrics-slider'
import {scale}            from '../scale'

class Content extends Component {
    shouldComponentUpdate(newprops){
        return this.props.text !== newprops.text || this.props.useMarkdown !== newprops.useMarkdown
    }
    componentDidUpdate(){ this._updateLinks(); }
    componentDidMount() { this._updateLinks(); }
    _updateLinks(){
        if (!this.props.useMarkdown) return
        let links = this.refs.content.querySelectorAll('a')
        for (let i = 0; i < links.length; i++) {
            let a = links[i]
            if (location.hostname === a.hostname || !a.hostname.length) continue;
            a.setAttribute('target', '_blank')
        }
    }
    getContent(){
        return this.refs.content
    }
    render(){
        let {useMarkdown, text} = this.props

        if (useMarkdown) {
            let markup = marked(text)
            return (<div ref="content" className="content"
                         dangerouslySetInnerHTML={{__html:markup}} />)
        } else {
            return (<div ref="content" className="content">{text}</div>)
        }
    }
}


export default class LyricsModal extends Component {
    constructor(props){
        super(props)

        this.state = {pct: 0, scrollingAvailable: false}

        this.onScroll     = this.onScroll.bind(this)
        this.onSlide      = this.onSlide.bind(this)
        this.onBGClick    = this.onBGClick.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.unbind = this.unbind.bind(this)
        this.hideModal = this.hideModal.bind(this)
    }
    componentWillMount(){
        document.addEventListener('mousedown', this.hideModal)
    }
    hideModal(e){
        if (e.target.closest('#lyrics-modal')) return

        e.preventDefault()
        e.stopPropagation()

        this.unbind()
        this.props.onClose(e)
    }
    unbind(){
        document.removeEventListener('mousedown', this.hideModal)
    }
    componentWillUpdate(newprops, newstate){
        this.setScroll(newstate.pct)
    }
    componentDidMount(){
        this.updateHeight()
        setTimeout(this.handleResize, 300)
        window.addEventListener('resize', this.handleResize)
    }
    componentWillUnmount(){
        this.unbind()
        window.removeEventListener('resize', this.handleResize)
    }
    setScroll(percentage){
        let scroller    = this.refs.scroll;
        let content     = this.getContent();
        let maxPosition = content.offsetHeight - scroller.offsetHeight;
        let position    = maxPosition * (percentage / 100)

        scroller.scrollTop = position
    }
    onBGClick(e){
        if (e.target !== this.refs.background) return;

        e.preventDefault()
        e.stopPropagation()

        this.props.onClose()
    }
    onScroll(e){
        e.preventDefault()
        e.stopPropagation()

        let scroller    = this.refs.scroll;
        let content     = this.getContent();
        let position    = scroller.scrollTop;
        let maxPosition = content.offsetHeight - scroller.offsetHeight;
        let percentage  = (position / maxPosition) * 100;

        this.setState({pct:percentage})
    }
    onSlide(pct){
        this.setState({pct})
    }
    updateHeight(){
        const height = window.innerHeight * (1/scale())
        const topBarHeight = 108
        this.setState({height: height - topBarHeight})
    }
    handleResize(){
        this.checkIfScrollingAvailable()
        this.updateHeight()
    }
    checkIfScrollingAvailable(){
        let scroller    = this.refs.scroll;
        let content     = this.getContent();
        let maxPosition = content.offsetHeight - scroller.offsetHeight;

        let scrollingAvailable = maxPosition > 0

        if (scrollingAvailable !== this.state.scrollingAvailable) {
            this.setState({scrollingAvailable})
        }
    }
    getContent(){
        return this.refs && this.refs.content.getContent();
    }
    renderContent(text) {
        return (<Content ref="content" useMarkdown={this.props.markdown} text={text} />)
    }
    render(){
        let {track, onClose, content}         = this.props
        let {pct, scrollingAvailable, height} = this.state
        let vis                               = scrollingAvailable ? 'visible' : 'hidden'
        let sliderStyle                       = {visibility: vis}

        pct = Math.max(0, Math.min(pct, 100))

        let text = content === 'info' ? track.moreInfo || "" : track.lyrics

        text += "\n\n\n\n"

        console.log('height = ', height, ' scrollingAvailable = ', scrollingAvailable)

        return (
            <div id="lyrics-modal"
                 className="lyrics-modal"
                 ref="background"
                 onClick={this.onBGClick}>
              <VerticalCenter className="container">
                <div className="lyrics-wrapper" style={{height:height+'px'}}>
                  <div className="lyrics">
                    <div className="title">{track.title}</div>
                    <div className="scroll-hider">
                      <div ref="scroll" onScroll={this.onScroll} className="scroll">
                        {this.renderContent(text)}
                      </div>
                    </div>
                    <div className="slider" style={sliderStyle}>
                      <LyricsSlider pct={pct} onScroll={this.onSlide} />
                    </div>
                    <div className="close" onClick={onClose}></div>
                  </div>
                </div>
              </VerticalCenter>
            </div>
        )
    }
}
