import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router';
import MainContainer from './main-container';
import DrawerContainer from './drawer';
import InfoCardContainer from './info-card';
import TimelineYearsContainer from './timeline-years';
import TimelineMonthsContainer from './timeline-months';
import PhotosContainer from './photos';
import DocumentsContainer from './documents';
import PressContainer from './press';
import MemorabiliaContainer from './memorabilia';
import DrawerFront from './drawer-front';
import AlbumModal from './album-modal';
import PPVModal from './news/ppv/ppv-modal';
import ModalWrapper from './performance-log/modal-wrapper';
import ItineraryModal from './performance-log/itinerary-notes';
import Map from './map';
import GlobalMenu from './global-menu';
import VideoPlayerContainer from './video-player';
import LiveStreamContainer from './live';
import LiveStreamTeaserContainer from './live-teaser';
import GettingStartedContainer from './getting-started';
import SearchContainer from './search/search';
import SearchRecordStoreDayContainer from './search-record-store-day';
import News from './news/news';
import Newspaper from './news/newspaper';
import NewsArticleContainer from './news/article';
import PPV_Page from './news/ppv/ppv';
import Top40Tracks from './news/top-40-tracks';
import Top10Albums from './news/top-10-albums';
import NewsletterContainer from './newsletter';
import ContactContainer from './contact';
import AccountInfo from './account-info/index';
import MovieNightHome from './movie-night/home/home';
import MovieNight from './movie-night/movie-night';
import Page404 from './404.js';
import TestContainer from './test';
import { Provider, connect } from 'react-redux';
import { Router, Route, IndexRoute, applyRouterMiddleware } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { showLiveStream, showLiveStreamTeaser } from './toggleLiveStreams';
import HOCRequireAuth from './hoc-require-auth';
import RequestModal from './performance-log/requestModal';
import Player from './components/new-player/new-player';
import NewspaperPage from "./newspaper-iframe/page";
import ContentfulPageContainer from "./contentful-pages/page";

const mapContentfulPages = (pages) => {
  return pages.map(page => {
    return <Route key={page._id} path={page.routePath} component={ContentfulPageContainer(page)} />
  });
}

const desktopRoutes = (pages) => {
  return <Fragment>
    <Redirect from="/" to="/welcome"/>
    <Route path="/welcome" component={MainContainer}>
      <IndexRoute component={DrawerFront}/>
      <Route component={HOCRequireAuth}>
        <Route path="/video" component={VideoPlayerContainer}/>
        {showLiveStream && <Route path="/live" component={LiveStreamContainer}/>}
        {showLiveStreamTeaser && <Route path="/live" component={LiveStreamTeaserContainer}/>}
      </Route>
      <Route path="/info-card/photos" component={PhotosContainer}/>
      <Route path="/info-card/documents" component={DocumentsContainer}/>
      <Route path="/info-card/press" component={PressContainer}/>
      <Route path="/info-card/memorabilia" component={MemorabiliaContainer}/>
      <Route path="/account" component={AccountInfo}/>
      <Route path="/drawer" component={DrawerContainer}/>
      <Route path="/map" component={Map}/>
      <Route path="/menu" component={GlobalMenu}/>
      <Route path="/:youtube/info-card" component={InfoCardContainer}/>
      <Route path="/info-card" component={InfoCardContainer}/>
      <Route path="/timeline" component={TimelineYearsContainer}/>
      <Route path="/timeline-months" component={TimelineMonthsContainer}/>
      <Route path="/album" component={AlbumModal}/>
      <Route path="/playlist" component={AlbumModal}/>
      <Route path="/film" component={AlbumModal}/>
      <Route path="/request-modal" component={RequestModal}/>
      <Route path="/performance-log" component={ModalWrapper}>
        <Route path=":tour" component={ItineraryModal}/>
      </Route>
      <Route path="/movie-night/home" component={MovieNightHome}/>
      <Route path="/movie-night/movietone" component={PPV_Page}/>
      <Route path="/movie-night(/:id)(/:id)" component={MovieNight}/>
      <Route path="/movietone(/:id)" component={PPVModal}/>
      <Route path="/news" component={Newspaper}>
        <Route path="top-40-tracks" component={Top40Tracks}/>
        <Route path="top-10-albums" component={Top10Albums}/>
        <Route path=":page/article" component={NewsArticleContainer}/>
        <Route path=":page" component={News}/>
      </Route>
      <Route path="/news-article" component={NewsArticleContainer}/>
      <Route path="/newsletter" component={NewsletterContainer}/>
      <Route path="/getting-started" component={GettingStartedContainer}/>
      <Route path="/contact" component={ContactContainer}/>
      <Route path="/search" component={SearchContainer}/>
      <Route path="/find-store" component={SearchRecordStoreDayContainer}/>
      <Route path="/404" component={Page404}/>
      <Route path="/test" component={TestContainer}/>
      <Route path="*" component={Page404}/>
      <Route path="/embed" component={Player}/>
      <Route path="/google71d6b4927829901b.html"/>
      {mapContentfulPages(pages)}
    </Route>
    <Route path="/times-contrarian" component={NewspaperPage}>
      <Route path="top-40-tracks" component={NewspaperPage}/>
      <Route path=":page" component={NewspaperPage}></Route>
      <Route path=":page/article" component={NewspaperPage}></Route>
    </Route>
  </Fragment>
};

const currentLocation = window.location.pathname;
const routes = (pages) =>
  currentLocation === '/embed' ? (
    <Route path="/embed" component={Player} />
  ) : (
    desktopRoutes(pages.filter(page => !page.hidden))
  );
class AppRoutes extends Component {
  render() {
    const { store, history, metas, pages } = this.props;
    console.log("%c @@@ pages", "color:red;", pages)
    return (
      <Provider store={store}>
        <Fragment>
          <Helmet {...metas} />
          <Router
            history={history}
            routes={routes(pages)}
            render={applyRouterMiddleware(useScroll())}
          />
        </Fragment>
      </Provider>
    );
  }
}

const mapStateToProps = function (state) {
  const { metas, pages: {all} } = state;
  return { metas, pages: all };
};

export default connect(mapStateToProps)(AppRoutes);
