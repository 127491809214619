import React, {Component} from 'react'
import {Button, URLOpener} from './common.js'

export default class Streaming extends Component {
    constructor(props) {
      super(props)
      this.onWatchFilm = this.onWatchFilm.bind(this)
    }

    onWatchFilm() {
      const {film} = this.props
      if (!this.props.canViewFilm(film)) {
          window.subs()
          return
      }
      return this.props.watchMovie(film)
    }

    render() {
        const {post, showSchedule, film} = this.props
        if (!film) return null
        const {live, isAfterStartDate, title, director, time, buyLink, dateText, message} = film

        let showDate = true
        if (live) showDate = false

        return (
            <div className="streaming">
              <center>
                <div className="title">{title}</div>
                <div className="director">Directed by {director}</div>
                {time && <div className="time">Running time: {time}</div>}
                {message && <div className="message">{message}</div>}
                {showDate && <div className="date-text">{dateText}</div>}
                <div className="buttons">
                  {buyLink && <Button action={URLOpener(buyLink)} title="buy film" />}
                  {post && <div className="spacer"/>}
                </div>
                {/* <div className="to-schedule" onClick={showSchedule}>See movie schedule</div> */}
              </center>
            </div>
        )
    }
}
