import React, {Component, Fragment} from 'react'
import PropTypes          from 'prop-types'
import VerticalCenter     from './vertical-center'
import ScrollBars         from 'react-scrollbar'
import preloadImages      from '../util/preload-images'
import cn                 from 'classnames'
import back               from '../util/back'
import bottomLine from '../../images/section-page/bottom-line.png'
import backgroundImg from '../../images/basic-page/background.jpg'
import backButton from '../../images/section-page/back-button.png'


function HeaderText({text}) {
    return <div style={{
        position: "absolute",
        top: "20px",
        zIndex: 2,
    }}>
        <img src={bottomLine}/>
        <h1
          style={{
              fontSize: "2.5em",
              textAlign: "center",
              margin: "0",
              fontFamily: "Veneer",
              letterSpacing: "0.07em",
              color: "#1A1711DD"
          }}
        >
            {text}
        </h1>
        <img src={bottomLine}/>
    </div>
}

export default class SectionPage extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {loading: true}

        this.onClose = this.onClose.bind(this)
        this.escFunction = this.escFunction.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.goBackToGettingStarted  = this.goBackToGettingStarted.bind(this)
        preloadImages([backgroundImg]).then(() => {
            this.setState({loading: false})
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    componentDidUpdate(){
        this._updateLinks();
    }
    componentDidMount() {
        this._updateLinks();
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }
    _updateLinks(){
        if (this.state.loading) return
        if (!this.props.html) return
        let links = this.refs.content.querySelectorAll('a')
        for (let i = 0; i < links.length; i++) {
            let a = links[i]
            if (location.hostname === a.hostname || !a.hostname.length) continue;
            a.setAttribute('target', '_blank')
        }
    }
    onClose(){
        back(this.context.router)
    }
    escFunction(){
        if(event.keyCode === 27) {
            back(this.context.router)
        }
    }

    goBackToGettingStarted() {
        this.context.router.push('/getting-started');
    }

    getContent(){
        if (this.props.html) {
            return (<div className="content" ref="content" dangerouslySetInnerHTML={{__html:this.props.html}} />)
        } else {
            return (
                <div className="content" ref="content">
                  {this.props.children}
                </div>
            )
        }
    }
    render(){

        if (this.state.loading) return null

        let {headerImageUrl, className, isPopup,
             onClose, showUserMenu, headerText, shownInGettingStarted} = this.props

        let showControls = !isPopup

        let cx = cn('section-page', className)

        return (
            <VerticalCenter className={cx} ref="bg" onBGClick={this.props.onBGClick || this.onClose}>
              <div className="content-area-wrapper">
                <span className="content-area-span" />
                <div className="content-area" ref={this.setWrapperRef}>
                  <div className="background" />
                    {shownInGettingStarted ? <button onClick={this.goBackToGettingStarted}>
                        <img src={backButton} alt="Back button" style={{
                            position: "absolute",
                            top: "36px",
                            zIndex: 10,
                            width: "57px",
                        }}/>
                    </button> : null}
                    {headerImageUrl
                      ? <img className="header-image" src={headerImageUrl}/>
                      : <HeaderText text={headerText}/>
                    }
                    <ScrollBars style={{
                        top: "140px",
                    }}>
                        {this.getContent()}
                    </ScrollBars>
                </div>
              </div>
            </VerticalCenter>
        )
    }
}
SectionPage.contextTypes = {
    router: PropTypes.object.isRequired
}
