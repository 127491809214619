import React, {useContext, useEffect, useRef} from "react";
import store from "../../store";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import headerDefaultImage from "../../../images/news/newspaper-header.png"

export function NewspaperHeadline(props) {
  const {leftHeaderText, rightHeaderText, pagesByTitle, page} = props;
  const opacity = props.opacity;
  const headerTitle = useRef();


  useEffect(() => {
    if (!page || _.isEmpty(pagesByTitle))
      return
    const pageId = `page-${page}`;
    const {pageHeader} = pagesByTitle[pageId];
    const headerImage = pageHeader?.fields?.file.url || headerDefaultImage;
    headerTitle.current.style.backgroundImage = `url(${headerImage})`;
  }, [pagesByTitle, page]);

  return (
   <div className="headline line" style={{ opacity: opacity }}>
    <div className="left-side">
  
      <div className="box">
       <p>{leftHeaderText}</p>
      </div>
  
    </div>
    <div className="center" ref={headerTitle}></div>
    <div className="right-side">
   
      <div className="box">
       <p>{rightHeaderText}</p>
      </div>
  
    </div>
   </div>
  );
}

NewspaperHeadline.contextTypes = {
  page: PropTypes.number,
  leftHeaderText: PropTypes.string,
  rightHeaderText: PropTypes.string,
  pagesByTitle: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    leftHeaderText: state?.newsPage?.page?.leftHeaderText || '',
    rightHeaderText: state?.newsPage?.page?.rightHeaderText || '',
    pagesByTitle: state?.contrarianPage?.byTitle || {}
  }
}

export default connect(mapStateToProps, {})(NewspaperHeadline);
