import React, {PureComponent} from 'react';
import Hanger             from './hanger';
import TitleTab           from './title-tab';
import cn                 from 'classnames'

export default class CabinetFolder extends PureComponent {

    getTabs() {
        let {item, index, onClick, onMouseEnter, onMouseLeave} = this.props

        if (item.isTrack) {
            return (
                <div>
                {item.tabLeft ? 
                <div className="title-tab-container">
                  <TitleTab item={item} index={index}
                            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                            position="left"
                            onClick={onClick} />
                </div>
                : null }
                {item.tabRight ? 
                <div className="title-tab-container">
                  <TitleTab item={item} index={index}
                            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                            position="right"
                            onClick={onClick} />
                </div>
                : null }
                </div>
            )
        } else {
            return (
                <div className="title-tab-container">
                  <TitleTab item={item}
                            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                            index={index} onClick={onClick} />
                </div>
            )
        }
    }

    _newPlaylistButton() {
        return (
            <div className="new-playlist-button" onClick={this.props.onAddPlaylist}/>
        );
    }

    render(){

        let {item, selected, prevSelected, onAddPlaylist} = this.props

        let {folder} = item

        let cx = cn({item:true,
                     selected,
                     first: item.firstItem,
                     last: item.lastItem})


        return (
            <div className={cx}>
              {this.getTabs()}
             <Hanger folderClass={folder} showSides={prevSelected} />
            </div>
        );
    }
}
