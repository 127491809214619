import { ENTRIES_LOADED } from '../constants';
import {makeInitialState, index} from './utils'

const initialState = makeInitialState()

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.timelineEvent) return index(action.entries.timelineEvent, state, 'date');
            break;
    }
    return state;
}
