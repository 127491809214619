import _ from 'lodash';
import { LOAD_LEFT_SIDE_ICONS } from '../constants';

export default function update(state = {icons:undefined}, action) {
    switch (action.type) {
        case LOAD_LEFT_SIDE_ICONS:
            if(action.data) {
                let result = undefined
                Object.keys(action.data).forEach(k => {
                    //there should only be one of these...
                    result = {icons: action.data[k]}
                })
                return result
            }
            break;
    }
    return state;
}
