import _ from 'lodash'
import { ENTRIES_LOADED } from '../constants';

const initialState = {all:[], byId:{}, byContentfulId:{}}

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.albumArt) {
                let byId = {}, byContentfulId = {}
                let all = _.map(action.entries.albumArt, albumArt=>{
                    byId[albumArt.id] = albumArt
                    byContentfulId[albumArt._id] = albumArt
                    return albumArt
                })
                return {all, byId, byContentfulId, loaded: true}
            }
            break;
    }
    return state;
}
