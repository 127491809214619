import _ from 'lodash'
const JSON = require('circular-json');
import {compareItems} from '../util/compare-release-date'

export function makeInitialState(){
    return {loaded: false, all:[],releaseItems:[], byId:{},timelineRelease:{}, byYear: {},timelineCompletion:{}}
}

export function index(newData, state, dateKey='releaseDate'){
    let data = state.loaded ? _.extend({}, newData, state.byContentfulId) : newData;
    let byId = {}, byYear = {},timelineRelease={}, byContentfulId = data, timelineCompletion={}

    let all = Object.keys(data).map(id => {
        let it  = data[id]
        
        let year = it[dateKey].year;
        if (byId[it.id]) {
            console.warn("found entry with duplicate id: ", it.id)
            return null;
        } else {
            byId[it.id] = it
        }  
        byYear[year] = byYear[year] || [] 
        byYear[year].push(it)
        return it
    })

    //setting release albums
    let releaseData = _.cloneDeep(data)
    let additionalReleases = createAdditionalTimelineItems(releaseData, timelineRelease, "release")
    let releaseItems = additionalReleases.additionalItems
    timelineRelease = additionalReleases.timeline

    //setting completion albums
    let completionData = _.cloneDeep(data)
    let additionalCompletion = createAdditionalTimelineItems(completionData, timelineCompletion, "completion")
    let completionItems = additionalCompletion.additionalItems
    timelineCompletion = additionalCompletion.timeline
    
    all = _.compact(all)
    all.forEach((track)=>{
        if(track.hasOwnProperty('isTrack')){
            if(track.free){
                track.songOfTheDay = true
                track.freeItem =true
            }
        }
    })
    all.sort(compareItems)


    return {loaded: true, all,releaseItems, byId, byYear,timelineRelease, byContentfulId, completionItems, timelineCompletion}
}

function createAdditionalTimelineItems (data, timeline, type){
    let additionalItems = Object.keys(data).map(id => {
        let AlbumDisplay  =  data[id];
        let releaseDate = AlbumDisplay.releaseDate instanceof Object ? new Date(AlbumDisplay.releaseDate.year, AlbumDisplay.releaseDate.month, AlbumDisplay.releaseDate.day) : new Date(AlbumDisplay.releaseDate)
        if (type == "release"){
            if (AlbumDisplay.displayDate){
                let displayDate = AlbumDisplay.displayDate instanceof Object ? new Date(AlbumDisplay.displayDate.year, AlbumDisplay.displayDate.month , AlbumDisplay.displayDate.day) : new Date(AlbumDisplay.displayDate)
                 if(AlbumDisplay.displayDate.year !== undefined && displayDate.getTime()  !== releaseDate.getTime() ){
                    timeline[AlbumDisplay.displayDate.year] = timeline[ AlbumDisplay.displayDate.year] || []
                    AlbumDisplay.releaseAlbum = true
                    AlbumDisplay.timelineReleaseDate = AlbumDisplay.displayDate 
                    timeline[AlbumDisplay.displayDate.year].push(AlbumDisplay)         
               }
            }
        }else if (type == "completion"){
            if (AlbumDisplay.completionDate && AlbumDisplay.completionDateExist){
                let completionDate = AlbumDisplay.completionDate instanceof Object ? new Date(AlbumDisplay.completionDate.year, AlbumDisplay.completionDate.month , AlbumDisplay.completionDate.day) : new Date(AlbumDisplay.completionDate)
                 if(AlbumDisplay.completionDate.year !== undefined && completionDate.getTime()  !== releaseDate.getTime() ){
                    timeline[AlbumDisplay.completionDate.year] = timeline[ AlbumDisplay.completionDate.year] || []
                    AlbumDisplay.completionAlbum = true
                    timeline[AlbumDisplay.completionDate.year].push(AlbumDisplay)         
               }
            }
        }
        return AlbumDisplay
    })
    let additionalTimelineItems = {additionalItems,timeline}
    return additionalTimelineItems
}