import _ from 'lodash'
import { ENTRIES_LOADED } from '../constants';

const initialState = {all:[], byId:{}}

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.page) {
                let byId = {}
                let all = _.map(action.entries.page, page=>{
                    byId[page.id] = page
                    return page
                })
                return {all, byId}
            }
            break;
    }
    return state;
}
