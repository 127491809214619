import React, {Component} from 'react'
import _                  from 'lodash'
import PropTypes          from 'prop-types'
import OpenFolder         from './open-folder'
import { getTrackById }   from '../drawer-items'
import PhotoGallery       from './photo-gallery'
import PhotoModal         from './photo-modal'
import GlobalMenuIcon     from './global-menu-icon'
import {photoCollectionIsLockedByTrackID} from '../user'

export default class FolderBrowser extends Component {

    constructor(props, b){
        super(props, b)

        let {query} = props.location

        let trackId = query.track
        let photoId = query.photo

        const locked = photoCollectionIsLockedByTrackID(trackId)

        let track  = getTrackById(trackId)

        let photos = this.extractPhotos(track)

        photos = photos || []
        let photo  = this.getPhoto(photoId, photos)

        this.state = {photo, track, photos, photoId, locked}

        this.showModal        = this.showModal.bind(this)
        this.hideModal        = this.hideModal.bind(this)
        this.showCurrentPhoto = this.showCurrentPhoto.bind(this)
        this.closeFolder      = this.closeFolder.bind(this)
        this.backToCabinet    = this.backToCabinet.bind(this)
    }
    getPhoto(id, photos){
        return _.find(photos, {id}) || null
    }
    extractPhotos(track){
        return this.extractPhotosFromTrack(track)
    }
    updateUrl({photo, track}){
        let {router}   = this.context
        let {pathname} = this.props.location

        let query = {track: track.id}
        if (photo && photo.id){
            query.photo = photo.id
        }

        let location = router.createLocation({pathname, query})
        delete location.search

        router.replace(location)
    }
    showModal(photo){
        let {router}   = this.context
        let {pathname} = this.props.location
        let {track}    = this.state

        let query = {track: track.id}
        if (photo && photo.id){
            query.photo = photo.id
        }

        let location = router.createLocation({pathname, query})
        delete location.search
        router.push(location)
    }
    showCurrentPhoto(photo){
        this.setState({photo, photoId:photo.id})
        this.updateUrl({photo, track:this.state.track})
    }
    hideModal(){
        this.context.router.goBack()
    }
    componentWillUpdate(nextProps, nextState) {
        let {query} = nextProps.location
        let trackId = query.track

        let track  = getTrackById(trackId)
        let photos = this.extractPhotos(track)

        let photoId = query.photo
        if (photoId !== nextState.photoId) {
            let photo = this.getPhoto(photoId, photos)
            this.setState({photoId, photo, track, photos})
        }
    }

    componentDidMount(){
        const {locked} = this.state
        if (locked) {
            const {router} = this.context
            router.replace(router.createLocation('/'))
            window.subs()
        }
    }

    closeFolder(){
        let pathname = '/info-card'
        let {track}  = this.state
        let {router} = this.context

        let query = {track: track.id }
        let location = router.createLocation({pathname, query})

        delete location.search

        router.push(location)
    }

    backToCabinet(){
        let {router} = this.context
        router.push(router.createLocation('/drawer'))
    }

    /* to be overriden */
    contentType(){
        console.error("Need to provide a content type!")
        debugger;
    }

    /* to be overriden */
    selectedTab(){
        console.error("don't know the selected tab")
        return null
    }

    /* to be overriden */
    extractPhotosFromTrack(/* track*/){
        console.error("don't know how to extract photos from track!")
        return null
    }

    /* to be overriden for documents */
    modalForPhotoAndPhotos(photo, photos){
        return (<PhotoModal photo={photo} collection={photos}
                            location={this.props.location}
                            onUpdate={this.showCurrentPhoto}
                            onDismiss={this.hideModal} /> )
    }


    render(){
        let {track, photoId, photo, photos, locked} = this.state
        if (locked) return null
        let selected = this.selectedTab()
        return (
            <div className="info-card-section">
              <OpenFolder track={track} selected={selected} onClose={this.closeFolder}>
                <PhotoGallery photos={photos} onSelect={this.showModal} />
              </OpenFolder>
              {photoId ? this.modalForPhotoAndPhotos(photo, photos) : null }
              <div className="up-arrow-wrapper">
                <div className="up-arrow" onClick={this.backToCabinet}></div>
              </div>
              <GlobalMenuIcon />
            </div>
        )
    }
}

FolderBrowser.contextTypes = {
    router: PropTypes.object.isRequired
}
