import marked             from 'marked'
import React, {Component} from 'react'

function formatDate({year, month}){
    let monthName = ['January','February', 'March', 'April', 'May', 'June','July','August','September','October','November','December'][month]
    return `${monthName} ${year}`
}

let renderer = new marked.Renderer()


const fixedCopyright = c => `<span class="copyright">${c}</span>`
const copyright = /\u00a9/g //replace all copyright symbols

renderer.paragraph = text => `<p>${text.replace(copyright, fixedCopyright)}</p>`

export default class PhotoInfo extends Component {
    render(){
        let {photo} = this.props
        let {date, description, credits} = photo

        let formattedDate = date ? formatDate(date) : '';

        //use double-hash marks to represent line breaks in contentful
        //(shortcoming of photo etc model.)
        credits = (credits || '').replace(/##/g, "\n")

        let descriptionMarkup = marked(description || '', {renderer})
        let creditsMarkup     = marked(credits, {renderer})

        return (
              <div className="photo-info">
                <div className="header">
                  <div className="photo-date">{formattedDate}</div>
                  <div className="photo-description"
                       dangerouslySetInnerHTML={{__html:descriptionMarkup}}/>
                </div>
                <div className="separator"></div>
                <div className="photo-credits" dangerouslySetInnerHTML={{__html:creditsMarkup}} />
              </div>
        )
    }
}
