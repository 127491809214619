import React, { useEffect, useRef } from 'react';
import {Link} from 'react-router';
import CircleType from 'circletype';


const parseFilms = (films = []) => {
    const FILMS_LIMIT = 4
    let filmsCopy = [...films]
    let featuredFilms = []
    let screenFilms = []

    filmsCopy.sort((film1, film2) => new Date(film2.liveDate) - new Date(film1.liveDate)) // sorting films by liveDate desc

    featuredFilms = filmsCopy.filter(film => (film.isFeatured))        
    filmsCopy = filmsCopy.filter(film => (film.nowShowing && !(film.isFeatured))) // !nowShowing is used just for scheduled films

    screenFilms = [...featuredFilms, ...filmsCopy] //placing featured films at the beginning
    screenFilms = [...screenFilms.slice(0, FILMS_LIMIT)]

    return screenFilms
}

const MovieNightScreen = ({screen}) => {
    const circleTextInstance = useRef();    
    const {category, films} = screen    
    let screenFilms = []
    if(films.length > 0) {
        screenFilms = parseFilms(films)
    }

    if(!(screenFilms) || !(screenFilms.length)) { // we don't display screens that don't have featured films
        return null
    }    

    useEffect(() => {
        new CircleType(circleTextInstance.current).radius(400)
    }, [])

    return (
        <Link 
            to={`/movie-night/${category.title.toLowerCase().replace(/ /g, "-")}`}>
        <div className="movie-night-screen">
            <div className="screen-container">
                <div className="screen-content">
                    <div ref={circleTextInstance} className="title">
                        {category.title}
                    </div>
                    <div className="bottom-content">
                        {
                            screenFilms.map((film, i)=> (
                                <div className="featured-title">
                                    {film.title}
                                </div> 
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
        </Link>
    );
};

export default MovieNightScreen;