import React, {Component}      from 'react'
import cn from 'classnames'
import {infoCardIsLockedByID, albumIsLockedByID, filmIsLockedByID} from '../user'
import {parseOnlyNumbers} from '../util/strings'

export default class TitleTab extends Component {
    getPosition() {
        let {position} = this.props.item
        if (this.props.position) position = this.props.position
        return position
    }
    getLabel(){
        let {item} = this.props
        let {title, stickerStyle, labelImage, titleLabel,labelStickerBootLeg} = item
        let position = this.getPosition()
        let bootLegStyle = false;
        if(labelStickerBootLeg ){
            let bootLegType = parseOnlyNumbers(labelStickerBootLeg)
            if(Number.isInteger(bootLegType) && bootLegType  == 2 )bootLegStyle = true
        } 

        if(titleLabel) {
            return <img className="img-centered" src={titleLabel} />
        }
        else if (labelImage && position !== 'left') {
            return <img className="ima" src={labelImage} />
        } else {
            let long = title.length > 23
            if (item.isAlbum || item.isFilm) {
                long = title.length > 25
            }
            let tablabel = cn({'tab-label':true, [stickerStyle || 'no-sticker']:true, bootLegStyle, long})

            return <div className={tablabel}><div dangerouslySetInnerHTML={{__html:title}}/></div>
        }
    }
    render(){

        let {item, index, onClick, onMouseEnter, onMouseLeave} = this.props

        let {id, tab, sticker, stickerStyle, isAlbum, isFilm, isPlaylist, bootLeg, outTake,  labelStickerBootLeg, demo} = item
        let disabled,
        bootLegAlbum  =  false,
        bootLegTrack  =  false,
        outTakeTrack  =  false,
        demoTrack     =  false
        
        if (isAlbum) {
            disabled = albumIsLockedByID(id)
        } else if (isFilm) {
            disabled = filmIsLockedByID(id)
        } else {
            disabled = infoCardIsLockedByID(id)
        }

        let position = this.getPosition()
        let drawerPath = window.location.pathname == "/drawer"

        if (isAlbum || isFilm || isPlaylist) position = 'center'
        if (!isAlbum && bootLeg) position = 'right'
        if(bootLeg) bootLegAlbum  = labelStickerBootLeg ? ` bootLegAlbum ${labelStickerBootLeg}` :' bootLegAlbum'
        if(bootLeg && drawerPath) bootLegTrack  = true
        if(outTake && drawerPath) outTakeTrack  = true
        if(demo && drawerPath) demoTrack = true 

        let tabClass = cn('title-tab', {[position]:true, disabled, bootLeg})
        let tabimage = cn({'tab-image':true, [tab]:true, [position]:true, bootLegTrack, outTakeTrack, demoTrack })

        let stickerimage = null;
        if (sticker && position !== 'right') {
            stickerimage = cn({'sticker-image':true, [sticker]:true, [stickerStyle]:true})
        }
        if(bootLeg){
            stickerimage = cn({'sticker-image':true, [bootLegAlbum]: bootLegAlbum})
        }

        return (
            <div className={tabClass} >
              <div className={tabimage}
                   onMouseEnter={disabled ? onMouseEnter : undefined} onMouseLeave={disabled ? onMouseLeave : undefined}
                   onClick={onClick} data-index={index}></div>
              {sticker || bootLeg ? <div className={stickerimage}></div> : null }
              {this.getLabel()}
            </div>
        )
    }
}
