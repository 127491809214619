import _ from 'lodash';
import { LOAD_POST_IT } from '../constants';

export default function update(state = {}, action) {
    switch (action.type) {
        case LOAD_POST_IT:
            if(action.data) {
                let result = {}
                Object.keys(action.data).forEach(k => {
                    let it = action.data[k]
                    if (it && it.id) {
                        result[it.id] = it
                    }
                })
                return result
            }
            break;
    }
    return state;
}
