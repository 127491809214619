import {playerState} from '../../../actions/tracks'

class Mp3Audio {
  audioExist(){
    const audioEl = window.mp3Player
    if (typeof(audioEl) != 'undefined' && audioEl != null)
    {
      return true
    }
    return false
  }
  playMp3() {
    const audioEl = window.mp3Player
    audioEl.play();
    //update mp3 player state
    playerState(true) 
  }

  pauseMp3() {
    const audioEl = window.mp3Player

    audioEl.pause();
        //update mp3 player state
    playerState(false)
  }

  isMp3Playing() {
    const audioEl = window.mp3Player
    if (audioEl && audioEl.duration > 0 && !audioEl.paused) {
      return true;
    } else {
      return false;
    }
  }
}

export default new Mp3Audio();
