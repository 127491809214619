import removeHash from './remove-hash'

/**
 * Regulate renderd links in rendered content
 *
 * @param {array} containers
 */
export default (containers) => {
  let router
  const regulate = e => {
    // not a link, or targeted
    if (!e.target.matches('a[href]:not([target="_blank"]), a[href]:not([target="_blank"]) *')) return

    // link to a .(html|html) page
    if (e.target.href.indexOf('.htm') !== -1) return

    const container = containers.find(container => e.target.closest(container) !== null)
    // not in the containers
    if (!container) return

    e.preventDefault()

    router && router.push(router.createLocation(removeHash(e.target.getAttribute('href'))))
  }

  return ctx => {
    router = ctx

    document.removeEventListener('click', regulate, false)
    document.addEventListener('click', regulate, false)
  }
}