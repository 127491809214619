import _ from 'lodash';
import store from '../store';
import {parseArticle} from './parse-article'
import compareReleaseDate from '../util/compare-release-date.js'
import {updateNewsArticle} from '../actions/articles'
import {parseOnlyNumbers}  from '../util/strings'

function tryParseContrarianAd(data){

    if (!_.has(data, 'fields')) return
    if (!_.has(data, 'fields.image')) return

    const image = data.fields.image
    if (!_.has(image, 'fields.file.url')) return
    if (!_.has(image, 'fields.file.details.image.height')) return

    const url    = image.fields.file.url
    const height = image.fields.file.details.image.height
    const link   = data.fields.link || ''

    return {url, height, link}
}

let _aAds, _bAds

setInterval(()=>{
    if (!_aAds) return
    _aAds = _.shuffle(_aAds)
    _bAds = _.shuffle(_bAds)
}, 4 * 60 * 60 * 1000) //4 hours

function parseNewsPage(data) {
    if (!_aAds) _aAds = _.shuffle(_.compact(data.aAds.map(tryParseContrarianAd)))
    if (!_bAds) _bAds = _.shuffle(_.compact(data.bAds.map(tryParseContrarianAd)))

    data.aAds = _aAds
    data.bAds = _bAds
    return data;
}

let newsData = undefined;
export function getNewsData() {
    if (!newsData) {
        newsData = store.getState().newsPage.page
    }
    return newsData
}

let pagesData = undefined;

export function getPagesData() {
    if (!pagesData) {
        pagesData = store.getState().contrarianPage.all
    }
    return pagesData
}


function compareDate(a, b){
    if      (a.year < b.year)   return -1
    else if (b.year < a.year)   return  1
    else if (a.month < b.month) return -1
    else if (b.month < a.month) return  1
    else if (a.day < b.day)     return -1
    else if (b.day < a.day)     return  1
    else                        return  0
}


export function addArticleToColumn(newsArticle, allArticles) {
    if (newsArticle == undefined || 
        newsArticle == null) {
    return [];
  }
    for (let i = 0; i < allArticles.length; i++) {
      const order = compareDate(newsArticle.releaseDate, allArticles[i].releaseDate);
      if (newsArticle.sticky || order === 1) {
        return allArticles.slice(0, i).concat([newsArticle]).concat(allArticles.slice(i))
      } else if (order === 0) {
        const priorityShift = newsArticle.order > allArticles[i].order ? 0 : 1;
        return allArticles.slice(0, i + priorityShift).concat([newsArticle]).concat(allArticles.slice(i + priorityShift));
      }
    }
    return allArticles.concat(newsArticle);
  }


export function getArticleData(page){
    return store.getState().newsArticle.articlesByPage;
}
export function getArticleById(id, page) {
    return store.getState().newsArticle.all.find(article => article.id.toLowerCase() === id.toLowerCase())
}