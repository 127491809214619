import React, {Component} from 'react'
import PropTypes from 'prop-types'

export default class GlobalMapIcon extends Component {
    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    onClick(){
        let {router} = this.context
        router.push(router.createLocation('/map'))
    }
    render() {
        return (
            <div className="global-map-icon" onClick={this.onClick}></div>
        )
    }
}
GlobalMapIcon.contextTypes = {
    router: PropTypes.object.isRequired
}
