export const PERFORMANCE_LOG_BASE_URL = '/performance-log/tour?tourid='
export const NYA_FREE = 'NYA-FREE'
export const NYA_YEARLY = 'NYA-YEARLY'
export const NYA_MONTHLY = 'NYA-MONTHLY'
export const NYA_UNLIMITED = 'NYA-UNLIMITED'
export const NYA_UNLIMITEDV2 = 'NYA-UNLIMITEDV2'
export const NYA_UNLIMITEDV2_MONTHLY = 'NYA-UNLIMITEDV2-MONTHLY'
export const ACTIVE = 'ACTIVE'
export const CANCELLED = 'CANCELED'
export const UNLIMITED = "UNLIMITED"
export const UNLIMITEDV2 = "UNLIMITEDV2"
export const CLASSIC   = "CLASSIC"
export const PATRON = 'PATRON'
export const RUST = "RUST"
export const RUSTV2 = "RUSTV2"
export const BRIO_VIDEO = "https://connect.orastream.com/show/"
export const VIMEO_URL = "https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/"



