import React          from 'react';
import VerticalSlider from './vertical-slider';


export default class LyricsSlider extends VerticalSlider {
    render(){
        let thumbStyle = {
            top: `${this.props.pct}%`
        }
        return (
            <div className="lyrics-slider-container">
              <div ref="line"
                   className="lyrics-slider-line"></div>
              <div ref="thumb"
                   className="lyrics-slider-thumb-wrapper"
                   style={thumbStyle}>
                <div ref="thumbImage"
                     onMouseDown={this.mouseDown}
                     onTouchStart={this.touchStart}
                     className="lyrics-slider-thumb"></div>
              </div>
            </div>
        );
    }
}
