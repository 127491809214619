import { ENTRIES_LOADED } from '../constants';

export default function update(state = [], action) {
  const {type, entries} = action
  switch (type) {
    case ENTRIES_LOADED:
      if (entries.plan) {
        let result = []
        Object.keys(entries.plan).forEach(k => {
            let plan = entries.plan[k]
            if (plan && plan.id) {
              const {id, name, benefitsJSON: benefits, banner, buyButtonText, displayName} = plan
              result.push({id, name, benefits, banner, buyButtonText, displayName})
            }
        })
        return result
      }
      break;
  }
  return state;
}
