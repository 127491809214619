import _ from 'lodash'
import { ENTRIES_LOADED } from '../constants';

const initialState = {all:[]};

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
           
            if(action.entries.requestValidation) {
                const result = initialState;
                Object.keys(action.entries.requestValidation).map(key => {
                    
                    result.all.push(action.entries.requestValidation[key]);
                })
                return result
            }
            break;
    }
    return state;
}
