import React, { Component } from "react";
import _ from "lodash";
import ScrollBars from "react-scrollbar";
import moment from "moment";

export default class UserRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { requestsSelected, performanceSelected, hideRequests } = this.props;

    const { name = "", location = "", stringDate = "" } = performanceSelected;
    
    //Organize by lastest date 
    requestsSelected = requestsSelected.sort((a,b)=>{
      let aDate = new Date (a.date.year, a.date.month - 1, a.date.day);
      let bDate = new Date (b.date.year, b.date.month - 1, b.date.day);
      return bDate - aDate
    })

   
    return (
      <div className="user-requests-modal">
        <div className="user-requests-inner">
          <div className="perf-info">
            <div className='close-btn' onClick={()=> hideRequests()}></div>
            <div className="header">
              <p>
                {name}, {location}
              </p>
              <p>{stringDate}</p>
            </div>
            <ScrollBars>
            <div>
                {requestsSelected.map((request, key) => {
                  let { userName ='', date, message } = request;
                  let initials = userName.match(/\b(\w)/g)
                  if(initials) initials.join('')
                  
                  const { month, day, year } = date;
                  date = `${moment.monthsShort(month - 1)} ${day}, ${year}`;
                  return (
                    <div className="perf-request-wrapper" key={key}>
                        <div className="req-info">
                          <span className="initials"><p>{initials}</p></span>
                          <span className="info">
                          <p className='req-title'>Requested By:</p>
                          <p>{userName}</p>
                          <p>{date}</p>
                          </span>
                      </div>
                      <div className='req-message'>{message}</div>
                    </div>
                  );
                })} 
            </div>
            </ScrollBars>
          </div>
        </div>
      </div>
    );
  }
}