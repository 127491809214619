import React, {Component} from 'react'
import _ from 'lodash'
import cn from 'classnames'
import {fetchReceipts} from '../services/api'

/*
   initial -> fetchOK
      +-----> fetchFail -> initial
*/


export default class ReceiptsPanel extends Component {
    constructor(props, ctx){
        super(props, ctx)
        this.state = {state:'initial'}
        this.counter = 0

        this.fetchFailClickTryAgain = this.fetchFailClickTryAgain.bind(this)
    }
    getContent(){
        return this[this.state.state]()
    }
    _fetchReceipts(){
        this.counter++
        const counter = this.counter

        fetchReceipts().then(
            receipts => {
                if (!this.mounted) return
                if (this.counter !== counter) return
                this.setState({state:'fetchOK', receipts})
            },
            () => {
                if (!this.mounted) return
                if (this.counter !== counter) return
                this.setState({state:'fetchFail'})
            }
        )
    }
    componentDidMount(){
        this.mounted = true
        if (this.state.state === 'initial') this._fetchReceipts()
    }
    componentDidUpdate(prevProps, prevState){
        const {state} = this.state
        if (prevState.state !== state && state === 'initial') {
            this._fetchReceipts()
        }
    }
    componentWillUnmount(){
        this.mounted = false
    }
    initial(){
        return (
            <div className="content tall">
              <div className="message">
                Fetching Your Receipts...
              </div>
            </div>
        )
    }
    fetchOK(){
        const {receipts}  = this.state,
              hasReceipts = !!receipts.length

        return (
            <div className="content tall">
              { hasReceipts &&
                <div className="header">
                  <div className="field field-0">DATE</div>
                  <div className="field field-1">ORDER ID</div>
                  <div className="field field-2">Payment</div>
                  <div className="field field-3">PRICE</div>
                  <div className="field field-4">TYPE</div>
                </div>
              }
              { hasReceipts &&
                receipts.map((item, idx)=>{
                    const { date, number, payment, price, type } = item
                    return (
                        <div className="charge" key={idx}>
                          <div className="field field-0">{date}</div>
                          <div className="field field-1">{number}</div>
                          <div className="field field-2">{payment}</div>
                          <div className="field field-3">{price}</div>
                          <div className="field field-4">{type}</div>
                        </div>
                    )
                })
              }
              { !hasReceipts &&
                <div className="message">You do not have any receipts.</div>
              }
            </div>
        )
    }
    fetchFail(){
        return (
            <div className="content">
              We're sorry, there was an error fetching your receipts.
              <div className="button" onClick={this.fetchFailClickTryAgain}>TRY AGAIN</div>
            </div>
        )
    }
    fetchFailClickTryAgain(){
        this.setState({state:'initial'})
    }
    render(){
        return (
            <div className="panel receipts-panel">
              <div className="title">Your Receipts</div>
              {this.getContent()}
            </div>
        )
    }
}
