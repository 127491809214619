import React from 'react'

const  WidgetState = (props) => {
    const {bitrate, playerState, loading} = props
    const player = loading ? 'LOADING' : playerState
    switch(player){
        case 'PAUSED':
        return  <div className="widget-state paused">PAUSED</div>
        case 'LIVEPLAY':
            return <div className="widget-state bitrate">{bitrate}</div>
        case 'LOADING':
            return <div className="widget-state loading">LOADING ...</div>
        default:
            return <div className="widget-state ready">READY</div>
    }
}


export default WidgetState;