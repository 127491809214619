import superagent from 'superagent'
import {VIMEO_URL} from '../util/contants/url_constants'

export const getThumbnail = (videoId) => {
    return new Promise((resolve, reject) => {
        superagent.get(VIMEO_URL + videoId)
        .end((err, res)=>{
            if (err) {
                if (typeof(reject) === 'function'){
                    return reject(err);
                } 
                return null;
            }
            return(JSON.parse(res.text))
        }).then((data) => {
            if( data && data.body && data.body.thumbnail_url){
                resolve(data.body.thumbnail_url)
            }else{
                resolve(null);
            }
        })
    })
}