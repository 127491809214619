import _ from 'lodash';
import { ENTRIES_LOADED } from '../constants';

export default function update(state = {page:undefined}, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.newsPage) {
                let result = undefined
                Object.keys(action.entries.newsPage).forEach(k => {
                    //there should only be one of these...
                    result = {page: action.entries.newsPage[k]}
                })
                return result
            }
            break;
    }
    return state;
}
