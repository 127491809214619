import React, {Component} from 'react'
import PropTypes          from 'prop-types'
import Audio              from './audio'
import PlaylistManager    from './playlist-manager'
import { getAlbumById} from '../../drawer-items'
import {setCurrentTrack}  from '../../currentDate'
import {PlayerControls, ScrubControl, VolumeControl, Meter, bitrate_to_value}from './new-player'

import Mp3Audio from './mp3-player/mp3-audio'

export default class Mp3Player extends Component {
    constructor(a, b){
        super(a, b)
        let {prevDisabled, nextDisabled} = PlaylistManager.prevNextDisabled()

        this._isMounted = false;
        this.state = {
            player: "stopped",
            playing : false,
            paused:0,
            selectedTrack: null,
            playingTrack:[], prevDisabled, nextDisabled,
            volume:100, playing:false, loading: false,
            state:null, samprate:0, bitrate:320, position:0,
            elapsed:'--:--', remaining:'--:--'
        }
        this.setVolume        = this.setVolume.bind(this)
        this.playPause        = this.playPause.bind(this)

        this.scrubDragStart   = this.scrubDragStart.bind(this)
        this.scrubDragMove    = this.scrubDragMove.bind(this)
        this.scrubDragEnd     = this.scrubDragEnd.bind(this)

        this.clickArt   = this.clickArt.bind(this)
        this.clickTrack = this.clickTrack.bind(this)

        this.onKeyPress = this.onKeyPress.bind(this)
        this.getTime = this.getTime.bind(this)
        this.updateState = this.updateState.bind(this)
        this.playMp3 = this.playMp3.bind(this)

    }
    componentDidMount(){
        let playingTrack = this.props.playingTrack
        this._isMounted = true;
        if(playingTrack) {
            window.mp3Player.src= playingTrack.mp3audio
            window.mp3Player.load()  
            this.playMp3(playingTrack)
        }
         window.mp3Player.addEventListener("timeupdate", this.updateState);
    }
    componentWillUnmount(){
        this._isMounted = false;
        if(window && mp3Player){
            window.mp3Player.pause()
            window.mp3Player.removeAttribute('src')
            window.mp3Player.removeEventListener("timeupdate",this.updateState)
        }

    }

    updateState(e){
        const {currentTime, duration, paused} = e.target
        this.setState({
            currentTime: currentTime,
            duration: duration,
            playing: !paused
          });
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.playingTrack !== this.state.playingTrack){
                let playingTrack= nextProps.playingTrack
                window.mp3Player.pause()
                window.mp3Player.removeAttribute('src')
                if(playingTrack && playingTrack.mp3audio) {
                  window.mp3Player.src = playingTrack.mp3audio;
                  window.mp3Player.load() 
                  this.playMp3(playingTrack)
                }
        }
    }


   getTime(time) {
        if(!isNaN(time)) {
          return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
        }
    }
    
    onKeyPress(e) {
        let {router} = this.context;
        const currentLocation = router.getCurrentLocation().pathname;
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || currentLocation.includes("/movie-night") || currentLocation.includes("/video")) return
        if (e.code === "Space") {
            if (this.state.playingTrack) this.playPause()
        }
    }
    playMp3(playingTrack){
    const audioPromise = window.mp3Player.play()
    if(audioPromise !== undefined){
        audioPromise.then(_=>{
            this.setState({playing: true, playingTrack })
        }).catch(err=>{
            this.setState({playing: false, playingTrack:[]})
        })
    }
    }
    playPause(){
        let {playing} = this.state
        playing = !playing
        if (playing)  Mp3Audio.playMp3()
        else          Mp3Audio.pauseMp3()

        this._isMounted &&this.setState({playing})
    }
    scrubDragStart(){
        this._isMounted &&this.setState({scrubbing:true, scrubState:this.state.position})
    }
    scrubDragMove(pct){
        this._isMounted &&this.setState({scrubState:pct})
    }
    scrubDragEnd(){
        const{duration} = this.state
         window.mp3Player.currentTime = duration*(this.state.scrubState/100)

        setTimeout(()=>{
            this._isMounted &&this.setState({scrubbing:false})
        }, 1500)
    }
    setVolume(pct){
        this._isMounted &&this.setState({volume:pct})
         window.mp3Player.volume = pct/100
    }
    clickArt(){ 
        let {playingTrack: track} = this.props
        if (!track) return
        let {router} = this.context

        let album = track.album || false ;
        let albumPosition = album && getAlbumById(album.id) || false ; 
        if(albumPosition) setCurrentTrack(albumPosition)

        let route = album.id && `/album?id=${album.id}` || false
        if (track && track.performanceLog && track.url) return;
        
        router.push(router.createLocation(route))
    }
    clickTrack(){
        let {playingTrack: track} = this.props
        let {router} = this.context
        let route = track.id && `/info-card?track=${track.id}` || false

        if (!track || track.listOnly) return

        if (track && track.performanceLog && track.url) route = track.url;

        if(route) router.push(router.createLocation(`${route}`))
    }
    render(){
        let {volume, playing, loading, bitrate,
             scrubbing, scrubState,
             prevDisabled, nextDisabled
        } = this.state

        const{ themeAperance = 'light-mode',playingTrack} = this.props
        const {} = playingTrack
        let disabled = !playingTrack

        const currentTime = this.getTime(this.state.currentTime)
        const duration = this.getTime(this.state.duration)
        const position = this.state.currentTime/this.state.duration

        let title, backgroundImage, performer, location, country
        if (playingTrack) {
            title = playingTrack.title
            backgroundImage = `url('${playingTrack.playerImage}')`
            performer = playingTrack.performer
            location = playingTrack.location
            country = playingTrack.country

        } else {
            title = ''
            backgroundImage = ''
            performer = ''
        }

        let imageStyle = {backgroundImage}
        let sample = "MP3 TRACK"
        let progress = scrubbing ? scrubState : position * 100
        

        let bwbgStyle = {height:'0%'}
        return (
            <div className="new-player-wrapper">
              <div className={`new-player ${themeAperance}`}>
                <div className="currently-playing">
                  <div className="bandwidth-background-wrapper">
                    <div className="bandwidth-background" style={bwbgStyle} />
                  </div>
                  <div className="info-wrapper" onClick={this.clickTrack}>
                      <div className="track-title"
                       >{`${title}${location ? ` - ${location}, ${country}`: ""}`}</div>
                      <div className="track-performer">{performer}</div>
                      <div className="time-indicators">
                        <div className="left">{currentTime}</div>
                        <div className="center">{sample}</div>
                        <div className="right">{duration}</div>
                      </div>
                  </div>
                  <audio className="audio-element" ref={ref =>  window.mp3Player = ref}/>
                  <div className="album-artwork"
                        onClick={this.clickArt}
                        style={imageStyle} />
                  <ScrubControl
                      dragStart={this.scrubDragStart}
                      onScroll={this.scrubDragMove}
                      dragEnd={this.scrubDragEnd}
                      pct={progress}
                  />
                </div>
                <div className="middle-layer" />
                <Meter bitrate={bitrate} />
                <PlayerControls
                    disabled={disabled}
                    prevDisabled={prevDisabled}
                    nextDisabled={nextDisabled}
                    loading={loading}
                    playing={playing}
                    onPlayPause={disabled ? undefined : this.playPause}
                    onPrev={undefined}
                    onNext={undefined}
                />
                <div className="quality-switch low"/>
              </div> 
              <VolumeControl onScroll={this.setVolume} pct={volume} />
            </div>
        )
    }
}

Mp3Player.contextTypes = {
    router: PropTypes.object.isRequired
}
