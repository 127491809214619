import React, {Component} from 'react'
import ReactModal         from 'react-modal'
import cns                from 'classnames'
import { scale }          from '../scale'

export default class Modal extends Component {
    render(){
        const { isOpen, onRequestClose, overlayClassName, className } = this.props
        return (
            <ReactModal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                overlayClassName={cns("modal-wrapper", {[overlayClassName]: overlayClassName})}
                className={cns('modal', {[className]: className})}
                style={{ overlay: {}, content: {transform: `scale(${scale()})`} }}
            >
                { this.props.children }
            </ReactModal>
        )
    }
}
