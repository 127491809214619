import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from './date-utils';
import { updateMetadata } from '../actions/metas';
import { connect } from 'react-redux';
import { updateArticleView, updateArticle } from '../actions/articleView';
import EventBusPublisher from '../newspaper-iframe/event-bus-publisher';
import EventBusSubscriber from './events/event-bus-subscriber';
import SplashScreen from '../splash-screen';
import PlaylistManager from "../components/new-player/playlist-manager";

function getDate() {
 return formatDate(new Date());
}

function externalizeHref(a) {
 if (location.hostname === a.hostname || !a.hostname.length) return;
 a.setAttribute('target', '_blank');
}

export function Newspaper(props) {
 const { router } = props;
 const query = router?.location?.query || {};
 const isArticleView = router?.location?.pathname?.includes('article') && 'id' in query;
 const isTop40Tracks = router?.location?.pathname?.includes('top-40-tracks');
 const articleId = query.id;

 const _page = parseInt(props.params ? props.params.page : 1) || 1;
 const _baseURL = process.env.SITE_URL + '/times-contrarian';

 const [page, setPage] = useState(_page);
 const [baseURL, setBaseURL] = useState(_baseURL);
 const [isLoadingIFrame, setIsLoadingIFrame] = useState(true);

 useEffect(() => {
  if (isTop40Tracks) {
   setBaseURL(`${_baseURL}/top-40-tracks`);
   return;
  }

  if (isArticleView) {
   setBaseURL(`${_baseURL}/${page}/article?id=${articleId}`);
   return;
  }

  setBaseURL(`${_baseURL}/${page}`);
 }, [isTop40Tracks, isArticleView, page, articleId]);

 useEffect(() => {
  updateMetadata({ title: 'NYA Times Contrarian' });
  // Replace URL when update page on iframe
  EventBusSubscriber.subscribeToChangePage(router);
  EventBusSubscriber.subscribeToPlayTrackFromIframe(PlaylistManager);
 }, []);

 useEffect(() => {
  const iframe = document.getElementById('newspaper');
  iframe.onload = () => setIsLoadingIFrame(false);

  return () => {
   iframe.onload = null;
  };
 }, [baseURL, page]);

  return (
   <div className="newspaper-page">
    {isLoadingIFrame && <SplashScreen style={{ height: '100vh', width: '100%' }} loadState={100} />}
    <iframe id="newspaper" src={`${baseURL}`} style={{display: `${isLoadingIFrame ? 'none' : 'block'}`}} ref={iframe => {
      try {
        if (iframe && iframe.contentWindow && iframe.contentWindow.navigator) {
          const newUserAgent = `${iframe.contentWindow.navigator.userAgent} nya-embedded`;
          Object.defineProperty(iframe.contentWindow.navigator, 'userAgent', {
            value: newUserAgent,
            writable: true
          });
        }
      } catch (error) {
        console.error('Error modifying the iframe\'s userAgent:', error);
      }
    }}/>
   </div>
  );
}

Newspaper.contextTypes = {
 router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
 return {
  contrarianPagesTitles: state.commonValues.contrarianPagesTitles,
  pagesData: state.contrarianPage.all,
  data: state.newsPage.page,
  movietonePosition: state.commonValues.movietonePosition,
  isArticleView: state.articleView.show,
  articleData: state.articleView.article,
 };
};

export default connect(mapStateToProps, { updateArticleView, updateArticle })(Newspaper);
