import React, {Component} from 'react'
import {inverseScale} from '../scale.js'
import {updateArticleView} from '../actions/articleView'
import { connect }         from 'react-redux';

let positions = {
    left: '96px',
    center: '114px',
    right: '132px'
}

class BackNextSwitch extends Component {
    constructor(props, context){
        super(props, context)

        this.click = this.click.bind(this)
    }
    toggle(key, then){
        if (this._timeout) return
        const {updateArticleView, isArticleView} = this.props
        this.refs.switch.style.left = positions[key]
        this._timeout = setTimeout(()=>{
            this.refs.switch.style.left = positions.center
            this._timeout = null
            if(isArticleView)updateArticleView(false);
            history[then]()
        }, 200)
    }
    click(e){
        let width = 272, half = width / 2
        let left  = (e.pageX * inverseScale()) - this.refs.element.offsetLeft

        if (left < half) this.toggle('left', 'back');
        else             this.toggle('right', 'forward')
    }
    render(){
        return (
            <div className="back-next-switch" ref ="element" onClick={this.click}>
              <div className="back-next-switch-wrapper">
                <div className="background" />
                <div className="back" />
                <div className="next" />
                <div className="switch" ref="switch" />
              </div>
            </div>
        )
    }
}
const mapStateToProps = function (state) {
    return {
        isArticleView: state.articleView
    };
  };

export default connect(mapStateToProps,{updateArticleView})(BackNextSwitch)