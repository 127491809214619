const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export function formatDate(date) {
    const weekday    = days[date.getDay()] || ''
    const dayofmonth = date.getDate()
    const month      = months[date.getMonth()] || ''
    const year       = date.getFullYear();

    return `${weekday}, ${month} ${dayofmonth}, ${year}`
}

export function formatDateShort(date) {
  const dayofmonth = date.getDate()
  const month      = date.getMonth() + 1
  const year       = date.getFullYear().toString().substr(2,2)
  return `${month}-${dayofmonth}-${year}`
}

export function parseDate (str) {
  return {
    year: parseInt(str.substr(0, 4), 10),
    month: parseInt(str.substr(5, 7), 10),
    day: parseInt(str.substr(8, 10), 10)
  }
}

export function formatParsedDate(data, short = false) {
    const {year, month, day} = data
    const date = new Date()
    date.setYear(year)
    date.setMonth(month - 1)
    date.setDate(day)
    return short ? formatDateShort(date) : formatDate(date)
}
