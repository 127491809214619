import cn                 from 'classnames'
import React, {Component} from 'react'
import TimelineEventInfo  from './timeline-event-info'

const iconTypeToClass = {
    "Black Icon":'black',
    "White Icon":'white',
    "Tag Icon":'tag',
    "Video Icon":'video'
}

let calcPosition = ({month, day}) => {
    return (((1/12) * (month-1)) + ((1/31) * day * (1/12))) * 100
}

export default class TimelineEvent extends Component {
    constructor(props){
        super(props)
        this.onClick     = this.onClick.bind(this)
        this.buttonClick = this.buttonClick.bind(this)
    }
    buttonClick(e){
        e.preventDefault()
        e.stopPropagation()

        let {event} = this.props
        let {moreInfo, video, photos} = event

        if (moreInfo && moreInfo.trim().length) {
            this.props.showMoreInfo(event)
        } else if (video) {
            this.props.showVideo(video)
        } else if (photos) {
            this.props.showPhotos(event)
        } else {
            console.error("don't know what to do with button click for event: ", event)
        }
    }
    onClick(e){
        if (e.target !== this.refs.icon) return 

        const clientWidth = document.body.clientWidth
        const pageX = e.pageX
        let makeLeftOffset = 0
        let makeRightOffset = 0

        if (
            clientWidth - pageX > clientWidth / 2
            && pageX < 180
            ) {
            makeLeftOffset = 190 - pageX
            makeRightOffset = 0
        }

        if (
            clientWidth - pageX < clientWidth / 2
            && pageX > clientWidth - 180
            ) {
            makeRightOffset = 190 - (clientWidth - pageX)
            makeLeftOffset = 0
        }
        
        e.preventDefault()
        e.stopPropagation()
        let {id} = this.props.event
        this.props.onClick(id, makeLeftOffset, makeRightOffset)
    }
    render(){
        let {event, selected} = this.props
        let {iconType, date}  = event

        let type  = iconTypeToClass[iconType]
        let cx    = cn('timeline-event', type, {selected})
        let style = {left: calcPosition(date) + '%'}

        return (
            <div className={cx} style={style}>
              <div className="bg" ref="icon" onClick={this.onClick} />
              {selected &&
               <div className="info-wrapper">
                 <TimelineEventInfo event={event}
                                    showMoreInfo={this.props.showMoreInfo}
                                    showVideo={this.props.showVideo}
                                    showPhotos={this.props.showPhotos}
                                    onClose={this.props.onClose} />
               </div>}
            </div>
        )
    }
}
