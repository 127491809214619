import React, {Component} from 'react'
import PropTypes from 'prop-types'

export default class GlobalMenuIcon extends Component {
    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    onClick(){
        let {router} = this.context
        router.push(router.createLocation('/menu'))
    }
    render() {
        return (
            <div className="global-menu-icon" onClick={this.onClick}></div>
        )
    }
}
GlobalMenuIcon.contextTypes = {
    router: PropTypes.object.isRequired
}
