import {
    ENTRIES_LOADED, FAILED_TO_LOAD_ENTRIES, DRAWER_LOADED
} from '../constants';

const initialState = "pending"

export default function update(state = initialState, action) {
    switch (action.type) {
        case DRAWER_LOADED:
            return "drawerLoaded";
        // case ENTRIES_LOADED:
        //     return "loaded"
        // case FAILED_TO_LOAD_ENTRIES:
        //     return "failed"
    }
    return state;
}
