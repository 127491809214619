import _ from "lodash";
import { UPDATE_TOGGLE } from "../constants";

const initialState = { original: true, completion: false, additional: true , bootleg:true };

export default function update(state = initialState, action) {

  switch (action.type) {
    case UPDATE_TOGGLE:
      if (action.toggle) { 
        return { ...state, [action.toggle.type]: !action.toggle.status }
      }
      break;
  }
  return state;
}
