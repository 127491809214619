import React, {Component} from 'react'
import {Link} from 'react-router'
import removeHash from '../services/remove-hash'

function externalizeHref(el){
    let links = el.querySelectorAll('a')
    for (let i = 0; i < links.length; i++) {
        let a = links[i]
        if (location.hostname === a.hostname || !a.hostname.length) continue;
        a.setAttribute('target', '_blank')
    }
}

export default class Ad extends Component {
    componentDidMount(){
        if (this.refs.link) externalizeHref(this.refs.link)
    }
    render() {
        const {link, image} = this.props
        const style = {objectFit:'cover'}
        const result = <img src={image} className="ad" style={style} />
        const isAlbumLink = link.includes('album')

        let path = removeHash(link)
        if (path && isAlbumLink) {
            path = `${path}&b=true`
        }

        if (path) {
            if (path.indexOf('http') === 0) {
                return (
                    <div ref="link">
                        <a href={path}>{result}</a>
                    </div>
                )
            }

            return (
                <Link to={path}>{result}</Link>
            )
        }

        return result
    }
}
