import React, { Component }            from 'react';
import PropTypes                       from 'prop-types';
import ProgressBar                     from 'react-progress-bar-plus';
import LoadingIndicator                from './components/loading-indicator';
import VerticalCenter                  from './components/vertical-center';
import {pathInResponsiveViews} from "./route-utils";

class SplashScreen extends Component {
    render(){
        const {isEmbed ,style, loadState } = this.props
        return (
            <VerticalCenter style={style || {width:'100%', height:'100%'}}>
              <center><LoadingIndicator  isEmbed={isEmbed} /></center>
              {!pathInResponsiveViews() && <div style={{textAlign: 'center'}}>
                <ProgressBar percent={loadState} autoIncrement={true} intervalTime={300} spinner={false}/>
              </div>}
            </VerticalCenter>
        )
    }
}

SplashScreen.defaultProps = {
    loadState: 0,
}

SplashScreen.propTypes = {
    loadState: PropTypes.number.isRequired,
}

export default SplashScreen
