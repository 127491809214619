import _ from 'lodash'
import { ENTRIES_LOADED, UPDATE_VIDEO_STATUS, VIDEO_PAUSED } from '../constants';

const initialState = {all:[], byId:{}, actualVideo: {videoId: null, status: VIDEO_PAUSED}}

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.extraVideoaudio) {
                let byId = {}, byContentfulId = {}
                let all = _.map(action.entries.extraVideoaudio, video=>{
                    byId[video.id] = video
                    byContentfulId[video._id] = video
                    return video
                })
                return {all, byId, byContentfulId}
            }
            break;
        case UPDATE_VIDEO_STATUS:
            return {
                ...state,
                actualVideo: {
                    videoId: action.videoId,
                    status: action.status
                }
            }
            break;
    }
    return state;
}
