import React, {Component} from 'react'
import marked             from 'marked'
import {getArticleById}   from '../services/api'
import PropTypes          from 'prop-types'
import VimeoPlayer        from '../components/vimeo-player'
import VimeoHelper from '../util/vimeo-helper'
import SplashScreen from '../splash-screen'
import {updateArticle} from '../actions/articleView'
import ShareButton from './shareButton';

let renderer = new marked.Renderer()

renderer.paragraph = text => {
  let paragraph = `<p>${text}</p>`;
  if (text.includes('<img'))
    paragraph = `<p class="article-image-container">${text}</p>`;
  return paragraph;
}

function getArticleImages(data) {
    if (data.vimeoId) {
        return <div className="video-container">
                <VimeoPlayer videoId={parseInt(data.vimeoId, 10)} />
            </div>
    } else if (data.type === 'two-image') {
        const url0 = `url(${data.images[0]})`
        const url1 = `url(${data.images[1]})`
        return (
            <div className="images two-image">
              <div className="image left" style={{backgroundImage:url0}} />
              <div className="image right"style={{backgroundImage:url1}} />
              <div className="credits">{data.credits}</div>
              <div className="clearfix" />
            </div>
        )
    } else if (data.type === 'one-image') {
        const url = `${data.images[0]}?w=970`
        return (
            <div className="images one-image">
              <img src={url} />
              <div className="clearfix" />
              <div className="credits">{data.credits}</div>
            </div>
        )
    } else {
        return null
    }
}

export default class NewsArticle extends Component {
    constructor(props) {
        super(props)
        this.articleRef = React.createRef();
        this.vimeoVideos = null;
        this.state = {
            data: {},
            loaded: false,
        }
        this.getArticleData = this.getArticleData.bind(this)
    }
    bindVideoEvents() {
        const container = this.refs.content;
        const iframes = container.querySelectorAll("iframe");
        this.vimeoVideos = new VimeoHelper(iframes);
    }
    componentWillUnmount() {
        this.vimeoVideos && this.vimeoVideos.destroy();
        updateArticle({})
    }
    componentDidMount() {
        const {data = {} , showArticle = false} = this.props

        //set up state from news props
        if(showArticle){
                setTimeout(()=>{
                    this.setState({ data, loaded: true})
                },1000)
           return;
        }
        if(this.props.location ){
            const {query :{id} = {} } = this.props.location
           this.getArticleData(id)
        }

    }
    getArticleData(id){
        const hasSearchedQuery = localStorage.getItem('searchedQuery') || false;
        const properties ={
            'bodyHeadline':true,
            'bodyText':true
        }
        getArticleById(id).then((data) => {
          const { article = {} } = data;
          const types = ['headline', 'bodyText'];
          if (data.article && hasSearchedQuery) {

            for (let property in article) {
              if (
                article[property] &&
                properties[property] &&
                typeof article[property] == "string"
              ) {
                let queryTerms = hasSearchedQuery.split(" ");

                if(types.includes(property)){
                  //get image snippet
                  let urlInstance =article[property] && article[property].substring(article[property].lastIndexOf("![") + 1,article[property].indexOf("jpg)"))
                  for (let i in queryTerms) {
                    let word = queryTerms[i].replace(/[\’\“\”]/g, "'").replace(/[^\s\w\d]/g, '\\$&');
                    let regEx = new RegExp(word, "ig");

                    let highlight = `<span className="highlight">${word}</span>`;

                    if( word.length > 3){
                      //replace highlighted item
                      article[property] = article[property].replace(regEx,highlight);
                    }
                    //image snippet
                    let image = article[property] && article[property].substring(article[property].lastIndexOf("![") + 1,article[property].indexOf("jpg)")) ||false;

                        // fix Images url
                        if (image) {
                          article[property] = article[property].replace(image,urlInstance);
                        }
                  }
                }

                //clear localstorage query
                localStorage.removeItem("searchedQuery");
              }
            }
          }
          this.setState({
            data: article,
            loaded: true,
          });
        });
    }

    componentWillReceiveProps(newprops){

        if(this.props.location){
          const {query :{id : nextId} = {} } = newprops.location
          const {query :{id} = {} } = this.props.location;

          if(nextId !== id ){
            //update the state of article
            this.getArticleData(nextId);
          }
        }
      }

    render() {
        const { data, loaded } = this.state
        if (_.isEmpty(data)) {
            // TODO: 404
            return null
        }
        const { headline, author, dateText, bodyText, column, bodyHeadline, headlinePhotoCaption, subBodyHeadline, page } = data

        return (
            <div id='main-content-article' className="main-content article" ref={this.articleRef}>
                {loaded ?
                    <div id='column-article' className="column article">
                    {bodyHeadline ? <div id='main-title' className={'title ' + column + ` news-${page.slice(-1)} main-title`} ref="content" dangerouslySetInnerHTML={{__html:marked(bodyHeadline || '')}} /> : <div id='main-title' className={'title ' + column + ` news-${page.slice(-1)} main-title`}><h1>{headline}</h1></div>}

                    {/* caption */}
                    <div className="headline-photo-caption">
                      <span>{headlinePhotoCaption}</span>
                    </div>

                    <ShareButton articleRef={this.articleRef}/>

                    {/* subbody */}
                    <div className='subtitle-container'>
                      <div id='subtitle' className={'title ' + column + ` news-${page.slice(-1)} subtitle`} ref="content" dangerouslySetInnerHTML={{__html:marked(subBodyHeadline || '')}} />
                    </div>


                      <div className='article-text-container'>
                          <div className="byline">
                              <span className="author">{author}</span>
                              <span className="date">{dateText}</span>
                          </div>
                          {getArticleImages(data)}
                          <div id='article-text' ref="content" className="article-text"
                              dangerouslySetInnerHTML={{__html:marked(bodyText || '', {renderer})}}
                          />
                      </div>
                    </div>
                : <SplashScreen style={{height: '100vh', width: '100%'}} loadState={100} />
                }
            </div>
        )
    }
}
NewsArticle.contextTypes = {
    router: PropTypes.object.isRequired
}
