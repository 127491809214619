import React, {Component} from 'react'
import AdColumn           from './ad-column'
import Billboard          from './billboard'
import {updateMetadata}   from '../actions/metas'

export default class Top10Albums extends Component {
    componentWillMount(){
      updateMetadata({ title: 'Top 10 Albums' })
    }
    render(){
        return (
          <div className="main-content article">
            <div className="column article">
              <Billboard which="top10" showNote = {true} />
            </div>
            <AdColumn showLinks />
          </div>
        )
    }
}
