import React from "react";
import LoadingIndicator   from '../components/loading-indicator';

const SearchResults = (props) => {
      let { results, onClick, queryCategory, categoryType, desc, loadingPagination, resultMetrics = {}, subCategoryData} = props;
      const categoryResults = results[queryCategory];
      const subCategoryTotal = subCategoryData  && subCategoryData.hasOwnProperty('outTake') && subCategoryData['outTake'].length || 0;
      let categoryResultsLength = categoryResults && categoryResults.length;
      let totalResults = Object.keys(resultMetrics).length > 0 &&  (resultMetrics[queryCategory] && resultMetrics[queryCategory].totalResults) || 0 ; 
      if (!queryCategory || categoryResultsLength == 0 ||  !_.isArray(categoryResults)) return null;
      const resultDiff = (categoryResultsLength < totalResults || categoryResultsLength > totalResults)

      //handle tracks subcategory count change when there is outtaketracks
      if (
        queryCategory === "tracks" &&
        resultDiff
      ) {
        totalResults -=subCategoryTotal
        if(totalResults !==categoryResultsLength){
          totalResults = categoryResultsLength;
        }
      }else if(queryCategory === "tour" && resultDiff){
        totalResults = categoryResultsLength;
      }

      //handle if results are more than expected.
      if(categoryResultsLength > totalResults ) totalResults =  categoryResultsLength;
      
      return (
        <div className="search-results">
          <div className="inner-wrapper">
            {totalResults ? (
              <div className="header">
                <div className="desc">{categoryType(queryCategory)}</div>
                <div className="count">
                  {categoryResultsLength} out of {totalResults} Result
                  {totalResults > 1 ? "s" : ""}
                </div>
                <br style={{ clear: "both" }} />
              </div>
            ) : null}
            <div className="filler" />
            <div className="result-items">
              {(categoryResults || []).map((item, idx) => desc(item, idx, onClick))}
            </div>
            {loadingPagination && <center><LoadingIndicator/></center>}
          </div>
        </div>
      );
    }

  export default  SearchResults;