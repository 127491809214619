import React, {Component} from 'react';
import {allMonths}        from '../stubs/gen-months';
import {minYear}          from '../stubs/gen-years';
import _                  from 'lodash';
import {getAlbumById}     from '../drawer-items';
import {compareItems}     from '../util/compare-release-date';


export default class TimelineOverview extends Component {
    constructor(props, b){
        super(props, b)

        this.monthCount = this._countMonths()

        this.clickAlbum = this.clickAlbum.bind(this)
    }
    clickAlbum(){
        let album = getAlbumById(this.props.albumId)
        this.props.onClickAlbum(album)
    }
    _countMonths(){
        return allMonths.length
    }
    _itemMonth(item){
        let {month, year} = item.releaseDate
        let years = year - minYear
        return (years * 12) + month
    }
    _calcPercentage(item){
        return (this._itemMonth(item) / this.monthCount) * 100
    }
    shouldComponentUpdate(newprops){
        return (
            newprops.albumId   !== this.props.albumId  ||
            newprops.left      !== this.props.left     ||
            newprops.width     !== this.props.width    ||
            newprops.moreLeft  !== this.props.moreLeft ||
            newprops.moreRight !== this.props.moreRight
        )
    }
    render(){

        let {moreLeft, moreRight} = this.props

        let id = this.props.albumId
        if (!id) return null

        let album  = getAlbumById(id)

        let albumPosition   = this._calcPercentage(album)
        let backgroundImage = `url('${album.overviewImage}')`

        let albumStyle = {left: albumPosition + '%'}

        let left  = (this.props.left || 0) + 'px'
        let width = this.props.width || '100%'

        let style = {left, width}


        return (
            <div className="timeline-overlay" style={style}>
               <div className="album-indicator" style={albumStyle} >
                 <div className="album-indicator-background" />
                 {moreLeft && <div className="prev-arrow"
                                   onClick={this.props.onClickPrev} /> }
                 <div className="album-image"
                      onClick={this.clickAlbum}
                      style={{backgroundImage}} />
                 {moreRight && <div className="next-arrow"
                                    onClick={this.props.onClickNext}  /> }
                 <div className="album-indicator-close"
                      onClick={this.props.onClickClose}>
                 </div>
               </div>
            </div>
        )
    }
}
