import {random_int, pick} from '../util/random'
import lorem              from 'lorem-ipsum'

function genVideo(){
    let month = random_int(1,12)
    let day   = random_int(1, 31)
    let year  = random_int(1965, 2017)

    let date = {month,day,year}

    let title       = lorem({count: random_int(1, 6), units:'words'})
    let notes       = lorem({count: random_int(1, 20), units:'words'})
    let description = lorem({count: random_int(50, 80), units:'words'})

    let url = "http://fake.website/video"

    return {title, notes, description, date, url}
}

export default function genVideos(count){
    let i = count, result = []
    while (i-- > 0){
        result.push(genVideo())
    }
    return result
}
