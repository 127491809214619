import React, {Component}            from 'react';
import PropTypes                     from 'prop-types'
import AccountPulldown               from './account-pulldown';
import {getUserInfo, hasAuth}        from '../services/api';
import Login from '../services/login';

export default class UserInfo extends Component {
    constructor(props, context){
        super(props, context)

        this.state = {showingPulldown: false}

        this.onSubscribeClick = this.onSubscribeClick.bind(this)
        this.onArrowClick     = this.onArrowClick.bind(this)
    }
    onArrowClick(){
        this.setState({showingPulldown: !this.state.showingPulldown})
    }
    onSubscribeClick(){
        /* window.displayWarningMessage('subscription')*/
        const {router} = this.context
        router.push(router.createLocation('/account?screen=plans'))
    }
    render(){
        let {onMouseEnter, showSubscribe} = this.props

        if (!hasAuth()) {
            return (
                <div className="user-info">
                    <div className="subscribe login" onMouseEnter={onMouseEnter} onClick={Login} ><p>log in</p></div>
                    <div className="subscribe" onMouseEnter={onMouseEnter} onClick={this.onSubscribeClick} ><p>subscribe</p></div>
                </div>
            )
        }

        let {showingPulldown} = this.state

        let info = getUserInfo()
        let name = (info.user_metadata && info.user_metadata.customFirstname) || info.name || info.nickname || info.email || ''
        name = name.split(' ')[0]

        let backgroundImage = `url(${info.picture || ''})`
        let avatarStyle = {backgroundImage}


        return (
            <div className="user-info logged-in">
                <div className="user-avatar">
                    <div className="avatar" style={avatarStyle} />
                    <div className="username">{name}</div>
                </div>
                <div key={`delimiter`} className="delimiter" />
                <AccountPulldown onClose={this.onArrowClick}/>
    
            </div>
        )
    }
}

UserInfo.contextTypes = {
    router: PropTypes.object.isRequired
}
