import {allYears}  from './gen-years'
import genVideos   from './gen-videos'
import lorem       from 'lorem-ipsum';

import {pop_random, random_int, chance, pick, pick_not} from '../util/random.js'

function genCredits(){
    let count = random_int(3, 30)
    let result = []
    while (count--){
        let title = lorem({count: 2, units:'words'})
        let credits = []
        let creditsCount = random_int(3, 10)
        while(creditsCount--){
            credits.push(lorem({count: 2, units:'words'}))
        }
        credits = credits.join('\n')
        result.push({title, credits})
    }
    return result
}

export const films = {
    all: [],
    byYear: {},
    index: {}
}

let count = 5
let i = -1
while(i++ < count){

    let year            = pick(allYears)
    let id              = `F_${i}`
    let title           = lorem({count:4, units:'words'})
    let description     = lorem({count: 4, units:'paragraphs'})
    let director        = lorem({count: 2, units:'words'})
    let producer        = lorem({count: 2, units:'words'})
    let cinematographer = lorem({count: 2, units:'words'})
    let releases        = lorem({count: 4, units:'words'})
    let credits         = genCredits()

    let month = random_int(1, 12)
    let day = random_int(1, 28)
    
    let releaseDate = {month, day, year: year.year}

    let videos = genVideos(10)

    let more = []
    {
        let count = random_int(5, 30)
        while(count--){ more.push({foo:'bar'})}
    }

    let info = []
    {
        let count = random_int(5, 30)
        while (count--){
            info.push({
                title: lorem({count: 1, units:'words'}),
                content: lorem({count: 3, units: 'paragraphs'})
            })
        }
    }

    let reviews = []
    {
        let count = random_int(5, 30)
        while (count--){
            reviews.push({
                title: lorem({count: 6, units:'words'}),
                content: lorem({count: 5, units: 'paragraphs'})
            })
        }
    }

    let film = {
        year, id, title, description, credits, releaseDate,
        director, producer, cinematographer, releases,
        videos, more, info, reviews,
        isFilm: true
    }

    films.all.push(film)
    if (!films.byYear[year.year]) films.byYear[year.year] = []
    films.byYear[year.year].push(film) 
    films.index[id] = film

}
