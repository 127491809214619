import Vimeo from '@vimeo/player'
import Audio from '../components/new-player/audio';

export default class VimeoHelper {
    constructor(iframes) {
        this.players = [];
        if (!iframes instanceof Array) {
            iframes = [iframes]
        }
        this.iframes = iframes;

        this.createPlayers();
    }
    destroy() {
        this.iframes.forEach(_iframe => {
            Audio.removeCallback(_iframe.audioCallback);
        });
    }
    createPlayers() {
        this.players = [];
        this.iframes.forEach(_iframe => {
            const _newPlayer = new Vimeo(_iframe);
            _newPlayer.on('play', function () {

                this.audioCallback = (state) => {
                    if (state.state === 'LIVEPLAY') {
                        this.pause();
                        Audio.removeCallback(this.audioCallback);
                    }
                };

                Audio.pause();
                Audio.addCallback(this.audioCallback);
            });
            this.players.push(_newPlayer);
        });
    }
};