/*global _OS:true */
import _ from 'lodash'
import {isIPad} from '../../platform'
import {getOrastreamUuid} from '../../services/api'
import store from '../../store'

let quality = true //high quality
if (isIPad) quality = false

var player={
    _elapsed: 0,
    firstLoad: true
};

window.onbeforeunload=function(){
	player.stop(true)
}
window.onload=function(){
    const isInEmbeddedNYATC = window.navigator ?
      window.navigator.userAgent.includes('nya-mobile') ||window.navigator.userAgent.includes('nya-embedded')
      : false
    if (isInEmbeddedNYATC) {
        return
    }
    _OS.init({
        connectUrl: 'connect.neilyoungarchives.com',
        workerUrl: '/js/os.worker.js',
        scripts: ['sls.js', 'src.js', 'soundtouch.js', 'pcm-to-mp4.js'],
        testUrl: 'https://cdn.neilyoungarchives.com/streaming/test.zip',
        enableCaching: false,
        segment: 15,
        useMediaSource: window.MediaSource && MediaSource.isTypeSupported('audio/mp4;codecs=flac'),
        useMediaSourceResampler: window.MediaSource && MediaSource.isTypeSupported('audio/mp4;codecs=flac'),
    })

    _OS.service = 'orastream'

    _OS.max = quality ? 9999 : 320
    _OS.mode = quality ? 1 : 2

    player.level = 1;
    player.toggled = player._toggled = false;
    _OS.paused = true;
    _OS.on('update', player.update)
    _OS.on('next', player.next)
    _OS.on('volume',function(v){
    });
}

function GET(url){
    let req = new XMLHttpRequest()
    req.open('GET', url)
    req.send()
}

player.report=function () {
    var apiUrl = `https://${process.env.ORASTREAMCONNECTURL}`

    // JWT to be provided
    if(player._elapsed>33 && _OS.token) {
        var _url = apiUrl + '/track/' + player.queued[player.currentTrackIndex] +
                   '/listen?played='+player._elapsed+ '&token='+ _OS.token;

        player._elapsed = 0;
        // added for reporting streams
        GET(_url)
        console.log('report listen:',_url);
    }

    // user_id and JWT to be provided
    if(!player._device_reported /* && _OS.user_id */ && _OS.token) {
        var p = 'Unknown'
        if(/Windows/.test(navigator.userAgent)) p = 'Windows'
        if(/Intel Mac OS X/.test(navigator.userAgent)) p = 'Mac'
        if(/Linux/.test(navigator.userAgent)) p = 'Linux'


        var _url = apiUrl + '/device' + '?platform=' + p + '&type=Web&version=1.0'
        let uuidUri = getOrastreamUuid()  ?  _OS.user_id ?  p + _OS.user_id : getOrastreamUuid() : null
        if(uuidUri)   _url += '&uuid='+ uuidUri  ;

        if(_OS.token) _url += '&token=' + _OS.token;
        //$$.get(_url);
        GET(_url)
        console.log('report device:',_url);
        player._device_reported = true;
    }
}

player.updateTrackInfo=function(index,isPrevOrNext){
    if (index === -1) {
        player.onTrackSelect && player.onTrackSelect(-1, isPrevOrNext)
    }else
        player.onTrackSelect && player.onTrackSelect(player.currentTrackIndex, isPrevOrNext);
}

player.select=function(){
    player.updateTrackInfo(player.currentTrackIndex)
    // here it does the loading for os
    player.load(player.currentTrackIndex);
}

player._prev=function(){
    if(!player.currentTrackIndex)  player.currentTrackIndex=0;
    player.report();
    player.stop(true);
    player.isPrevOrNext = -1

    if(player.currentTrackIndex-1>=0) {
    	player.currentTrackIndex--;
    	player.select();
    }
}

player._next=function(){
    if(!player.currentTrackIndex)  player.currentTrackIndex=0;
    player.report();
    player.stop(true);
    player.isPrevOrNext = 1

    if(player.queued.length == player.currentTrackIndex+1){
        //load the first song again after the whole album played
        player.currentTrackIndex = 0
        player.select();
        return;
    }


    if(player.currentTrackIndex+1<player.queued.length) {
    	player.currentTrackIndex++;
    	player.select();
    }
}

player.next=function(){
    if(!player.currentTrackIndex)  player.currentTrackIndex=0;
    player.report();

    if(player.currentTrackIndex+1<player.queued.length) {
    	player.currentTrackIndex++;
        player.updateTrackInfo(player.currentTrackIndex);
    	setTimeout(function(){_OS.stopped=false;},3000);
    }
    else{
    	player.stop();
    	setTimeout(function(){player.updateTrackInfo(-1);},1000);
    }
}

player.enqueue = function(items, key) {
    let q = _.map(items, key)
    _OS.queue(q);
    player.queued=q;
}

player.load=function(index){
    _OS.load(index,0)
}

player.play=function(flag){
    _OS.play(flag)
}

player.seek=function(value){
    _OS.seek(parseFloat(value))
}

player.stop=function(isPrevOrNext){
    player.updateTrackInfo(-1, isPrevOrNext);
    player.toggled=player._toggled=false;
    _OS.stop();
}

//here call the method check     checkPreview(json);
//add the preview limit on the song json too.

player.update=function(json){
    const tracks   = store.getState().tracks;
    //check if it it's embed as well.
    const {playingTrack :{ preview = true, isEmbed, free = false, albumEmbed = false }= {}} = tracks

    if(!json.position) json.position=0;
    if(!json.buffered) json.buffered=0;
    if(!json.bitrate) json.bitrate='--'
    if(!json.state) json.state='LOADING'
    // added preview check
    var previewSTate = ''
    if(player.__elapsed!=json.elapsed){
        player.__elapsed=json.elapsed;
        player._elapsed++;
    }

    json.preview =  preview && ! free;
    if(json.preview && isEmbed ||  _OS.isPreview() ) {
    	previewSTate = ' | ('+(32-player._elapsed)+'s preview only)';
    	if(player._elapsed > 30) {
            //restart elapse position
            player._elapsed = 0;
            if(albumEmbed){
                //play next song
                player._next();
                return;

            }
            //restart single track
            player.stop();

            return;

    	}
    }
    if (player.callback) (player.callback(json))
}

let playerListeners = []

player.callback = function(state) {
    for (let listener of playerListeners) listener(state)
}

// let hasDisplayedPlaybackWarningMessage = false

class Audio {
    getIsFirstLoad() {
        return player.firstLoad;
    }
    setIsFirstLoad(newValue) {
        player.firstLoad = newValue;
    }
    setStreamingToken(t){
        _OS.token = t
    }
    getQuality(){ return quality; }
    toggleQuality(){
        quality = !quality
        _OS.max = quality ? 9999 : 320
        _OS.mode = quality ? 1 : 2
        _OS.seek(_OS.position - 1e-5)
    }
    playItems(tracks, index=0, isPrevNext){

        player.report();
        player.stop(isPrevNext)

        player.enqueue(tracks, 'orastream')
        player.currentTrackIndex = index;
        // here it does the loading
        player.select()
    }
    stopPlaying(){
        player.stop()
    }
    play(){
        player.play(true)
    }
    pause(){
        player.play(false)
    }
    seek(pct, isEmbed) {
        const tracks   = store.getState().tracks;
        const {playingTrack :{ free = false, albumEmbed = false}= {}} = tracks ;
        //handle tracks from album
        if(albumEmbed && (pct > 0.08) && !free){
            player._next()
            player._elapsed = 0;
            //handle single tracks
        }else if (isEmbed && (pct > 0.08) && !free){
            player._elapsed = 0;
            player.stop();
            return;
        }


        player.seek(pct)
    }
    next(){ player._next() }
    prev(){ player._prev() }
    addCallback(fn) {
        playerListeners.push(fn)
    }
    removeCallback(fn) {
        playerListeners = playerListeners.filter(it=>it!==fn)
    }
    onTrackSelect(fnOrNull) { player.onTrackSelect = fnOrNull }
    setVolume(v){ _OS.setVolume(v) }
    setShowAreYouStillListeningModal(showAreYouStillListeningModal) {
        player.showAreYouStillListeningModal = showAreYouStillListeningModal
    }
    isShowAreYouStillListeningModal() {
        return player.showAreYouStillListeningModal
    }
    isPrevOrNext() {
        return player.isPrevOrNext
    }
    getVolume() {
        return _OS.volumeLevel;
    }
}
export default new Audio()
