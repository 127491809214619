import React, {Component, Children} from 'react'

export class HSplitFirst extends Component {
    render(){
        let kids = Children.toArray(this.props.children)
        let top  = kids.shift()
        let {style, height} = this.props

        return (<table style={style}>
                  <tbody>
                    <tr height={height}><td>
                      {top}
                    </td></tr>
                    <tr height="*"><td>
                      {kids}
                    </td></tr>
                  </tbody>
                </table>);
    }
}

export class HSplitLast extends Component {
    render(){
        let kids   = Children.toArray(this.props.children)
        let bottom = kids.pop()
        let {style, height} = this.props

        return (<table style={style}>
                  <tbody>
                    <tr height="*"><td>
                      {kids}
                    </td></tr>
                    <tr height={height}><td>
                      {bottom}
                    </td></tr>
                  </tbody>
                </table>);
    }
}

