import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import cn from "classnames";

export default class PerformaceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderTableData = this.renderTableData.bind(this);
  }

  renderRequests(requests) {
    let userRequests = [];
    if (!_.isEmpty(requests)) {
      requests.map((request, key) => {
        if (key <= 2) {
          userRequests.push(<div key={key}>{request.userName}</div>);
        }
      });
    }

    return <div className="user-requests">{userRequests}</div>;
  }

  renderTableData() {
    const {
      performanceData: { performances },
      showPerformanceLog,
      userRequestItems,
      showRequests,
      isRequestVisible,
    } = this.props;

    performances.sort((a, b) => {
      if (!_.has(a, "asset.startDate") || !_.has(b, "asset.startDate")) return;
      const {
        asset: {
          startDate: {
            day: aday = "1",
            month: amonth = "1",
            year: ayear = "1969",
          },
        },
      } = a;
      const {
        asset: {
          startDate: {
            day: bday = "1",
            month: bmonth = "1",
            year: byear = "1969",
          },
        },
      } = b;
      return (
        new Date(`${amonth}/${aday}/${ayear}`) -  new Date(`${bmonth}/${bday}/${byear}`)
      );
    });
    return performances.map((performance, index) => {
      const {
        _id,
        userRequests,
        viewed,
        requestViewed,
        asset: {
          venue = [],
          hasLog,
          startDate: { day = "1", month = "1", year = "69" },
        },
      } = performance;

      let name,
        location,
        country,
        date = new Date(`${month}/${day}/${year}`),
        showUserRequest = false,
        userRequesImage = false,
        changeRequestVenue = isRequestVisible ? false : true,
        visited = viewed ? " viewed" : "",
        requestVisited = requestViewed ? " active" : " innactive";

      date = moment(date);
      date = date.format("M/D");

      if (!_.isEmpty(venue) && venue[0]) {
        name = venue[0].name;
        location = venue[0].location;
        country = venue[0].country
      }
      if (!_.isEmpty(userRequestItems) && !_.isEmpty(userRequests)) {
        showUserRequest = userRequestItems.reduce((prev, curr) => {
          return Math.abs(curr.minRequest - userRequests.length) <
            Math.abs(prev.minRequest - userRequests.length)
            ? curr
            : prev;
        });
      }
      if (
        !_.isEmpty(showUserRequest) &&
        _.has(showUserRequest, "symbol.fields.file")
      ) {
        let { url } = showUserRequest.symbol.fields.file;
        userRequesImage = url;
      }
      return (
        <tr key={_id}>
          <td className={`perf-date ${visited}`}>{date}</td>
          <td className={`perf-name ${visited}`}>{name}</td>
          <td className={`perf-location ${visited}`}>{location}, {country}</td>
          <td className="perf-log">
            {hasLog && (
              <button
                className="log-btn"
                onClick={() =>
                  showPerformanceLog({
                    performanceId: _id,
                    performanceLog: true,
                    changeRequestVenue,
                  })
                }
              >
                view log
              </button>
            )}
          </td>
          <td className="perf-request"
            onClick={() =>
              showRequests({
                performanceId: _id,
                requestsSelected: userRequests,
                viewedRequest: true,
              })
            }
          >
            {userRequesImage && (
              <span>
                <img
                  className={`${requestVisited} ${showUserRequest.type}`}
                  src={userRequesImage}
                />
              </span>
            )}
          </td>
        </tr>
      );
    });
  }
  render() {
    return (
      <div>
        <table id="performances-table">
          <tbody>{this.renderTableData()}</tbody>
        </table>
      </div>
    );
  }
}