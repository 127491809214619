export default class EventBusSubscriber {

  static subscribeToChangePage (router) {

    window.addEventListener("message", (event) => {
      let {message = "", page = 1, parentUrl= '' } = event.data;
      if (message === "CHANGE_PAGE") {
        const url = new URL(`/news/${page}`, document.baseURI);
        history.replaceState(null, "", url);
        return;
      }

      if (message === 'PARENT_REDIRECT') {
        if (parentUrl.startsWith("/#/")) {
          parentUrl = parentUrl.replace(/^\/#/ig,'');
        }
        const root = document.baseURI;
        try {
          const url = new URL(parentUrl, root);
          router.push(url)
          history.replaceState(null, '', url);
        } catch (e) {
          console.log("%c @@@ e", "color:red;", e)
        }
        return;
      }

      if (message === 'OPEN_EXTERNAL_LINK') {
        const {message = "", url ='' } = event.data;
        window.open(url, "_blank");
        return ;
      }
    });
  }

  static subscribeToPlayTrackFromIframe (playlistManager) {
    window.addEventListener("message", (event) => {
      let {message = "", track = {}, tracks = [] } = event.data;
      if (message === "PLAY_ON_PARENT_PLAYER") {
        const currTrack = playlistManager.currentTrack();
        if (currTrack && currTrack.id === track.id) {
          if (_OS.playing) {
            playlistManager.pause();
            return;
          }
          playlistManager.play();
        } else {
          playlistManager._playItemInMiddleItems(track, tracks);
        }
      }
    })
  }
}
