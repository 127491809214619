import React, { Component } from 'react'

const AudioPlayer = ({ filePath, fileType }) => <div className="player">
    <audio controls autoPlay><source src={filePath} type={fileType} /></audio>
</div>

class PostItAudio extends Component {
    constructor(props) {
        super(props)

        this.onClose = this.onClose.bind(this)
    }
    componentDidMount() {
        window.pauseAudioIfNeeded && window.pauseAudioIfNeeded()
    }
    componentWillUnmount() {
        window.resumeAudioIfNeeded && window.resumeAudioIfNeeded()
    }
    onClose() {
        this.props.onClose()
    }
    render() {
        const { track } = this.props
        const filePath = track && track.audioFile

        return (
            <div className="post-it-modal">
                <div className="post-it-modal__wrapper">
                    <div className="post-it-modal__wrapper__box">
                        <div className="post-it-modal__wrapper__box__close" onClick={this.onClose} />
                        <div className="post-it-modal__wrapper__box__inner">
                            <AudioPlayer filePath={filePath} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PostItAudio