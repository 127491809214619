export default function toTimestamp({month, day, year}){
    let d = new Date()
    d.setTime(0)
    d.setHours(0)
    d.setDate(1)

    d.setYear(year)
    d.setMonth(month - 1)
    d.setDate(day)

    return +d
}

