import React from 'react';
import { connect } from 'react-redux';
import SplashScreen from './splash-screen';
import FolderBrowser from './components/folder-browser'
import PhotoModal    from './components/photo-modal'

class Container extends FolderBrowser {
    contentType(){
        return 'docs'
    }
    selectedTab(){
        return 'documents'
    }
    extractPhotosFromTrack(track){
        return track.documents
    }
    modalForPhotoAndPhotos(photo, photos){
        return (<PhotoModal photo={photo} collection={photos}
                            location={this.props.location}
                            alwaysShowZoom={true}
                            onUpdate={this.showCurrentPhoto}
                            onDismiss={this.hideModal} /> )
    }
}


const mapStateToProps = function (state) {
    if (state.status !== "drawerLoaded") {
        return {
            loaded: false
        }
    }
  
    return { loaded: true};
};

const ContainerWrapper = (props) => {
    if (!props.loaded) return <div className="info-card-section">
            <SplashScreen loadState={100} />
        </div>;
    return <Container {...props}/>
  }
  
  export default connect(mapStateToProps)(ContainerWrapper);