import { assign, get } from 'lodash'
import {
    SET_SEARCH, UPDATE_SEARCH, RESET_SEARCH , CHANGE_SEARCH_CATEGORY
} from '../constants'

const initialState = {
  results: {},
  foundCategories: {},
  subCategoryData: [],
  existingCategories: [],
  canPaginate: false,
  suggestTerm: false,
  termsArray:false
};

export default function update(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH:
            return get(action, 'data', {})
        case UPDATE_SEARCH:
            //update categories and subcategories on pagination
            const data  = get(action, 'data', {})
            const {foundCategories,results , subCategoryData, canPaginate,termsArray} = data
       
        return {...state, foundCategories,results , subCategoryData, canPaginate,termsArray};

        case CHANGE_SEARCH_CATEGORY:
               const searchData = _.cloneDeep(state);
               const {results: resultsData} = searchData

               //update categories and subcategories on pagination
               const category  = get(action, 'data', {})
               const categoryData= resultsData && resultsData.subCategories && resultsData.subCategories[category];

           return {...state, subCategoryData:categoryData, queryCategory:category};

        case RESET_SEARCH:
            return initialState
    }
    return state
}
