import React, {Component} from 'react'
import _                  from 'lodash'
import cn                 from 'classnames'
import hasClass           from 'has-class'
import compareReleaseDate from '../util/compare-release-date'
import {albumIsLockedByID} from '../user'

/* normal 'stacking' position etc, must match css */
const cardTop      = 126
const cardRight    = -490
const clipRight    = -540 /* paperclip */
const cardClipDiff = (clipRight - cardRight) + 31
const cardMargin   = 8
const cardWidth    = 270


export default class InfoCardAlbums extends Component {
    constructor(props){
        super(props)

        this.state = {browsing: props.entering ? true : false }

        this.albumClick    = this.albumClick.bind(this)
        this.documentClick = this.documentClick.bind(this)
        this.documentTouch = this.documentTouch.bind(this)
    }
    shouldComponentUpdate(newprops, newstate) {
        return newstate.browsing !== this.state.browsing || (newprops.entering && !this.props.entering) || this.props.newSearch
    }
    componentWillUpdate(props){
        if (props.entering && !this.props.entering && !this.state.ranInitial) {
            this.setState({browsing:true, ranInitial:true})
            setTimeout(()=>{
                this.setState({browsing:false})
            }, 1100)
        }
    }
    componentWillMount(){
        /* firefox fires the click event that closes the album
         * modal /after/ the modal dismisses and the route transitions
         * which causes our check in documentClick to fail, so we
         * have to bind mousedown instead, which fires earlier
         **/
        document.addEventListener('mousedown', this.documentClick)
        document.addEventListener('touchstart', this.documentClick)
    }
    componentWillUnmount(){
        document.removeEventListener('mousedown', this.documentClick)
        document.removeEventListener('touchstart', this.documentClick)
    }
    documentClick(e){
        if (hasClass(e.target, 'is-album-cover')) return
        return this._maybeQuitBrowsing(e)
    }
    documentTouch(e){
        if (e.touches.length > 1) return
        if (hasClass(e.touches[0].target, 'is-album-cover')) return
        return this._maybeQuitBrowsing(e)
    }
    _maybeQuitBrowsing(e){
        if (document.location.href.search("/info-card") === -1) return
        if (!this.state.browsing) return;

        e.preventDefault()
        e.stopPropagation()

        this.setState({browsing: false})
    }
    albumClick(e){
        e.preventDefault()
        e.stopPropagation()
        let id = e.target.getAttribute('data-id')

        if (this.state.browsing && id !== 'A_0000'|| this.isSingleAlbum() && id !== 'A_0000' ) {
            //skip first album if it is default image album
            this.props.onCoverClick(id)
        } else {
            this.setState({browsing: true})
        }
    }
    itemCount(){
        return Object.keys(this.props.track.presentOn).length
    }
    isSingleAlbum(){
        return this.itemCount() === 1
    }
    rightForIndex(idx, total) {

        let {browsing} = this.state
        let {entering} = this.props

        let itemWidth  = entering ? cardWidth * 0.3 : cardWidth
        let margin     = cardMargin
        let totalWidth = (total * itemWidth) + (margin * (total - 1))

        if (browsing) {
            if (total <= 4 || entering) {
                // 4 or less, flush right
                let idxFromRight = ((total - 1) - idx)
                let right        = (idxFromRight * itemWidth) + (margin * idxFromRight)
                return right + cardRight
            } else {
                // > 4, center in window
                let rightPosition = -1 * (((idx + 1) * itemWidth) + (margin * idx))
                let centerRightPosition = rightPosition + (totalWidth  / 2)
                return centerRightPosition
            }
        } else {
            return cardRight - (idx * 10);
        }
    }
    renderAlbums(albums){
        let total = albums.length

        let items = albums.map((album, idx)=>{

            let {id}  = album
            let bg       = album.infoCardImage
            let right    = this.rightForIndex(idx, total)

            let cardPhotoStyle = bg ? {backgroundImage: `url(${bg})`} : {backgroundColor: 'orange'}
            let photoClass     = cn({'card-photo':true })

            cardPhotoStyle.right     = right

            return (
                <div className={photoClass}
                     key={idx}
                     style={cardPhotoStyle}
                     onClick={this.albumClick}>
                  <div className="shader is-album-cover" data-id={id}></div>
                </div>
            )
        })
        items.reverse()
        return items
    }
    getPaperClipStyle(){
        let count = this.itemCount()
        let right = count > 4 ? clipRight + ((count - 4) * -10) : clipRight

        if (this.state.browsing && count >= 5) {
            /* when albums are centered in window, clip stays on rightmost album */
            right = this.rightForIndex(count-1,count)
            right += cardClipDiff
        }

        return {right}
    }
    render(){
        let {track} = this.props
        if (!track.album && !track.albumImage){
            return null;
        }else if(!track.album && track.albumImage && track.presenOnEmpty){
            return(
                <div className="info-card-albums-container stacked">
                <div className='card-photo'
                     style={{backgroundImage: `url(${track.albumImage})`}} >
                  <div className="shader is-album-cover"></div>
                </div>
                </div>
            )
        }

        let albums = Object.keys(track.presentOn).map(k=>track.presentOn[k])
        albums = _.reject(albums, {id:track.album.id})
        albums = albums.filter(item => item.type === "album").sort((a,b)=>compareReleaseDate(a.displayDate, b.displayDate))
        albums.unshift(track.album)
        if(track.albumImage ){
            albums.unshift({'id':'A_0000','infoCardImage': track.albumImage })
        }
        let {browsing} = this.state
        let cx = cn('info-card-albums-container', {browsing, stacked: !browsing})

        let clip = cn('paperclip', {[track.paperclip]:true})

        return (
            <div className={cx}>
              {
                  this.renderAlbums(albums)
              }
              <div className={clip} style={this.getPaperClipStyle()}></div>
              {browsing ? <div className="modal-background" /> : null }
            </div>
        )

    }
}